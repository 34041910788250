import React, { useEffect, useState } from "react";
import Sidebar from "./Sidebar";
import Footer from "./Footer";

import DriverRequest from "../common/driver/DriverRequest";
import AllDriverDetails from "../common/driver/AllDriverDetails";
import AddDriver from "../common/driver/AddDriver";
import UpdateDriver from "../common/driver/UpdateDriver";
import ShowDriver from "../common/driver/ShowDriver";
import { ShowDriversApi } from "../helper/integration";

const DriverDetails = () => {
  const [MainDriver, setMainDriver] = useState([]);
  const [isLoad, setisLoad] = useState(false);
  const [Request, setRequest] = useState(0);
  const [SingleDriver, setSingleDriver] = useState(null);
  const [isUpdate, setisUpdate] = useState(false);

  useEffect(() => {
    (async function () {
      try {
        const data = await ShowDriversApi();
        setMainDriver((prev) => data);
        let Req = 0;
        data.forEach((element) => {
          if (element?.status == "pending") Req++;
        });
        setRequest(Req);
      } catch (er) {
        console.log(er);
      } finally {
        setisLoad(true);
      }
    })();
  }, [isUpdate]);
  return (
    <div>
      {<Sidebar />}
      <div className="asside">
        <div className="about-first">
          <div className="row">
            <div className="col-12 mb-24">
              <div className="bg-box">
                <nav className="mb-3">
                  <div class="nav nav-tabs" id="nav-tab" role="tablist">
                    <button
                      class="nav-link active"
                      id="pills-home-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-home"
                      type="button"
                      role="tab"
                      aria-controls="pills-home"
                      aria-selected="true"
                    >
                      {" "}
                      All Driver{" "}
                    </button>
                    <button
                      class="nav-link position-relative"
                      id="pills-profile-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-profile"
                      type="button"
                      role="tab"
                      aria-controls="pills-profile"
                      aria-selected="false"
                    >
                      Request
                      <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                        {Request > 99 ? "99+" : Request}
                      </span>
                    </button>
                  </div>
                </nav>
                <div class="tab-content" id="pills-tabContent">
                  <AllDriverDetails
                    drivers={MainDriver}
                    isLoad={isLoad}
                    setSingleDriver={setSingleDriver}
                    isUpdate={isUpdate}
                    setisUpdate={setisUpdate}
                  />
                  <DriverRequest
                    drivers={MainDriver}
                    isLoad={isLoad}
                    isUpdate={isUpdate}
                    setisUpdate={setisUpdate}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <!-- Add Modal --> */}
        <AddDriver isUpdate={isUpdate} setisUpdate={setisUpdate} />
        {/* <!--End Add Modal --> */}

        {/* <!-- Edit Modal --> */}
        <UpdateDriver
          item={SingleDriver}
          isUpdate={isUpdate}
          setisUpdate={setisUpdate}
          setItem={setSingleDriver}
        />
        {/* <!--End Edit Modal --> */}

        {/* <!-- View Modal --> */}
        <ShowDriver SingleDriver={SingleDriver} />
        {/* <!--End View Modal --> */}

        {<Footer />}
      </div>
    </div>
  );
};

export default DriverDetails;
