import React, { useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { POSTAPI } from "../../helper/integration";
import { Api } from "../../utils/Api";
const AddModal = ({ update }) => {
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [hourlyRideSupported, setHourlyRideSupported] = useState(false);
  const [Image, setImage] = useState(null);

  const handleAddData = (data) => {
    if (!Image) return toast.error("Please Select Image !");
    let newForm = new FormData();

    newForm.append("type", data.type);
    newForm.append("vehicle_name", data.name);
    newForm.append("number_of_sit", data.no_of_sit);
    newForm.append("base_price", data.base_price);
    newForm.append("price_per_km", data.price_per_km);
    newForm.append("price_per_minute", data.price_per_min);
    newForm.append("driver_commission", data.driver_commission);
    newForm.append("detail", data.detail);
    newForm.append("sub_detail", data.sub_detail);
    newForm.append("pool_ride", data.pool_ride);
    newForm.append("hourly_ride", hourlyRideSupported);
    newForm.append("per_hour_charge", data.per_hour_charge);
    newForm.append("image", Image);

    toast
      .promise(POSTAPI(Api.AddVehicle, newForm), {
        loading: "Wait Few Moment !",
        success: "Add SuccessFully !",
        error: "Something Wrong !",
      })
      .then(() => {
        update();
      })
      .catch((er) => {});
    document.getElementById("closeAddVehicle").click();
    reset();
  };

  const handleHourlyRideChange = (event) => {
    setHourlyRideSupported(event.target.value === "option3");
  };
  return (
    <div
      className="modal fade"
      id="exampleModalAdd"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              Add Vehicle Details
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <div className="user-details">
              <form className="row" onSubmit={handleSubmit(handleAddData)}>
                <div className="mb-3 col-md-6 col-12">
                  <label className="mb-2">Service Type</label>
                  <select
                    className="form-select form-control"
                    id="floatingSelectGrid"
                    aria-label="Floating label select example"
                    {...register("type")}
                  >
                    <option value="ride">Ride</option>
                    <option value="courier">Courier</option>
                  </select>
                </div>
                <div className="mb-3 col-md-6 col-12">
                  <label className="mb-2">Name Of Vehicle Type</label>
                  <input
                    type="text"
                    className="form-control"
                    {...register("name", { required: true })}
                  />
                  {errors.name && (
                    <span style={{ color: "red" }} className="p-1  ">
                      Please Enter Name !
                    </span>
                  )}
                </div>
                <div className="mb-3 col-md-6 col-12">
                  <label className="mb-2">Vehicle Image </label>
                  <input
                    type="file"
                    className="form-control"
                    multiple
                    onChange={(e) => setImage(e.target.files[0])}
                  />
                </div>

                <div className="mb-3 col-md-6 col-12">
                  <label className="mb-2">No Of Sit</label>
                  <input
                    type="number"
                    className="form-control"
                    {...register("no_of_sit", { required: true })}
                  />
                  {errors.no_of_sit && (
                    <span style={{ color: "red" }} className="p-1  ">
                      Please Enter No Of Sit !
                    </span>
                  )}
                </div>
                <div className="mb-3 col-md-6 col-12">
                  <label className="mb-2">Base Price</label>
                  <input
                    type="number"
                    className="form-control"
                    {...register("base_price", { required: true })}
                  />
                  {errors.base_price && (
                    <span style={{ color: "red" }} className="p-1  ">
                      Please Enter Base Price !
                    </span>
                  )}
                </div>
                <div className="mb-3 col-md-6 col-12">
                  <label className="mb-2">Price (Per KM)</label>
                  <input
                    type="number"
                    className="form-control"
                    {...register("price_per_km", { required: true })}
                  />
                  {errors.price_per_km && (
                    <span style={{ color: "red" }} className="p-1  ">
                      Please Enter Price (Per KM) !
                    </span>
                  )}
                </div>
                <div className="mb-3 col-md-6 col-12">
                  <label className="mb-2">Price (Per Minute)</label>
                  <input
                    type="number"
                    className="form-control"
                    {...register("price_per_min", { required: true })}
                  />
                  {errors.price_per_min && (
                    <span style={{ color: "red" }} className="p-1  ">
                      Please Enter Price (Per Minute) !
                    </span>
                  )}
                </div>
                <div className="mb-3 col-md-6 col-12">
                  <label className="mb-2">Driver Commission (In %)</label>
                  <input
                    type="number"
                    className="form-control"
                    {...register("driver_commission", {
                      required: true,
                    })}
                  />
                  {errors.driver_commission && (
                    <span style={{ color: "red" }} className="p-1  ">
                      Please Enter Driver Commision
                    </span>
                  )}
                </div>
                <div className="mb-3 col-md-6 col-12">
                  <label className="mb-2">Features</label>
                  <input
                    type="text"
                    className="form-control"
                    {...register("detail", { required: true })}
                  />
                  {errors.detail && (
                    <span style={{ color: "red" }} className="p-1  ">
                      Please Enter Feature !
                    </span>
                  )}
                </div>
                <div className="mb-3 col-md-6 col-12">
                  <label className="mb-2">Vehicle Information</label>
                  <input
                    type="text"
                    className="form-control"
                    {...register("sub_detail", {
                      required: true,
                    })}
                  />
                  {errors.sub_detail && (
                    <span style={{ color: "red" }} className="p-1  ">
                      Please Enter Vehicle Information !
                    </span>
                  )}
                </div>
                <div className="mb-3 col-md-6 col-12">
                  <label className="mb-2">Pool ride supported ?</label>
                  <div>
                    <div class="form-check form-check-inline">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="inlineRadioOptions"
                        id="inlineRadio1"
                        checked={true}
                        value={true}
                        {...register("pool_ride")}
                      />
                      <label class="form-check-label" for="inlineRadio1">
                        Yes
                      </label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="inlineRadioOptions"
                        id="inlineRadio2"
                        value={false}
                        {...register("pool_ride")}
                      />
                      <label class="form-check-label" for="inlineRadio2">
                        No
                      </label>
                    </div>
                  </div>
                </div>
                <div className="mb-3 col-md-6 col-12">
                  <label className="mb-2">Hourly ride supported ?</label>
                  <div>
                    <div class="form-check form-check-inline">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="HourlyinlineRadioOptions"
                        id="inlineRadio3"
                        value="option3"
                        checked={hourlyRideSupported}
                        onChange={handleHourlyRideChange}
                      />
                      <label class="form-check-label" for="inlineRadio3">
                        Yes
                      </label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="HourlyinlineRadioOptions"
                        id="inlineRadio4"
                        value="option4"
                        checked={!hourlyRideSupported}
                        onChange={handleHourlyRideChange}
                      />
                      <label class="form-check-label" for="inlineRadio4">
                        No
                      </label>
                    </div>
                  </div>
                </div>
                {hourlyRideSupported && (
                  <div className="mb-3 col-md-6 col-12">
                    <label className="mb-2"> Per hour charge</label>
                    <input
                      type="number"
                      className="form-control"
                      defaultValue={""}
                      {...register("per_hour_charge", {
                        required: hourlyRideSupported,
                      })}
                    />
                    {errors.per_hour_charge && (
                      <span style={{ color: "red" }} className="p-1  ">
                        Please Enter Per Hour Charge !
                      </span>
                    )}
                  </div>
                )}
                <div className="upload-reset-btn mb-0 justify-content-center pt-2">
                  <button
                    type="button"
                    className="btn btn-reset"
                    data-bs-dismiss="modal"
                    id="closeAddVehicle"
                  >
                    Cancel
                  </button>
                  <button type="submit" className="btn btn-upload me-0">
                    Save
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddModal;
