import React from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { AddFAQApi } from "../../helper/integration";

const AddDriverModel = ({ handleReferesh }) => {
  const { register, reset, handleSubmit } = useForm();
  const handleAddFAQ = async (data) => {
    try {
      let obj = {
        type: "Driver",
        ...data,
      };
      await toast.promise(AddFAQApi(obj), {
        loading: "Wait Few Moment !",
        success: "FAQ Add Successfully !",
        error: "Somthing Wrong !",
      });
      handleReferesh();
    } catch (er) {
    } finally {
      document.getElementById("closefaqadd").click();
      reset();
    }
  };
  return (
    <div
      className="modal fade"
      id="adddriverfaq"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              Add Driver FAQ
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <div className="user-details">
              <form className="row" onSubmit={handleSubmit(handleAddFAQ)}>
                <div className="mb-3">
                  <label className="mb-2">Enter Question</label>
                  <input
                    type="text"
                    className="form-control"
                    {...register("question", { required: true })}
                  />
                </div>
                <div className="mb-3">
                  <label className="mb-2">Enter Answer</label>
                  <textarea
                    class="form-control"
                    id="exampleFormControlTextarea1"
                    rows="3"
                    {...register("answer", { required: true })}
                  ></textarea>
                </div>
                <div className="upload-reset-btn mb-0 justify-content-center pt-2">
                  <button
                    className="btn btn-reset"
                    id="closefaqadd"
                    data-bs-dismiss="modal"
                    type="button"
                  >
                    Cancel
                  </button>
                  <button type="submit" className="btn btn-upload me-0">
                    Save
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddDriverModel;
