import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { UpdateRequestApi, POSTAPI } from "../../helper/integration";
import { Api } from "../../utils/Api";
const UpdateModel = ({ item, setItem, isUpdate, setisUpdate, fetchData }) => {
  const { register, reset, handleSubmit, setValue } = useForm({
    defaultValues: {
      id: item?.id || "",
      UserID: item?.UserID?._id || "",
      type: item?.type || "",
      pickup_locations: item?.pickup_locations || "",
      drop_locations: item?.drop_locations || "",
      TotalAmount: item?.TotalAmount || "",
      payment_type: item?.payment_type || "",
      status: item?.status || "",
    },
  });

  const [AllUsers, setAllUsers] = useState([]);
  //const [username, setUsername] = useState("");
  //const [isUpdate, setisUpdate] = useState(false);

  const handleOptionChange = (event) => {
    setValue("UserID", event.target.value);
    console.log("Selected User name:", event.target.value);
    //console.log("Selected UserID:", event.target.value);
    const selectedUser = AllUsers.find(
      (user) => user._id === event.target.value
    );
    setUsername(selectedUser ? selectedUser.username : "");
  };

  //const [username, setUsername] = useState("");
  const [type, setType] = useState("");
  const [pickup_locations, setPickup_locations] = useState("");
  const [drop_locations, setDrop_locations] = useState("");
  const [TotalAmount, setTotalAmount] = useState("");
  const [payment_type, setPayment_type] = useState("");
  const [status, setStatus] = useState("");
  const [id, setId] = useState();

  const [username, setUsername] = useState("");

  const handleUpdateRequest = async (data) => {
    try {
      const newData = {
        id: data.id,
        UserID: data.UserID,
        type: data.type,
        pickup_locations: data.pickup_locations,
        drop_locations: data.drop_locations,
        TotalAmount: data.TotalAmount,
        payment_type: data.payment_type,
        status: data.status,
      };

      console.log(newData, id);
      const response = await toast.promise(
        UpdateRequestApi(newData, {
          headers: {
            "Content-Type": "application/json",
          },
        }),
        {
          loading: "Wait Few Moment!",
          success: "Request Updated Successfully!",
          error: "Something Went Wrong!",
        }
      );
      setisUpdate(!isUpdate);
      //document.getElementById("closeUpdateRequest").click();
      // if (response && response.data) {
      //   const updatedData = response.data;
      //   console.log(updatedData);

      //   setItem(updatedData);
      //   setisUpdate(!isUpdate);
      //   fetchData(); // Fetch updated data
      //   reset();
      //   document.getElementById("closeUpdateRequest").click();
      // } else {
      //   throw new Error("No response data");
      // }
    } catch (er) {
      console.log(er, data);
      toast.error("update failed", data);
    }
  };

  useEffect(() => {
    (async function () {
      try {
        const data = await POSTAPI(Api?.ShowUsersApi);
        setAllUsers(data.data);
        console.log(data.data);
        //setVehicles(currentRecords);
      } catch (er) {
        console.log(er);
      }
    })();

    if (item) {
      console.log("Item:", item);
      console.log("UserID:", item.UserID);
      console.log("Username:", item?.UserID?.username);

      setValue("id", item?._id);
      setValue("UserID", item?.UserID?._id || "");
      //setValue("UserID", item?.UserID?.username || "");
      setUsername(item?.UserID?.username || "");
      setValue("type", item?.type || "");
      setValue("pickup_locations", item?.pickup_locations || "");
      setValue("drop_locations", item?.drop_locations || "");
      setValue("TotalAmount", item?.TotalAmount || "");
      setValue("payment_type", item?.payment_type || "");
      setValue("status", item?.status || "");
    }
  }, [item, setValue, isUpdate]);

  return (
    <div
      className="modal fade"
      id="exampleModaledit"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              Edit New Request
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <div className="user-details">
              <form onSubmit={handleSubmit(handleUpdateRequest)}>
                {/* <div className="mb-4">
                  <label className="mb-2">ID</label>
                  <input
                    type="text"
                    {...register("id")}
                    disabled
                    defaultValue={item?._id}
                    // value={TotalAmount}
                    // onChange={(event) => setTotalAmount(event.target.value)}
                    className="form-control"
                  />
                </div> */}
                <div className="form-floating mb-4 mt-2">
                  <select
                    className="form-select form-control"
                    id="floatingSelectGrid"
                    aria-label="Floating label select example"
                    // placeholder={item?.UserID?.username}
                    // defaultValue={item?.UserID}
                    placeholder={username}
                    defaultValue={item?.UserID?._id}
                    //placeholder={item?.UserID?.username}
                    {...register("UserID")}
                    onChange={handleOptionChange}
                  >
                    <option selected value="">
                      Select Name
                    </option>
                    {AllUsers?.map((v) => (
                      <option
                        key={v._id}
                        value={v._id}
                        // onClick={() => handleOptionClick(lvl.name)}
                      >
                        {v.username}
                      </option>
                    ))}
                  </select>
                  <label for="floatingSelectGrid">Select Customer Name</label>
                </div>
                <div className="form-floating mb-3 mt-2">
                  <select
                    className="form-select form-control"
                    id="floatingSelectGrid"
                    aria-label="Floating label select example"
                    defaultValue={item?.type}
                    {...register("type")}
                    // value={type}
                    // onChange={(event) => setType(event.target.value)}
                  >
                    <option Value="All">Select Service Type</option>
                    <option Value="Taxi Booking">Taxi Bookings</option>
                    <option value="Courier Delivery">Courier Bookings</option>
                  </select>
                  <label for="floatingSelectGrid">Service Type</label>
                </div>
                <div className="mb-3">
                  <label className="mb-2">Pickup Location</label>
                  <input
                    type="text"
                    defaultValue={item?.pickup_locations}
                    {...register("pickup_locations")}
                    className="form-control"

                    // value={pickup_locations}
                    // onChange={(event) =>
                    //   setPickup_locations(event.target.value)
                    // }
                  />
                </div>
                <div className="mb-3">
                  <label className="mb-2">Dropoff Location</label>
                  <input
                    type="text"
                    defaultValue={item?.drop_locations}
                    {...register("drop_locations")}
                    // value={drop_locations}
                    // onChange={(event) => setDrop_locations(event.target.value)}
                    className="form-control"
                  />
                </div>
                <div className="mb-4">
                  <label className="mb-2">Total Amount</label>
                  <input
                    type="number"
                    {...register("TotalAmount")}
                    defaultValue={item?.TotalAmount}
                    // value={TotalAmount}
                    // onChange={(event) => setTotalAmount(event.target.value)}
                    className="form-control"
                  />
                </div>
                <div className="form-floating mb-4 mt-2">
                  <select
                    className="form-select form-control"
                    id="floatingSelectGrid"
                    aria-label="Floating label select example"
                    defaultValue={item?.payment_type}
                    {...register("payment_type")}
                    // value={payment_type}
                    // onChange={(event) => setPayment_type(event.target.value)}
                  >
                    <option Value="All">All</option>
                    <option value="Cash Payment">Cash</option>
                    <option value="Card">Card</option>
                  </select>
                  <label for="floatingSelectGrid">Payment Method</label>
                </div>
                <div className="form-floating mb-3 mt-2">
                  <select
                    className="form-select form-control"
                    id="floatingSelectGrid"
                    aria-label="Floating label select example"
                    defaultValue={item?.status}
                    {...register("status")}
                    // value={status}
                    // onChange={(event) => setStatus(event.target.value)}
                  >
                    <option Value="All">Select status</option>
                    <option Value="Complete">Complete</option>
                    <option value="Pending">Pending</option>
                  </select>
                  <label for="floatingSelectGrid">Status</label>
                </div>
                <div className="upload-reset-btn mb-0 justify-content-center pt-2">
                  <button
                    className="btn btn-reset"
                    id="closeUpdateRequest"
                    data-bs-dismiss="modal"
                  >
                    Cancel
                  </button>
                  <button
                    className="btn btn-upload me-0"
                    data-bs-dismiss="modal"
                    type="submit"
                    //onSubmit={handleSubmit(handleUpdateRequest)}
                  >
                    Save
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateModel;
