import React, { useEffect, useState } from "react";
import Sidebar from "./Sidebar";
import Footer from "./Footer";

import TexiBooking from "../common/booking/TexiBooking";
import CourierBooking from "../common/booking/CourierBooking";
import ViewModal from "../common/booking/ViewModal";
import { RemoveBookingApi, ShowBookingApi } from "../helper/integration";
import toast from "react-hot-toast";
import bootstrap from "bootstrap/dist/js/bootstrap.bundle.min";

const Bookings = () => {
  const [isLoad, setisLoad] = useState(false);
  const [AllDetail, setAllDetail] = useState([]);
  const [isUpdate, setisUpdate] = useState(false);
  const [SingleBooking, setSingleBooking] = useState(null);
  const [Texi, setTexi] = useState([]);
  const [Courier, setCourier] = useState([]);
  const [itemIdToDelete, setItemIdToDelete] = useState(null);
  const handleShowSingleData = (item) => setSingleBooking((prev) => item);
  // const handleDeleteDriver = (id) => {
  //   let obj = {
  //     user_id: id,
  //   };
  //   toast
  //     .promise(RemoveBookingApi(obj), {
  //       loading: "Wait Few Moment !",
  //       success: "Booking Detail Delete Successfully !",
  //       error: "Something Wrong !",
  //     })
  //     .then(() => {
  //       setisUpdate(!isUpdate);
  //     })
  //     .catch(() => {});
  // };

  const handleUpdate = () => setisUpdate(!isUpdate);
  useEffect(() => {
    (async function () {
      try {
        const data = await ShowBookingApi();
        setAllDetail(data);
        setTexi((prev) => data?.Texi);
        setCourier((prev) => data?.Courier);
      } catch (er) {
      } finally {
        setTimeout(() => {
          setisLoad(true);
        }, 500);
      }
    })();
  }, [isUpdate]);

  const handleDeleteDriver = (id) => {
    setItemIdToDelete(id);
    const deleteModal = new bootstrap.Modal(
      document.getElementById("deleteConfirmationModal")
    );
    deleteModal.show();
  };

  const confirmDelete = async () => {
    if (itemIdToDelete) {
      try {
        const obj = { user_id: itemIdToDelete };

        await toast.promise(RemoveBookingApi(obj), {
          loading: "Wait a moment!",
          success: "Booking details deleted successfully!",
          error: "Something went wrong!",
        });

        setisUpdate(!isUpdate); // Trigger a state update to refresh the data
      } catch (error) {
        console.error("Error deleting booking:", error); // Log the error for debugging
      } finally {
        setItemIdToDelete(null); // Clear the ID after operation
      }
    } else {
      console.warn("No item ID to delete."); // Log a warning if no ID is set
    }
  };
  return (
    <div>
      {<Sidebar />}
      <div className="asside">
        <div className="about-first">
          <div className="row">
            <div className="col-12 mb-24">
              <div className="bg-box">
                <nav className="mb-3">
                  <div class="nav nav-tabs" id="nav-tab" role="tablist">
                    <button
                      class="nav-link active"
                      id="pills-home-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-home"
                      type="button"
                      role="tab"
                      aria-controls="pills-home"
                      aria-selected="true"
                    >
                      {" "}
                      Taxi Bookings{" "}
                    </button>
                    <button
                      class="nav-link"
                      id="pills-profile-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-profile"
                      type="button"
                      role="tab"
                      aria-controls="pills-profile"
                      aria-selected="false"
                    >
                      {" "}
                      Courier Bookings{" "}
                    </button>
                  </div>
                </nav>
                <div class="tab-content" id="pills-tabContent">
                  <TexiBooking
                    isLoad={isLoad}
                    handleShow={handleShowSingleData}
                    handleRemove={handleDeleteDriver}
                    update={handleUpdate}
                    newLoad={isUpdate}
                  />
                  <CourierBooking
                    item={Courier}
                    isLoad={isLoad}
                    handleShow={handleShowSingleData}
                    handleRemove={handleDeleteDriver}
                    update={handleUpdate}
                    newLoad={isUpdate}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Delete Confirmation Modal */}
        <div
          className="modal fade"
          id="deleteConfirmationModal"
          tabIndex="-1"
          aria-labelledby="deleteConfirmationModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
            <div className="modal-content">
              <div className="modal-header bg-primary">
                <h5
                  className="modal-title text-white"
                  id="deleteConfirmationModalLabel"
                >
                  Confirm Deletion
                </h5>
                <button
                  type="button"
                  className="btn-close bg-white"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <h4 className="py-4 mx-auto">
                  Are you sure you want to delete this Booking?
                </h4>
                <div className="d-flex justify-content-center pt-2">
                  <button
                    id="confirmDeleteButton"
                    className="btn btn-primary me-2"
                    data-bs-dismiss="modal"
                    onClick={confirmDelete}
                  >
                    Yes
                  </button>
                  <button className="btn btn-secondary" data-bs-dismiss="modal">
                    No
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- View Taxi Modal --> */}
        <ViewModal item={SingleBooking} />
        {/* <!--End View Modal --> */}

        {<Footer />}
      </div>
    </div>
  );
};

export default Bookings;
