import React from "react";
import user from "../../Image/user.png";
const ShowDriver = ({ SingleDriver }) => {
  return (
    <div
      className="modal fade"
      id="viewDriver"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              View Driver
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <div className="user-details">
              <form className="row">
                <div className="mb-3 col-md-6 col-12">
                  <label className="mb-2">User Name</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder={SingleDriver?.username}
                    disabled
                  />
                </div>
                <div className="mb-3 col-md-6 col-12">
                  <label className="mb-2">Email ID</label>
                  <input
                    type="mail"
                    className="form-control"
                    placeholder={SingleDriver?.email}
                    disabled
                  />
                </div>
                <div className="mb-3 col-md-6 col-12">
                  <label className="mb-2">Mobile Number</label>
                  <input
                    type="number"
                    className="form-control"
                    placeholder={SingleDriver?.mobilenumber}
                    disabled
                  />
                </div>
                <div className="mb-3 col-md-6 col-12">
                  <label className="mb-2">Residence Address</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder={SingleDriver?.Residence_Address}
                    disabled
                  />
                </div>
                <div className="mb-3 col-md-6 col-12">
                  <label className="mb-2">Profile Image</label>
                  <div className="profile-img">
                    <img
                      src={SingleDriver?.ProfileImage} //drivingLicenseUrl profileUrl ProfileImage
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = user;
                      }}
                    />
                  </div>
                </div>
                <div className="mb-3 col-md-6 col-12">
                  <label className="mb-2">Last Updated Date</label>
                  <input
                    type="date"
                    className="form-control"
                    value="2024-01-10"
                    disabled
                  />
                </div>
                <div className="mb-3 col-md-6 col-12">
                  <label className="mb-2">Pending Balance Amount</label>
                  <input
                    type="number"
                    className="form-control"
                    placeholder="1000"
                    disabled
                  />
                </div>
                <div className="mb-3 col-md-6 col-12">
                  <label className="mb-2">Vehicle Details</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder={SingleDriver?.Vehicle_Details}
                    disabled
                  />
                </div>
                <div className="mb-3 col-md-6 col-12">
                  <label className="mb-2">Account Details</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder={SingleDriver?.accountNumber}
                    disabled
                  />
                </div>
                <div className="mb-3 col-md-6 col-12">
                  <label className="mb-2">Status</label>
                  <div>
                    {SingleDriver?.status === "pending" && (
                      <p className="badge bg-warning me-1 text-white mb-0">
                        Pending
                      </p>
                    )}
                    {SingleDriver?.status === "approve" && (
                      <p className="badge bg-success me-1 text-white mb-0">
                        Approved
                      </p>
                    )}
                    {SingleDriver?.status === "reject" && (
                      <p className="badge bg-danger me-1 text-white mb-0">
                        Reject
                      </p>
                    )}
                  </div>
                </div>

                {/* <div className="mb-3 col-md-6 col-12">
                              <label className='mb-2'>Driving License</label>
                              <div className='profile-img'>
                                  <img src={user} className='img-fluid'/>
                              </div>
                          </div>
                          <div className="mb-3 col-md-6 col-12">
                              <label className='mb-2'>Insurance Certificate</label>
                              <div className='profile-img'>
                                  <img src={user} className='img-fluid'/>
                              </div>
                          </div>
                          <div className="mb-3 col-md-6 col-12">
                              <label className='mb-2'>Safety Documents</label>
                              <div className='profile-img'>
                                  <img src={user} className='img-fluid'/>
                              </div>
                          </div>
                          <div className="mb-3 col-md-6 col-12">
                              <label className='mb-2'>Vehicle Pictures</label>
                              <div className='profile-img'>
                                  <img src={user} className='img-fluid'/>
                                  <img src={user} className='img-fluid'/>
                                  <img src={user} className='img-fluid'/>
                                  <img src={user} className='img-fluid'/>
                              </div>
                          </div>
                          <div className="mb-3 col-md-6 col-12">
                              <label className='mb-2'>Vehicle Details</label>
                              <input type="text" className="form-control" placeholder='Vehicle Details' disabled/>
                          </div>
                          <div className="mb-3 col-md-6 col-12">
                              <label className='mb-2'>Vehicle Type</label>
                              <input type="text" className="form-control" placeholder='Vehicle Type' disabled/>
                          </div>
                          <div className="mb-3 col-md-6 col-12">
                              <label className='mb-2'>Vehicle Company Name</label>
                              <input type="text" className="form-control" placeholder='Vehicle Company Name' disabled/>
                          </div>
                          <div className="mb-3 col-md-6 col-12">
                              <label className='mb-2'>Vehicle Model Name</label>
                              <input type="text" className="form-control" placeholder='Vehicle Model Name' disabled/>
                          </div>
                          <div className="mb-3 col-md-6 col-12">
                              <label className='mb-2'>Plate Number</label>
                              <input type="text" className="form-control" placeholder='Plate Number' disabled/>
                          </div>
                          <div className="mb-3 col-md-6 col-12">
                              <label className='mb-2'>VIN Number</label>
                              <input type="text" className="form-control" placeholder='VIN Number' disabled/>
                          </div>
                          <div className="mb-3 col-md-6 col-12">
                              <label className='mb-2'>Vehicle Color</label>
                              <input type="text" className="form-control" placeholder='Vehicle Color' disabled/>
                          </div> */}
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShowDriver;
