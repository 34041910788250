import React, { useEffect } from "react";
import Sidebar from "./Sidebar";
import Footer from "./Footer";
import EditDriverModel from "../common/FAQ/EditDriverModel";
import EditUserModel from "../common/FAQ/EditUserModel";
import AddDriverModel from "../common/FAQ/AddDriverModel";
import AddUserMode from "../common/FAQ/AddUserModel";
import Driver from "../common/FAQ/Driver";
import User from "../common/FAQ/User";
import { useState } from "react";
import { ShowFAQApi } from "../helper/integration";

const Notification = () => {
  const [Drivers, setDriver] = useState([]);
  const [Users, setUser] = useState([]);
  const [isLoad, setisLoad] = useState(false);
  const [isUpdate, setisUpdate] = useState(false);
  const [SingleData, setSingleData] = useState(null);
  const handleReferesh = () => setisUpdate(!isUpdate);

  useEffect(() => {
    (async function () {
      try {
        const data = await ShowFAQApi();
        setDriver((prev) => data?.Driver);
        setUser((prev) => data?.User);
      } catch (er) {
      } finally {
        setTimeout(() => {
          setisLoad(true);
        }, []);
      }
    })();
  }, [isUpdate]);
  return (
    <div>
      {<Sidebar />}
      <div className="asside">
        <div className="about-first">
          <div className="row">
            <div className="col-12 mb-24">
              <div className="bg-box">
                <nav className="mb-3">
                  <div class="nav nav-tabs" id="nav-tab" role="tablist">
                    <button
                      class="nav-link active"
                      id="pills-home-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-home"
                      type="button"
                      role="tab"
                      aria-controls="pills-home"
                      aria-selected="true"
                    >
                      {" "}
                      Driver{" "}
                    </button>
                    <button
                      class="nav-link"
                      id="pills-profile-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-profile"
                      type="button"
                      role="tab"
                      aria-controls="pills-profile"
                      aria-selected="false"
                    >
                      {" "}
                      User{" "}
                    </button>
                  </div>
                </nav>
                <div class="tab-content" id="pills-tabContent">
                  <Driver
                    DriverData={Drivers}
                    isLoad={isLoad}
                    handleReferesh={handleReferesh}
                    setItem={setSingleData}
                  />
                  <User
                    UserData={Users}
                    isLoad={isLoad}
                    handleReferesh={handleReferesh}
                    setItem={setSingleData}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <!-- Edit Driver FAQ Modal --> */}
        <EditDriverModel
          item={SingleData}
          setItem={setSingleData}
          handleReferesh={handleReferesh}
        />
        {/* <!--End Driver FAQ Modal --> */}
        {/* <!-- Edit User FAQ Modal --> */}
        <EditUserModel
          item={SingleData}
          setItem={setSingleData}
          handleReferesh={handleReferesh}
        />
        {/* <!--End User FAQ Modal --> */}
        {/* <!-- Add Driver FAQ Modal --> */}
        <AddDriverModel handleReferesh={handleReferesh} />
        {/* <!--End Driver FAQ Modal --> */}
        {/* <!-- Add User FAQ Modal --> */}
        <AddUserMode handleReferesh={handleReferesh} />
        {/* <!--End User FAQ Modal --> */}

        {<Footer />}
      </div>
    </div>
  );
};

export default Notification;
