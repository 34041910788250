import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Sidebar from "./Sidebar";
import Footer from "./Footer";
import user from "../Image/user.png";
import GoogleMap from "./GoogleMap";
import { POSTAPI } from "../helper/integration";
import { Api } from "../utils/Api";
import Paggination from "../common/Paggination";

const BirdEyeView = () => {
  const [AllData, setAllData] = useState([]);
  const [isLoad, setisLoad] = useState(false);
  const [Drivers, setDrivers] = useState([]);
  const [Pointer, setPointer] = useState([]);
  const [username, setUsername] = useState([]);
  const [currentPage, setcurrentPage] = useState(1);
  const [recordsPerPage] = useState(10);
  const [Queary, setQueary] = useState("");
  const [isUpdate, setisUpdate] = useState(false);
  const [Filter, setFilter] = useState("");
  const [nPage, setnPage] = useState([1]);
  const handleUpdate = () => setisUpdate(!isUpdate);
  const handleChangePage = (id) => {
    setcurrentPage(id);
    const indexOfLastRecord = id * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    let currentRecords = AllData.slice(indexOfFirstRecord, indexOfLastRecord);
    currentRecords = Queary
      ? currentRecords.filter(
          (item) =>
            item.username.toLowerCase().includes(Queary.toLowerCase()) ||
            item.Residence_Address.toLowerCase().includes(
              Queary.toLowerCase()
            ) ||
            item.mobilenumber.toLowerCase().includes(Queary.toLowerCase())
        )
      : currentRecords;
    setDrivers(currentRecords);
  };

  const handleFilter = (e) => {
    setFilter((prev) => e.target.value);
    handleUpdate();
  };

  const handleSearch = (e) => {
    let val = e.target.value;
    setQueary(val);
    let newArray = AllData.filter((item) => {
      return (
        (item.username &&
          item.username.toLowerCase().includes(val.toLowerCase())) ||
        (item.live_location &&
          item.live_location.toLowerCase().includes(val.toLowerCase())) ||
        (item.mobilenumber &&
          item.mobilenumber.toLowerCase().includes(val.toLowerCase()))
      );
    });
    let newData = [];

    newArray.forEach((element) => {
      newData.push({
        lat: element.current_latitude,
        lng: element.current_longitude,
        available: element.availableStatus,
        username: element.username,
      });
    });
    setPointer(newData);
    setUsername(newData);
    setcurrentPage(1);
    const indexOfLastRecord = 1 * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const currentRecords = newArray.slice(
      indexOfFirstRecord,
      indexOfLastRecord
    );
    setDrivers(currentRecords);
    const nPages = Math.ceil(newArray?.length / recordsPerPage);
    const num = [...Array(nPages + 1).keys()].slice(1);
    setnPage(num);
  };
  useEffect(() => {
    (async () => {
      try {
        setisLoad(true);
        let obj = {};
        if (Filter) obj["filter"] = Filter;
        const data = await POSTAPI(Api.ShowBide, obj);
        setAllData(data.data);
        let newData = [];

        data.data.forEach((element) => {
          newData.push({
            lat: element.current_latitude,
            lng: element.current_longitude,
            available: element.availableStatus,
            username: element.username,
          });
        });
        setPointer(newData);
        setUsername(newData);
        let indexOfLastRecord = 1 * recordsPerPage;
        let indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
        let currentRecords = data?.data?.slice(
          indexOfFirstRecord,
          indexOfLastRecord
        );
        setcurrentPage(1);
        setDrivers(currentRecords);
        let nPages = Math.ceil(data?.data?.length / recordsPerPage);
        let num = [...Array(nPages + 1).keys()].slice(1);
        setnPage(num);
      } catch (er) {
        console.log(er);
      } finally {
        setisLoad(true);
      }
    })();
  }, [isUpdate]);
  return (
    <div>
      {<Sidebar />}
      <div className="asside">
        <div className="about-first">
          <div className="row">
            <div className="col-12 mb-24">
              <div className="bg-box">
                <div className="pro-add-new px-0">
                  <p>BIRD EYE VIEW</p>
                  <div className="d-flex align-items-end justify-content-between">
                    <div className="d-flex align-items-center">
                      <div className="me-3">
                        <select
                          className="form-select form-control"
                          id=""
                          aria-label="Floating label select example"
                          onChange={handleFilter}
                        >
                          <option value="">All</option>
                          <option value="available">Available</option>
                          <option value="busy">Busy</option>
                        </select>
                      </div>
                      <div className="form-floating small-floating">
                        <input
                          type="text"
                          className="form-control py-2 w-auto"
                          id="floatingInput"
                          placeholder="name@example.com"
                          onChange={handleSearch}
                        />
                        <label for="floatingInput">Search</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-flex align-items-end justify-content-between"></div>
                <div className="row">
                  <div className="col-lg-5 col-12">
                    <div class="responsive-map">
                      <GoogleMap
                        initialLat={21.7629202}
                        initialLng={72.129684}
                        points={Pointer}
                        username={username}
                      />
                    </div>
                  </div>
                  <div className="col-lg-7 col-12">
                    <div className="responsive-table">
                      <table className="table">
                        <thead>
                          <tr>
                            <th>Profile Image</th>
                            <th>Driver Name</th>
                            <th>Mobile Number</th>
                            <th>Current Location</th>
                            <th>Status</th>
                            {/* <th>Action</th> */}
                          </tr>
                        </thead>
                        <tbody>
                          {!isLoad ? (
                            <tr>
                              <td colSpan={9}>
                                <div className="d-flex justify-content-center  alig-align-items-center ">
                                  <div className="loader"></div>
                                </div>
                              </td>
                            </tr>
                          ) : !Drivers?.length ? (
                            <tr>
                              <td colSpan={9}>
                                <div className="d-flex justify-content-center  alig-align-items-center ">
                                  <p>Record Not Found !</p>
                                </div>
                              </td>
                            </tr>
                          ) : (
                            Drivers.map((item) => (
                              <tr>
                                <td>
                                  <div className="profile-img">
                                    <img
                                      src={item?.ProfileImage}
                                      onError={(e) =>
                                        (e.currentTarget.src = user)
                                      }
                                      className="img-fluid"
                                    />
                                  </div>
                                </td>
                                <td>{item?.username}</td>
                                <td>{item?.mobilenumber}</td>
                                <td>{item?.live_location}</td>
                                <td>
                                  {item?.availableStatus == "busy" ? (
                                    <Link
                                      to={"#"}
                                      class="badge bg-warning me-1 text-white"
                                    >
                                      Busy
                                    </Link>
                                  ) : (
                                    <Link
                                      to={"#"}
                                      class="badge bg-success me-1 text-white"
                                    >
                                      Available
                                    </Link>
                                  )}
                                </td>
                                {/* <td>
                                  {item?.availableStatus == "busy" ? (
                                    "-"
                                  ) : (
                                    
                                    // <button    {/* Placeholder for removed button */}
                                {/* //   type="button"
                                    //   class="btn btn-upload py-2"
                                    // >
                                    //   Assign
                                    // </button> */}
                                {/* } */}
                                {/* // </td> */}
                              </tr>
                            ))
                          )}
                        </tbody>
                      </table>
                    </div>
                    <Paggination
                      nPage={nPage}
                      currentPage={currentPage}
                      handleChangePage={handleChangePage}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {<Footer />}
      </div>
    </div>
  );
};

export default BirdEyeView;
