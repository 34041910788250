import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import {
  EditBookingApi,
  ShowDriversApi,
  POSTAPI,
} from "../../helper/integration";
import { Api } from "../../utils/Api";
import GoogleMap2 from "../../components/GoogleMap2";
import GoogleMap3 from "../../components/GoogleMap3";
const UpdateModelTexi = ({
  item,
  setItem,
  isUpdate,
  setisUpdate,
  fetchData,
}) => {
  const { register, reset, handleSubmit, setValue } = useForm({
    defaultValues: {
      id: item?.id || "",
      UserID: item?.UserID?._id || "",
      DriverID: item?.DriverID?._id || "",
      type: item?.type || "",
      pickup_locations: item?.pickup_locations || "",
      drop_locations: item?.drop_locations || "",
      TotalAmount: item?.TotalAmount || "",
      payment_type: item?.payment_type || "",
      status: item?.status || "",
    },
  });

  const [PageData, setPageData] = useState([]);
  const [username, setUsername] = useState("");
  const [drivername, setDrivername] = useState("");
  const [AllUsers, setAllUsers] = useState([]);
  const [AllDrivers, setAllDrivers] = useState([]);
  //const [isUpdate, setisUpdate] = useState(false);
  const [Pointer, setPointer] = useState([]);
  const [showPickupMap, setShowPickupMap] = useState(false);
  const [showDropoffMap, setShowDropoffMap] = useState(false);

  const [UserID, setUserID] = useState();
  const [vehical, setVehical] = useState("");
  const [pickup_locations, setPickup_locations] = useState();
  const [current_latitude, setCurrent_latitude] = useState(21.7629202);
  const [current_longitude, setCurrent_longitude] = useState(72.129684);
  const [pickupImage, setPickupImage] = useState(false);
  const [dropofImage, setDropofImage] = useState(false);
  const [drop_locations, setDrop_locations] = useState();
  const [dropup_latitude, setDropup_latitude] = useState(21.7629202);
  const [dropup_longitude, setDropup_longitude] = useState(72.129684);
  const [isMapHide, setMapHide] = useState(false);
  const [distance, setDistance] = useState("");
  const [time, setTime] = useState("");
  //const [username, setUsername] = useState("");
  const [type, setType] = useState("");
  //const [pickup_locations, setPickup_locations] = useState("");
  //const [drop_locations, setDrop_locations] = useState("");
  const [TotalAmount, setTotalAmount] = useState("");
  const [payment_type, setPayment_type] = useState("");
  const [status, setStatus] = useState("");
  const [id, setId] = useState();

  //   const [username, setUsername] = useState("");

  const handleOptionChange = (event) => {
    setValue("UserID", event.target.value);
    const selectedUser = AllUsers.find(
      (user) => user._id === event.target.value
    );
    setUsername(selectedUser ? selectedUser.username : "");
    console.log(selectedUser);
  };

  const handleOptionChange2 = (event) => {
    setValue("DriverID", event.target.value);
    const selectedDriver = AllDrivers.find(
      (driver) => driver._id === event.target.value
    );
    setDrivername(selectedDriver ? selectedDriver.username : "");
    console.log(selectedDriver);
  };

  const calculateDistanceAndDuration = (
    current_latitude,
    current_longitude,
    dropup_latitude,
    dropup_longitude
  ) => {
    // Validate input values
    if (
      typeof current_latitude !== "number" ||
      typeof current_longitude !== "number" ||
      typeof dropup_latitude !== "number" ||
      typeof dropup_longitude !== "number"
    ) {
      console.error("Invalid input values:", {
        current_latitude,
        current_longitude,
        dropup_latitude,
        dropup_longitude,
      });
      return {
        distance: "Invalid",
        time: "Invalid",
      };
    }

    // Convert degrees to radians
    const toRadians = (degree) => degree * (Math.PI / 180);

    // Haversine formula
    const haversineDistance = (lat1, lon1, lat2, lon2) => {
      const R = 6371; // Radius of the Earth in kilometers
      const dLat = toRadians(lat2 - lat1);
      const dLon = toRadians(lon2 - lon1);
      const a =
        Math.sin(dLat / 2) ** 2 +
        Math.cos(toRadians(lat1)) *
          Math.cos(toRadians(lat2)) *
          Math.sin(dLon / 2) ** 2;
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      const distance = R * c; // Distance in kilometers
      return distance;
    };

    const distance = haversineDistance(
      current_latitude,
      current_longitude,
      dropup_latitude,
      dropup_longitude
    );
    console.log(`Distance: ${distance.toFixed(2)} km`);

    // Time per kilometer in minutes
    const timePerKilometer = 3; // 3 minutes per kilometer
    const timeInMinutes = distance * timePerKilometer;

    // Convert total time in minutes to hours and minutes
    const hours = Math.floor(timeInMinutes / 60);
    const minutes = Math.round(timeInMinutes % 60);
    const timeInHours = hours + minutes / 60;

    // Format the time as a string
    const formattedTime =
      timeInMinutes <= 60
        ? `${timeInMinutes.toFixed(2)} Minutes`
        : `${hours}.${(minutes / 60).toFixed(2).split(".")[1]} Hours`;

    console.log(`Estimated travel time: ${formattedTime}`);
    console.log(`Estimated travel time: ${timeInHours.toFixed(2)} Hours`);

    // Update state variables
    setDistance(`${distance.toFixed(2)} KM`);
    setTime(formattedTime);

    return {
      distance: `${distance.toFixed(2)} KM`,
      time: formattedTime,
    };
  };

  useEffect(() => {
    if (
      current_latitude &&
      current_longitude &&
      dropup_latitude &&
      dropup_longitude
    ) {
      calculateDistanceAndDuration(
        current_latitude,
        current_longitude,
        dropup_latitude,
        dropup_longitude
      );
    }
  }, [current_latitude, current_longitude, dropup_latitude, dropup_longitude]);
  const handlePickupLocationClick = () => {
    setShowPickupMap(!showPickupMap);
    setPickupImage(!pickupImage);
  };
  const handleDropofLocationClick = () => {
    setShowDropoffMap(!showDropoffMap);
    setDropofImage(!dropofImage);
  };

  const handlePickupLocationChange = (location) => {
    setCurrent_latitude(location.lat);
    setCurrent_longitude(location.lng);
    setValue("pickup_locations", location.locationName);
    setPickup_locations(location.locationName);

    console.log(location, current_latitude, current_longitude);
    calculateDistanceAndDuration(
      location.lat,
      location.lng,
      dropup_latitude,
      dropup_longitude
    );
  };
  // Drop-off location change handler
  const handleDropLocationChange = (location) => {
    setDropup_latitude(location.lat);
    setDropup_longitude(location.lng);
    setValue("drop_locations", location.locationName2);
    setDrop_locations(location.locationName2); // Ensure this state is used in your form

    // Log for debugging
    console.log(location, dropup_latitude, dropup_longitude);
    calculateDistanceAndDuration(
      location.lat,
      location.lng,
      current_latitude,
      current_longitude
    );
  };

  const handleUpdateRequest = async (data) => {
    try {
      const newData = {
        id: data.id,
        UserID: data.UserID,
        DriverID: data.DriverID,
        current_latitude: String(current_latitude),
        current_longitude: String(current_longitude),
        dropup_latitude: String(dropup_latitude),
        dropup_longitude: String(dropup_longitude),
        pickup_locations: data.pickup_locations,
        drop_locations: data.drop_locations,
        TotalAmount: data.TotalAmount,
        payment_type: data.payment_type,
        status: data.status,
        distance: distance,
        time: time,
      };

      console.log(newData, id);
      const response = await toast.promise(
        EditBookingApi(newData, {
          headers: {
            "Content-Type": "application/json",
          },
        }),
        {
          loading: "Wait Few Moments!",
          success: "Booking Updated Successfully!",
          error: "Something Went Wrong!",
        }
      );
      setisUpdate(!isUpdate);
    } catch (error) {
      console.log(error, data);
      toast.error("Update failed", data);
    }
  };
  useEffect(() => {
    const fetchUsersAndDrivers = async () => {
      try {
        // Fetch users
        const usersData = await POSTAPI(Api?.ShowUsersApi);
        setAllUsers(usersData?.data || []);
        console.log("Users:", usersData?.data);

        // Fetch drivers
        const driversData = await POSTAPI(Api?.ShowDriver); // Ensure that this API endpoint is correct
        setAllDrivers(driversData?.data || []); // Set drivers data correctly
        console.log("Drivers:", driversData?.data);
      } catch (error) {
        console.error("Error fetching users and drivers:", error);
      }
    };

    fetchUsersAndDrivers();

    if (item) {
      console.log("Item:", item);

      setValue("id", item?._id);
      setValue("UserID", item?.UserID?._id || "");
      setValue("DriverID", item?.DriverID?._id || ""); // Set DriverID correctly
      setUsername(item?.UserID?.username || "");
      setDrivername(item?.DriverID?.username || "");
      setValue("type", item?.type || "");
      setValue("pickup_locations", item?.pickup_locations || "");
      setValue("drop_locations", item?.drop_locations || "");
      setValue("TotalAmount", item?.TotalAmount || "");
      setValue("payment_type", item?.payment_type || "");
      setValue("status", item?.status || "");
    }
  }, [item, setValue, isUpdate]);
  useEffect(() => {
    (async function () {
      try {
        const data = await POSTAPI(Api.ShowUsersApi);
        setAllUsers(data.data);
        console.log(data.data);
        //setVehicles(currentRecords);
        let newData = [];

        data.data.forEach((element) => {
          newData.push({
            lat: element.current_latitude,
            lng: element.current_longitude,
            available: element.availableStatus,
          });
        });
        setPointer(newData);
      } catch (er) {
        console.log(er);
      }
    })();
  }, []);
  return (
    <div
      className="modal fade"
      id="exampleModaledit"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              Edit Booking
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <div className="user-details">
              <form onSubmit={handleSubmit(handleUpdateRequest)}>
                {/* <div className="mb-4">
                  <label className="mb-2">ID</label>
                  <input
                    type="text"
                    {...register("id")}
                    disabled
                    defaultValue={item?._id}
                    // value={TotalAmount}
                    // onChange={(event) => setTotalAmount(event.target.value)}
                    className="form-control"
                  />
                </div> */}
                <div className="form-floating mb-4 mt-2">
                  <select
                    className="form-select form-control"
                    id="floatingSelectGrid"
                    aria-label="Floating label select example"
                    placeholder={username}
                    defaultValue={item?.UserID?._id}
                    {...register("UserID")}
                    onChange={handleOptionChange}
                  >
                    <option value="">Select Customer Name</option>
                    {AllUsers?.map((user) => (
                      <option key={user._id} value={user._id}>
                        {user.username}
                      </option>
                    ))}
                  </select>
                  <label htmlFor="floatingSelectGrid">
                    Select Customer Name
                  </label>
                </div>

                <div className="form-floating mb-4 mt-2">
                  <select
                    className="form-select form-control"
                    id="floatingSelectDriver"
                    aria-label="Floating label select example"
                    placeholder={drivername}
                    defaultValue={item?.DriverID?._id}
                    {...register("DriverID")}
                    onChange={handleOptionChange2}
                  >
                    <option value="">Select Driver Name</option>
                    {AllDrivers?.map((driver) => (
                      <option key={driver._id} value={driver._id}>
                        {driver.username}
                      </option>
                    ))}
                  </select>
                  <label htmlFor="floatingSelectDriver">
                    Select Driver Name
                  </label>
                </div>
                <div className="mb-3">
                  <label className="mb-2">Pickup Location</label>
                  <input
                    type="text"
                    {...register("pickup_locations", { required: true })}
                    className="form-control"
                    onClick={handlePickupLocationClick} // Handle click to show pickup map
                  />
                  {/* {pickupImage && (
                      <div className="mt-3">
                        <img
                          src="https://example.com/pickup_image.jpg" // Replace with your image URL
                          alt="Pickup location"
                          className="img-fluid"
                        />
                      </div>
                    )} */}
                  {showPickupMap && (
                    <div className="mt-3 col-12">
                      <div className="responsive-map">
                        <GoogleMap2
                          current_latitude={current_latitude}
                          current_longitude={current_longitude}
                          onLocationChange={handlePickupLocationChange} // Handle location change
                        />
                      </div>
                    </div>
                  )}
                </div>

                <div className="mb-3">
                  <label className="mb-2">Dropoff Location</label>
                  <input
                    type="text"
                    {...register("drop_locations", { required: true })}
                    className="form-control"
                    onClick={handleDropofLocationClick} // Handle click to show dropoff map
                  />
                  {showDropoffMap && (
                    <div className="mt-3 col-12">
                      <div className="responsive-map">
                        <GoogleMap3
                          dropup_latitude={dropup_latitude}
                          dropup_longitude={dropup_longitude}
                          onLocationChange={handleDropLocationChange} // Handle location change for dropoff
                        />
                      </div>
                    </div>
                  )}
                </div>

                <div className="mb-4">
                  <label className="mb-2">Total Amount</label>
                  <input
                    type="number"
                    {...register("TotalAmount")}
                    defaultValue={item?.TotalAmount}
                    // value={TotalAmount}
                    // onChange={(event) => setTotalAmount(event.target.value)}
                    className="form-control"
                  />
                </div>
                <div className="form-floating mb-4 mt-2">
                  <select
                    className="form-select form-control"
                    id="floatingSelectGrid"
                    aria-label="Floating label select example"
                    {...register("payment_type", { required: true })}
                    //{...register("payment_type")}
                    // value={payment_type}
                    // onChange={(event) => setPayment_type(event.target.value)}
                  >
                    <option Value="All">All</option>
                    <option value="Cash Payment">Cash</option>
                    <option value="Card">Card</option>
                  </select>
                  <label for="floatingSelectGrid">Payment Method</label>
                </div>
                <div className="form-floating mb-3 mt-2">
                  <select
                    className="form-select form-control"
                    id="floatingSelectGrid"
                    aria-label="Floating label select example"
                    defaultValue={item?.status}
                    {...register("status")}
                    // value={status}
                    // onChange={(event) => setStatus(event.target.value)}
                  >
                    <option Value="All">Select status</option>
                    <option Value="Complete">Complete</option>
                    <option value="Pending">Pending</option>
                  </select>
                  <label for="floatingSelectGrid">Status</label>
                </div>
                <div className="upload-reset-btn mb-0 justify-content-center pt-2">
                  <button
                    className="btn btn-reset"
                    id="closeUpdateRequest"
                    data-bs-dismiss="modal"
                  >
                    Cancel
                  </button>
                  <button
                    className="btn btn-upload me-0"
                    data-bs-dismiss="modal"
                    type="submit"
                    //onSubmit={handleSubmit(handleUpdateRequest)}
                  >
                    Save
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateModelTexi;
