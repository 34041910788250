import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Sidebar from "./Sidebar";
import Footer from "./Footer";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import {
  AddDispatchRequestApi,
  POSTAPI,
  AddBookingpi,
} from "../helper/integration";
import { Api } from "../utils/Api";
import GoogleMap2 from "./GoogleMap2";
import GoogleMap3 from "./GoogleMap3";
import axios from "axios";
// import user from "../Image/user.png";

const AddNewRequest = () => {
  const {
    register,
    reset,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  const [AllUsers, setAllUsers] = useState([]);
  const [Pointer, setPointer] = useState([]);
  const [showPickupMap, setShowPickupMap] = useState(false);
  const [showDropoffMap, setShowDropoffMap] = useState(false);

  const [UserID, setUserID] = useState();
  const [vehical, setVehical] = useState("");
  const [type, setype] = useState("Booking");
  const [pickup_locations, setPickup_locations] = useState();
  const [current_latitude, setCurrent_latitude] = useState(21.7629202);
  const [current_longitude, setCurrent_longitude] = useState(72.129684);

  const [drop_locations, setDrop_locations] = useState();
  const [dropup_latitude, setDropup_latitude] = useState(21.7629202);
  const [dropup_longitude, setDropup_longitude] = useState(72.129684);

  const [distance, setDistance] = useState("");
  const [time, setTime] = useState("");
  //const apiKey = "AIzaSyBvKlO2WoD5Jv272i3gwp7dxhI6tIyWqwI";

  const [TotalAmount, setTotalAmount] = useState();
  const [payment_type, setPayment_type] = useState();

  const [isUpdate, setisUpdate] = useState();

  const [AllVehicle, setAllVehicle] = useState([]);
  //const [Vehicle_type, setVehicle_type] = useState("");
  //const [type, setype] = useState("Booking");
  // HIDE & SHOW MAP AS PICK
  const [isMapHide, setMapHide] = useState(false);

  const [username, setUsername] = useState("");

  // const Api = {
  //   AddBooking: "https://younoplus.com/api/booking/booking",
  // };

  // const AddBookingpi = async (obj) => {
  //   try {
  //     const { data } = await axios.post(Api.AddBooking, obj);
  //     console.log(data);
  //     return data;
  //   } catch (error) {
  //     console.error("API request failed:", error.response || error.message);
  //     console.log(error);
  //     throw error;
  //   }
  // };
  const handleAddRequest = async (data) => {
    //let newData = new FormData();
    const newData = {
      UserID: data.UserID,
      type: data.type,
      vehical: data.vehical,
      service_stype: data.service_stype,

      current_latitude: String(current_latitude),
      current_longitude: String(current_longitude),
      pickup_locations: pickup_locations,

      dropup_latitude: String(dropup_latitude),
      dropup_longitude: String(dropup_longitude),
      drop_locations: drop_locations,

      Price: parseInt(data.Price, 10),
      payment_type: data.payment_type,

      distance: distance,
      time: time,
      status: "Pending",
    };

    // POSTAPI(Api.ShowVehicle);
    try {
      console.log(data, newData);
      await toast.promise(AddBookingpi(newData), {
        loading: "Wait Few Moment!",
        success: "New Request Booking Successfully!",
        error: "Something Wrong!",
      });
      reset();
      setisUpdate(!isUpdate);
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong!");
    }
  };

  useEffect(() => {
    (async function () {
      try {
        const data = await POSTAPI(Api.ShowVehicle);
        setAllVehicle(data.data);
        console.log(data.data);
        //setVehicles(currentRecords);
      } catch (er) {
        console.log(er);
      }
    })();
  }, []);

  useEffect(() => {
    (async function () {
      try {
        const data = await POSTAPI(Api.ShowUsersApi);
        setAllUsers(data.data);
        console.log(data.data);
        //setVehicles(currentRecords);
        let newData = [];

        data.data.forEach((element) => {
          newData.push({
            lat: element.current_latitude,
            lng: element.current_longitude,
            available: element.availableStatus,
          });
        });
        setPointer(newData);
      } catch (er) {
        console.log(er);
      }
    })();
  }, []);

  const handleInputChange = (e) => {
    setMapHide(!isMapHide);
    console.log(e);
    // setPickupLocation(e.target.value);
    // setIsMapVisible(!!e.target.value); // Show map if there is any input value
  };

  const handleOptionChange = (event) => {
    const { name, value } = event.target;
    if (name === "UserID") {
      setUserID(value);
    } else if (name === "type") {
      setype(value);
    } else if (name === "vehical") {
      setVehical(value);
    } else if (name === "service_stype") {
      setValue("service_stype", value);
    }
    //setVehicle(event.target.value);
    console.log("Selected values:", UserID, type, vehical);
  };

  const handleLocationChange = (location) => {
    setCurrent_latitude(location.lat);
    setCurrent_longitude(location.lng);
    setValue("pickup_locations", location.locationName);
    setPickup_locations(location.locationName);
    console.log(location, current_latitude, current_longitude);
    calculateDistanceAndDuration(
      location.lat,
      location.lng,
      dropup_latitude,
      dropup_longitude
    );
  };
  const handleLocationChange2 = (location) => {
    setDropup_latitude(location.lat);
    setDropup_longitude(location.lng);
    setValue("drop_locations", location.locationName2);
    setDrop_locations(location.locationName2);
    console.log(location, dropup_latitude, dropup_longitude);
    calculateDistanceAndDuration(
      current_latitude,
      current_longitude,
      location.lat,
      location.lng
    );
  };

  const calculateDistanceAndDuration = (
    current_latitude,
    current_longitude,
    dropup_latitude,
    dropup_longitude
  ) => {
    // Convert degrees to radians
    const toRadians = (degree) => degree * (Math.PI / 180);

    // Haversine formula
    const haversineDistance = (lat1, lon1, lat2, lon2) => {
      const R = 6371; // Radius of the Earth in kilometers
      const dLat = toRadians(lat2 - lat1);
      const dLon = toRadians(lon2 - lon1);
      const a =
        -Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(toRadians(lat1)) *
          Math.cos(toRadians(lat2)) *
          Math.sin(dLon / 2) *
          Math.sin(dLon / 2);
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      const distance = R * c; // Distance in kilometers
      return distance;
    };

    const distance = haversineDistance(
      current_latitude,
      current_longitude,
      dropup_latitude,
      dropup_longitude
    );
    console.log(`Distance: ${distance.toFixed(2)} km`);

    // Time per kilometer in minutes
    const timePerKilometer = 3; // 3 minutes per kilometer
    const timeInMinutes = distance * timePerKilometer;

    // Convert total time in minutes to hours and minutes
    const hours = Math.floor(timeInMinutes / 60);
    const minutes = timeInMinutes % 60;
    const timeInHours = hours + minutes / 60;

    // Format the time as a string
    const formattedTime =
      timeInMinutes <= 60
        ? `${timeInMinutes.toFixed(2)} Minutes`
        : `${hours}.${(minutes / 60).toFixed(2).split(".")[1]} Hours`;

    console.log(`Estimated travel time: ${formattedTime}`);
    console.log(`Estimated travel time: ${timeInHours.toFixed(2)} Hours`);

    // Update state variables
    setDistance(`${distance.toFixed(2)} KM`);
    setTime(formattedTime);

    // console.log(`Estimated travel time: ${formattedTime}`);
    // console.log(`Estimated travel time: ${timeInHours.toFixed(2)} hours`);

    // Update state variables
    // setDistance(`${distance.toFixed(2)} KM`);
    // setTime(`${formattedTime} hours`);

    return {
      distance: `${distance.toFixed(2)} KM`,
      time: formattedTime,
    };
  };

  useEffect(() => {
    calculateDistanceAndDuration(
      current_latitude,
      current_longitude,
      dropup_latitude,
      dropup_longitude
    );
  }, [current_latitude, current_longitude, dropup_latitude, dropup_longitude]);

  const togglePickupMap = () => {
    setShowPickupMap(!showPickupMap);
    setShowDropoffMap(false);
  };

  const toggleDropoffMap = () => {
    setShowDropoffMap(!showDropoffMap);
    setShowPickupMap(false);
  };

  const filteredVehicles =
    type === ""
      ? []
      : AllVehicle.filter(
          (v) =>
            (type === "Taxi Booking" && v.type === "Booking") ||
            (type === "Courier Delivery" && v.type === "Courier")
        );

  return (
    <div>
      {<Sidebar />}
      <div className="asside">
        <div className="about-first">
          <div className="row">
            <div className="col-12 mb-24">
              <div className="bg-box">
                <div class="tab-content" id="pills-tabContent">
                  <div>
                    <div className="pro-add-new px-0">
                      <p>ADD NEW REQUEST</p>
                    </div>
                    <form onSubmit={handleSubmit(handleAddRequest)}>
                      <div className="form-floating mb-4 mt-2">
                        <select
                          className="form-select form-control"
                          id="floatingSelectGrid"
                          aria-label="Floating label select example"
                          {...register("UserID", { required: true })}
                          name="UserID"
                          onChange={handleOptionChange}
                        >
                          <option selected>Select</option>

                          {AllUsers?.map((v) => (
                            <option
                              key={v._id}
                              value={v._id}
                              // onClick={() => handleOptionClick(lvl.name)}
                            >
                              {v.username}
                            </option>
                          ))}
                        </select>
                        <label for="floatingSelectGrid">
                          Select Customer Name
                        </label>
                      </div>
                      <div className="form-floating mb-3 mt-2">
                        <select
                          className="form-select form-control"
                          id="floatingSelectGrid"
                          aria-label="Floating label select example"
                          {...register("type", { required: true })}
                          onChange={handleOptionChange}
                          name="type"
                          //onChange={handleOptionChange}
                        >
                          {/* <option selected>
                                Select
                              </option> */}
                          <option selected value="Taxi Booking">
                            Taxi Bookings
                          </option>
                          <option value="Courier Delivery">
                            Courier Bookings
                          </option>
                        </select>
                        <label for="floatingSelectGrid">Booking Type</label>
                      </div>
                      <div className="form-floating mb-3 mt-4">
                        <label className="mb-2"></label>
                        <select
                          className="form-select form-control"
                          id=""
                          aria-label="Floating label select example"
                          {...register("vehical", { required: true })}
                          onChange={handleOptionChange}
                        >
                          {filteredVehicles?.map((v) => (
                            <option
                              key={v.vehicle_name}
                              value={v.vehicle_name} // && v._id
                              // onClick={() => handleOptionClick(lvl.name)}
                            >
                              {v.vehicle_name}
                            </option>
                          ))}
                        </select>
                        <label for="floatingSelectGrid">Vehicle Type</label>
                      </div>
                      <div className="form-floating mb-3 mt-4">
                        <label className="mb-2"></label>
                        <select
                          className="form-select form-control"
                          id=""
                          aria-label="Floating label select example"
                          {...register("service_stype", { required: true })}
                          //onClick={handleOptionChange}
                        >
                          <option selected value="All">
                            Select Type
                          </option>
                          <option value="Ride Now" selected>
                            Ride Now
                          </option>
                          {/* <option value="Card">Card</option> */}
                        </select>
                        <label for="floatingSelectGrid">Service Type</label>
                      </div>
                      <div className="mb-3">
                        <label className="mb-2">Pickup Location</label>
                        <input
                          type="text"
                          {...register("pickup_locations", { required: true })}
                          className="form-control"
                          onClick={togglePickupMap}
                          onChange={handleLocationChange}
                        />
                        {showPickupMap && (
                          <div className="mt-3 col-12">
                            <div className="responsive-map">
                              <GoogleMap2
                                current_latitude={current_latitude}
                                current_longitude={current_longitude}
                                points={Pointer}
                                onLocationChange={handleLocationChange}
                              />
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="mb-3">
                        <label className="mb-2">Dropoff Location</label>
                        <input
                          type="text"
                          {...register("drop_locations", { required: true })}
                          className="form-control"
                          onClick={toggleDropoffMap}
                          onChange={handleLocationChange2}
                        />
                        {showDropoffMap && (
                          <div className="mt-3 col-12">
                            <div className="responsive-map">
                              <GoogleMap3
                                dropup_latitude={dropup_latitude}
                                dropup_longitude={dropup_longitude}
                                points={Pointer}
                                onLocationChange={handleLocationChange2}
                              />
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="mb-4">
                        <label className="mb-2">Booking Amount</label>
                        <input
                          type="number"
                          {...register("Price", { required: true })}
                          className="form-control"
                        />
                      </div>
                      <div className="form-floating mb-4 mt-4">
                        <select
                          className="form-select form-control"
                          id="floatingSelectGrid"
                          aria-label="Floating label select example"
                          {...register("payment_type", { required: true })}
                        >
                          <option value="Cash Payment" selected>
                            Cash
                          </option>
                          <option value="Card">Card</option>
                        </select>
                        <label for="floatingSelectGrid">Payment Method</label>
                      </div>
                      <div className="upload-reset-btn mb-0 justify-content-center pt-2">
                        <button
                          className="btn btn-reset"
                          data-bs-dismiss="modal"
                        >
                          Cancel
                        </button>
                        <button
                          className="btn btn-upload me-0"
                          data-bs-dismiss="modal"
                          type="submit"
                          //onClick={() => alert("API hit successfully!")}
                        >
                          Save
                        </button>
                      </div>
                    </form>
                    {/* <button
                          className="btn btn-success mt-3"
                          }
                        >
                          Test API Hit
                        </button> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {<Footer />}
      </div>
    </div>
  );
};

export default AddNewRequest;
