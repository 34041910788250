import React from "react";

const ViewModal = ({ item }) => {
  function convertDateFormat(dateString) {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    let hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12; // Handle midnight (0 hours)
    const time = hours.toString().padStart(2, "0") + ":" + minutes + " " + ampm;
    return `${day}-${month}-${year} ${time}`;
  }
  return (
    <div
      className="modal fade"
      id="viewexampleModaledit"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              View {item?.type}
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <div className="user-details">
              <form className="row">
                <div className="mb-3 col-md-6 col-12">
                  <label className="mb-2">Trip ID</label>
                  <input
                    type="number"
                    className="form-control"
                    placeholder={item?.RideId}
                    disabled
                  />
                </div>
                <div className="mb-3 col-md-6 col-12">
                  <label className="mb-2">Customer Name</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder={item?.UserID?.username}
                    disabled
                  />
                </div>
                <div className="mb-3 col-md-6 col-12">
                  <label className="mb-2">Driver Name</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder={item?.DriverID?.username}
                    disabled
                  />
                </div>
                <div className="mb-3 col-md-6 col-12">
                  <label className="mb-2">Pickup Location</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder={item?.pickup_locations}
                    disabled
                  />
                </div>
                <div className="mb-3 col-md-6 col-12">
                  <label className="mb-2">Dropoff Location</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder={item?.drop_locations}
                    disabled
                  />
                </div>
                <div className="mb-3 col-md-6 col-12">
                  <label className="mb-2">Total Distance</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder={item?.distance ?? "-"}
                    disabled
                  />
                </div>
                <div className="mb-3 col-md-6 col-12">
                  <label className="mb-2">Promo Code</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder={item?.Discount || "-"}
                    disabled
                  />
                </div>
                <div className="mb-3 col-md-6 col-12">
                  <label className="mb-2">Payment Method</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder={item?.payment_type}
                    disabled
                  />
                </div>
                <div className="mb-3 col-md-6 col-12">
                  <label className="mb-2">Total Amount</label>
                  <input
                    type="number"
                    className="form-control"
                    placeholder={item?.TotalAmount}
                    disabled
                  />
                </div>
                <div className="mb-3 col-md-6 col-12">
                  <label className="mb-2">Created At</label>
                  <input
                    type="date-time"
                    className="form-control"
                    placeholder={
                      item?.createdAt ? convertDateFormat(item?.createdAt) : "-"
                    }
                    disabled
                  />
                </div>
                <div className="mb-3 col-md-6 col-12">
                  <label className="mb-2">Status</label>
                  <div>
                    {item?.status == "Complete" ? (
                      <p class="badge bg-success me-1 text-white mb-0">
                        Complete
                      </p>
                    ) : (
                      <p class="badge bg-warning me-1 text-white mb-0">
                        Pending
                      </p>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewModal;
