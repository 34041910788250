import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Sidebar from "./Sidebar";
import Footer from "./Footer";
import user from "../Image/user.png";
import AddModal from "../common/vehicle/AddModal";
import {
  POSTAPI,
  RemovePendingBookingApi,
  ShowNewRequestsApi,
} from "../helper/integration";
import { Api } from "../utils/Api";
import Pagination from "../common/Paggination";
import NotFound from "../Image/notfound.png";
import toast from "react-hot-toast";
import ViewModal2 from "../common/dispatcher/ViewModel2";
import UpdateModel from "../common/dispatcher/UpdateModel";
import { CSVLink } from "react-csv";
import Papa from "papaparse";
// import { request } from "express"

const NewRequest = () => {
  const [SingleBooking, setSingleBooking] = useState(null);
  const [isLoad, setisLoad] = useState(false);
  const [currentPage, setcurrentPage] = useState(1);
  const [isDisble, setisDisble] = useState(false);
  const [recordsPerPage] = useState(10);
  const [nPage, setnPage] = useState([1]);
  const [AllRequests, setAllRequests] = useState([]);
  const [Requests, setRequests] = useState([]);

  const [MainFilter, setMainFilter] = useState([]);
  const [FilterPayment, setFilterPayment] = useState("");
  const [FilterData, setFilterData] = useState("");
  const [AllData, setAllData] = useState([]);
  const [type, setType] = useState("");
  const [payment_type, setPayment_type] = useState("");
  const [search, setSearch] = useState("");
  const [importRequests, setImportRequests] = useState([]);
  const [isUpdate, setisUpdate] = useState(false);
  const [ReqIdToDelete, setReqIdToDelete] = useState(null);

  const handleFilter = async (e) => {
    const { value } = e.target;
    setType(value);
    setisUpdate((prev) => !prev);
    console.log(value);
  };
  const handleFilterWithPayment = (e) => {
    const { value } = e.target;
    setPayment_type(value);
    setisUpdate((prev) => !prev);
    console.log(value);
  };

  const handleShowSingleData = (item) => setSingleBooking((prev) => item);

  // const handleEditSingleData = (id) => {
  //   let obj = {
  //     id: id,
  //   };
  // };
  const handleChangePage = (id) => {
    setcurrentPage(id);
    const indexOfLastRecord = id * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    let currentRecords = AllRequests.slice(
      indexOfFirstRecord,
      indexOfLastRecord
    );
    setRequests(currentRecords);
  };

  const handleDeleteRequest = () => {
    if (ReqIdToDelete) {
      let obj = {
        user_id: ReqIdToDelete,
      };
      toast
        .promise(RemovePendingBookingApi(obj), {
          loading: "Wait Few Moment !",
          success: "New Request Delete Successfully !",
          error: "Something Wrong !",
        })
        .then(() => {
          setisUpdate(!isUpdate);
          setReqIdToDelete(null);
        })
        .catch(() => {});
    }
  };

  useEffect(() => {
    (async function () {
      try {
        const data = await ShowNewRequestsApi();
        let pendingData = data.AllBook.filter(
          (request) => request.status === "Pending"
        );
        pendingData.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        );

        if (type && type !== "All") {
          pendingData = pendingData.filter((request) => request.type === type);
        } else if (type === "Taxi Booking") {
          pendingData = pendingData.filter(
            (request) => request.type === "Taxi Booking"
          );
        } else if (type === "Courier Delivery") {
          pendingData = pendingData.filter(
            (request) => request.type === "Courier Delivery"
          );
        }

        if (payment_type && payment_type !== "All") {
          pendingData = pendingData.filter(
            (request) => request.payment_type === payment_type
          );
        } else if (payment_type === "Cash Payment") {
          pendingData = pendingData.filter(
            (request) => request.payment_type === "Cash Payment"
          );
        } else if (payment_type === "Card") {
          pendingData = pendingData.filter(
            (request) => request.payment_type === "Card"
          );
        }

        console.log(pendingData);
        setAllRequests(pendingData);
        let indexOfLastRecord = currentPage * recordsPerPage;
        let indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
        let currentRecords = pendingData.slice(
          indexOfFirstRecord,
          indexOfLastRecord
        );
        setRequests(currentRecords);
        let nPages = Math.ceil(pendingData?.length / recordsPerPage);
        let num = [...Array(nPages + 1).keys()].slice(1);
        setnPage(num);
      } catch (er) {
        console.log(er);
      } finally {
        setisLoad(true);
      }
    })();
  }, [isUpdate, type, payment_type]);

  useEffect(() => {
    // Reset to the first page when the search term changes
    setcurrentPage(1);
  }, [search]);

  useEffect(() => {
    // Update the filtered results when the search term changes
    const filteredData = AllRequests.filter((item) =>
      search.toLowerCase() === ""
        ? true
        : item.drop_locations.toLowerCase().includes(search.toLowerCase()) ||
          item?.UserID?.username.toLowerCase().includes(search.toLowerCase()) ||
          item.pickup_locations.toLowerCase().includes(search.toLowerCase())
    );
    const indexOfLastRecord = 1 * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const currentRecords = filteredData.slice(
      indexOfFirstRecord,
      indexOfLastRecord
    );
    setRequests(currentRecords);
    const nPages = Math.ceil(filteredData.length / recordsPerPage);
    const num = [...Array(nPages + 1).keys()].slice(1);
    setnPage(num);
  }, [search, AllRequests]);

  function convertDateFormat(dateString) {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    let hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12; // Handle midnight (0 hours)
    const time = hours.toString().padStart(2, "0") + ":" + minutes + " " + ampm;
    return `${day}-${month}-${year} ${time}`;
  }

  const handleImportData = (e) => {
    const file = e.target.files[0];
    if (file) {
      Papa.parse(file, {
        complete: function (result) {
          const headerRow = result.data[0];
          const rowsWithData = result.data.slice(1);
          const formattedRows = rowsWithData.map((row) => {
            const formattedRow = {};
            headerRow.forEach((header, index) => {
              formattedRow[header] = row[index];
            });
            return formattedRow;
          });
          setImportRequests((prev) => formattedRows);
          document.getElementById("OpenImportModal").click();
        },
        header: false, // Assuming no header row in the CSV
      });
    }
    e.target.files = null;
  };

  const handleUpdateRequestSuccess = (updatedData) => {
    setAllRequests((prevRequests) =>
      prevRequests.map((request) =>
        request._id === updatedData._id ? updatedData : request
      )
    );
    setisUpdate((prev) => !prev);
  };

  return (
    <div>
      {<Sidebar />}
      <div className="asside">
        <div className="about-first">
          <div className="row">
            <div className="col-12 mb-24">
              <div className="bg-box">
                <div className="pro-add-new px-0">
                  <p>NEW REQUEST</p>
                  <div className="form-floating small-floating">
                    <input
                      type="text"
                      className="form-control py-2 w-auto"
                      id="floatingInput"
                      placeholder="name@example.com"
                      //onChange={handleSearch}
                      onChange={(e) => setSearch(e.target.value)}
                    />
                    <label for="floatingInput">Search</label>
                  </div>
                </div>
                <div className="d-flex align-items-end justify-content-between">
                  <div className="d-flex">
                    <div className="mb-3 me-3">
                      <label className="mb-2">Service Type</label>
                      <select
                        className="form-select form-control"
                        id=""
                        aria-label="Floating label select example"
                        onChange={handleFilter}
                      >
                        <option Value="All">All</option>
                        <option value="Taxi Booking">Taxi Bookings</option>
                        <option value="Courier Delivery">
                          Courier Bookings
                        </option>
                      </select>
                    </div>
                    <div className="mb-3">
                      <label className="mb-2">Payment Method Filter</label>
                      <select
                        className="form-select form-control"
                        id=""
                        aria-label="Floating label select example"
                        onChange={handleFilterWithPayment}
                      >
                        <option Value="All">All</option>
                        <option value="Cash Payment">Cash</option>
                        <option value="Card">Card</option>
                      </select>
                    </div>
                  </div>
                  <div
                    class="mb-3 btn-group"
                    role="group"
                    aria-label="Basic outlined example"
                  >
                    <CSVLink
                      data={AllRequests}
                      // headers={[
                      //   { label: "Username", key: "UserID.username" },
                      //   { label: "User ID", key: "UserID._id" },
                      //   { label: "Service Type", key: "type" },
                      //   { label: "Payment Method", key: "payment_type" },
                      //   { label: "Amount", key: "TotalAmount" },
                      //   { label: "Pick-up Location", key: "pickup_locations" },
                      //   { label: "Drop-off Location", key: "drop_locations" },
                      //   { label: "Status", key: "status" },
                      //   { label: "Booking Date", key: "createdAt" },
                      // ]}
                      filename={"New_requests.csv"}
                      type="button"
                      id="exportRequests"
                      className="btn btn-outline-primary shadow-none"
                      hidden
                      target="_blank"
                    >
                      EXPORT CSV
                    </CSVLink>
                    <Link
                      data-bs-toggle="modal"
                      data-bs-target="#exportRequestsDetail"
                      type="button"
                      className="btn btn-outline-primary shadow-none"
                    >
                      EXPORT CSV
                    </Link>
                    <Link
                      id="OpenImportModal"
                      data-bs-toggle="modal"
                      data-bs-target="#importRequestsDetail"
                      type="button"
                      class="btn btn-outline-primary shadow-none"
                      hidden
                    >
                      Import CSV
                    </Link>
                    <input
                      type="file"
                      id="importRequests"
                      onChange={handleImportData}
                      hidden
                    />
                    <label htmlFor="importRequests">
                      <div class="btn btn-outline-primary shadow-none   ">
                        IMPORT CSV
                      </div>
                    </label>
                  </div>
                </div>
                <div className="responsive-table">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>No</th>
                        <th>Trip ID</th>
                        <th>Customer Name</th>
                        <th>Pickup Location</th>
                        <th>Dropoff Location</th>
                        <th>Total Amount</th>
                        <th>Payment Method</th>
                        {/* <th>Vehicle Type</th> */}
                        <th>Service Type</th>
                        <th>Created At</th>
                        <th>Status</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {!isLoad ? (
                        <tr>
                          <td colSpan={9}>
                            <div className="d-flex justify-content-center align-items-center">
                              <div className="loader"></div>
                            </div>
                          </td>
                        </tr>
                      ) : !Requests.length ? (
                        <tr>
                          <td colSpan={9}>
                            <div
                              className="d-flex flex-column  justify-content-center align-items-center mt-2  "
                              style={{ height: "15vh" }}
                            >
                              <img
                                src={NotFound}
                                style={{ height: "100px" }}
                                alt=""
                              />
                              <div> New dispatch Request not found !</div>
                            </div>
                          </td>
                        </tr>
                      ) : Requests?.length ? (
                        Requests.filter((item) => {
                          return search.toLowerCase() === ""
                            ? true
                            : item.drop_locations
                                .toLowerCase()
                                .includes(search.toLowerCase()) ||
                                item?.UserID?.username
                                  .toLowerCase()
                                  .includes(search.toLowerCase()) ||
                                item.pickup_locations
                                  .toLowerCase()
                                  .includes(search.toLowerCase());
                        }).map((item, index) => (
                          <tr key={index}>
                            <td>
                              {currentPage === 1
                                ? index + 1
                                : index + 1 + (currentPage - 1) * 10}
                            </td>
                            <td>{item?.RideId}</td>
                            <td>{item?.UserID?.username}</td>
                            <td>{item.pickup_locations}</td>
                            <td>{item.drop_locations}</td>
                            <td>{item.TotalAmount}</td>
                            <td>{item.payment_type}</td>
                            {/* <td>{item.vehical}</td> */}
                            <td>
                              <div>
                                {item?.type === "Taxi Booking" && (
                                  <Link
                                    to={"#"}
                                    className="badge bg-info me-1 text-white"
                                  >
                                    Taxi Bookings
                                  </Link>
                                )}
                                {item?.type === "Courier Delivery" && (
                                  <Link
                                    to={"#"}
                                    className="badge bg-dark me-1 text-white"
                                  >
                                    Courier Bookings
                                  </Link>
                                )}
                              </div>
                            </td>
                            <td>
                              {item?.createdAt
                                ? convertDateFormat(item?.createdAt)
                                : "-"}
                            </td>
                            <td>
                              <div>
                                {item?.status === "Pending" && (
                                  <Link
                                    to={"#"}
                                    className="badge bg-warning me-1 text-white"
                                  >
                                    Pending
                                  </Link>
                                )}
                                {item?.status === "Complete" && (
                                  <Link
                                    to={"#"}
                                    className="badge bg-success me-1 text-white"
                                  >
                                    Complete
                                  </Link>
                                )}
                              </div>
                            </td>
                            <td>
                              <div className="icon-up-del">
                                <Link
                                  type="button"
                                  data-bs-toggle="modal"
                                  data-bs-target="#exampleModaledit"
                                  onClick={() => handleShowSingleData(item)}
                                >
                                  <i className="fa-solid fa-pen"></i>
                                </Link>
                                <Link>
                                  <i
                                    // onClick={() =>
                                    //   handleDeleteRequest(item?._id)
                                    // }
                                    className="fa-solid fa-trash"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      setReqIdToDelete(item._id);
                                    }}
                                    data-bs-toggle="modal"
                                    data-bs-target="#deleteConfirmationModal"
                                  ></i>
                                </Link>
                                <Link
                                  type="button"
                                  data-bs-toggle="modal"
                                  data-bs-target="#viewexampleModaledit"
                                  onClick={() => handleShowSingleData(item)}
                                >
                                  <i className="fa-solid fa-eye"></i>
                                </Link>
                              </div>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan={9}>
                            <div
                              className="d-flex flex-column  justify-content-center align-items-center mt-2  "
                              style={{ height: "15vh" }}
                            >
                              <img
                                src={NotFound}
                                style={{ height: "100px" }}
                                alt=""
                              />
                              <div> New dispatch Request not found !</div>
                            </div>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
                <Pagination
                  currentPage={currentPage}
                  nPage={nPage}
                  handleChangePage={handleChangePage}
                />
                <div
                  className="modal fade"
                  id="exportRequestsDetail"
                  tabindex="-1"
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">
                          Export New Requests Details
                        </h5>
                        <button
                          type="button"
                          className="btn-close"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        ></button>
                      </div>
                      <div className="modal-body">
                        <div className="user-details">
                          <div>
                            <div>
                              <h4 className="py-4 mx-auto ">
                                Are sure to want export New Reqeusts Details !
                              </h4>
                            </div>
                            <div className="upload-reset-btn mb-0 justify-content-center pt-2">
                              <button
                                id="closeEdit"
                                className="btn btn-reset"
                                data-bs-dismiss="modal"
                              >
                                No
                              </button>
                              <button
                                onClick={() => {
                                  document
                                    .getElementById("exportRequests")
                                    .click();
                                }}
                                data-bs-dismiss="modal"
                                className="btn btn-upload me-0"
                              >
                                Yes
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="modal fade"
                  id="importRequestsDetail"
                  tabindex="-1"
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">
                          Import New Requests Details
                        </h5>
                        <button
                          type="button"
                          className="btn-close"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        ></button>
                      </div>
                      <div className="modal-body">
                        <div className="user-details">
                          <div>
                            <div>
                              <h4 className="py-4 mx-auto ">
                                Do You Want Add Duplicate Data ?{" "}
                              </h4>
                              <p>Total Data : {AllRequests.length}</p>
                              <p>
                                Duplicate Data :{" "}
                                {
                                  importRequests?.filter((el) =>
                                    AllRequests?.some(
                                      (item) => item?._id == el?._id
                                    )
                                  ).length
                                }
                              </p>
                              <p>
                                New Data :{" "}
                                {
                                  importRequests?.filter(
                                    (el) =>
                                      !AllRequests?.some(
                                        (item) => item?._id == el?._id
                                      )
                                  ).length
                                }
                              </p>
                            </div>
                            <div className="upload-reset-btn mb-0 justify-content-center pt-2">
                              <button
                                onClick={() => {
                                  let newData = importRequests?.filter(
                                    (el) =>
                                      !AllRequests?.some(
                                        (item) => item?._id == el?._id
                                      )
                                  );
                                  if (!newData?.length)
                                    return toast.error("Data Not Found !");
                                  toast
                                    .promise(
                                      POSTAPI(Api.AddBookingCSV, {
                                        data: newData?.map((item) => {
                                          let { _id, ...newRow } = item;
                                          return newRow;
                                        }),
                                      }),
                                      {
                                        loading: "Wait Few Moment !",
                                        success:
                                          "New Requests Add Successfully !",
                                        error: "CSV Structure Not Match !",
                                      }
                                    )
                                    .then(() => {
                                      setisUpdate(!isUpdate);
                                    })
                                    .catch(() => {});
                                }}
                                className="btn btn-reset"
                                data-bs-dismiss="modal"
                              >
                                No
                              </button>
                              <button
                                onClick={() => {
                                  toast
                                    .promise(
                                      POSTAPI(Api.AddBookingCSV, {
                                        data: importRequests?.map((item) => {
                                          let { _id, ...newRow } = item;
                                          return newRow;
                                        }),
                                      }),
                                      {
                                        loading: "Wait Few Moment !",
                                        success:
                                          "New Requests Add Successfully !",
                                        error: "CSV Structure Not Match !",
                                      }
                                    )
                                    .then(() => {
                                      setisUpdate(!isUpdate);
                                    })
                                    .catch(() => {});
                                }}
                                data-bs-dismiss="modal"
                                className="btn btn-upload me-0"
                              >
                                Yes
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <!-- Edit Modal --> */}
        <UpdateModel
          item={SingleBooking}
          setItem={setSingleBooking}
          isUpdate={isUpdate}
          setisUpdate={setisUpdate}
          onUpdateSuccess={handleUpdateRequestSuccess}
          // item={SingleBooking}
          // setItem={handleShowSingleData}
          // onUpdateSuccess={handleUpdateRequestSuccess}
        />
        {/* <div
          className="modal fade"
          id="exampleModaledit"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Edit New Request
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <div className="user-details">
                  <form>
                    <div className="form-floating mb-4 mt-2">
                      <select
                        className="form-select form-control"
                        id="floatingSelectGrid"
                        aria-label="Floating label select example"
                      >
                        <option selected>All</option>
                        <option value="1">Name</option>
                        <option value="2">Name</option>
                        <option value="3">Name</option>
                        <option value="3">Name</option>
                      </select>
                      <label for="floatingSelectGrid">
                        Select Customer Name
                      </label>
                    </div>
                    <div className="form-floating mb-3 mt-2">
                      <select
                        className="form-select form-control"
                        id="floatingSelectGrid"
                        aria-label="Floating label select example"
                      >
                        <option Value="All">All</option>
                        <option Value="Taxi Booking">Taxi Bookings</option>
                        <option value="Courier Delivery">
                          Courier Bookings
                        </option>
                      </select>
                      <label for="floatingSelectGrid">Service Type</label>
                    </div>
                    <div className="mb-3">
                      <label className="mb-2">Pickup Location</label>
                      <input type="text" className="form-control" />
                    </div>
                    <div className="mb-3">
                      <label className="mb-2">Dropoff Location</label>
                      <input type="text" className="form-control" />
                    </div>
                    <div className="mb-4">
                      <label className="mb-2">Total Amount</label>
                      <input type="number" className="form-control" />
                    </div>
                    <div className="form-floating mb-4 mt-2">
                      <select
                        className="form-select form-control"
                        id="floatingSelectGrid"
                        aria-label="Floating label select example"
                      >
                        <option value="Cash Payment">Cash</option>
                        <option value="Card">Card</option>
                      </select>
                      <label for="floatingSelectGrid">Payment Method</label>
                    </div>
                    <div className="form-floating mb-3 mt-2">
                      <select
                        className="form-select form-control"
                        id="floatingSelectGrid"
                        aria-label="Floating label select example"
                      >
                        <option selected>Complete</option>
                        <option value="1">Pending</option>
                      </select>
                      <label for="floatingSelectGrid">Status</label>
                    </div>
                    <div className="upload-reset-btn mb-0 justify-content-center pt-2">
                      <button className="btn btn-reset" data-bs-dismiss="modal">
                        Cancel
                      </button>
                      <button
                        className="btn btn-upload me-0"
                        data-bs-dismiss="modal"
                      >
                        Save
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        {/* <!--End Edit Modal --> */}

        {/* <!-- View Modal --> */}
        <ViewModal2 item={SingleBooking} />
        {/* <!--End View Modal --> */}

        {/* Delete Confirmation Modal */}
        <div
          className="modal fade"
          id="deleteConfirmationModal"
          tabIndex="-1"
          aria-labelledby="deleteConfirmationModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
            <div className="modal-content">
              <div className="modal-header bg-primary">
                <h5
                  className="modal-title text-white"
                  id="deleteConfirmationModalLabel"
                >
                  Confirm Deletion
                </h5>
                <button
                  type="button"
                  className="btn-close bg-white"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <h4 className="py-4 mx-auto">
                  Are you sure you want to delete this new request?
                </h4>
                <div className="d-flex justify-content-center pt-2">
                  <button
                    id="confirmDeleteButton"
                    className="btn btn-primary me-2"
                    data-bs-dismiss="modal"
                    onClick={handleDeleteRequest}
                  >
                    Yes
                  </button>
                  <button className="btn btn-secondary" data-bs-dismiss="modal">
                    No
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {<Footer />}
      </div>
    </div>
  );
};

export default NewRequest;
