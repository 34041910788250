import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Sidebar from "./Sidebar";
import Footer from "./Footer";
import Piechart from "./Piechart";
import {
  POSTAPI,
  ShowDriversApi,
  ShowNewRequestsApi,
} from "../helper/integration";
import { Api } from "../utils/Api";
import { capitalize } from "@mui/material";

const Statistics = () => {
  const [isLoad, setisLoad] = useState(false);
  const [isUpdate, setisUpdate] = useState(false);
  const [AllPendingRequests, setAllPendingRequests] = useState([]);
  const [AllCompleteRequests, setCompleteRequests] = useState([]);
  const [AllCancelRequests, setCancelRequests] = useState([]);
  const [AllInRouteRequests, setAllInRouteRequests] = useState([]);
  const [MainDriver, setMainDriver] = useState([]);

  useEffect(() => {
    (async function () {
      try {
        const data = await ShowNewRequestsApi();
        const pendingData = data.AllBook.filter(
          (request) => request.status === "Pending"
        );
        console.log(pendingData.length);

        const CompleteData = data.AllBook.filter(
          (request) => request.status === "Complete"
        );
        console.log(CompleteData.length);

        const CancelData = data.AllBook.filter(
          (request) => request.status === "Cancel"
        );
        console.log(CancelData.length);

        const InRouteData = data.AllBook.filter(
          (request) => request.status === "RideStart"
        );
        console.log(InRouteData.length);

        setAllPendingRequests(pendingData.length);
        setCompleteRequests(CompleteData.length);
        setCancelRequests(CancelData.length);
        setAllInRouteRequests(InRouteData.length);
        //setRequests(currentRecords);
      } catch (er) {
        console.log(er);
      } finally {
        setisLoad(true);
      }
    })();

    (async function () {
      try {
        const data = await ShowDriversApi();
        setMainDriver((prev) => (data?.length ? data.length : 0));
        let Req = 0;
        data.forEach((element) => {
          if (element?.availableStatus == "available") Req++;
        });
        setMainDriver(Req);
        console.log(data);
      } catch (er) {
        console.log(er);
      } finally {
        setisLoad(true);
      }
    })();
  }, [isUpdate]);

  return (
    <div>
      {<Sidebar />}
      <div className="asside">
        <div className="about-first">
          <div className="row">
            <div className="col-lg-3 col-md-4 col-sm-6 col-12 mb-24">
              {/* <Link to={"/NewUser"}> */}
              <div className="bg-box">
                <div className="welcome-section">
                  <div className="welcome-text">
                    <h5>{AllCancelRequests}</h5>
                    <p>Number of requests canceled</p>
                  </div>
                </div>
              </div>
              {/* </Link> */}
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6 col-12 mb-24">
              {/* <Link to={"/UserDetails"}> */}
              <div className="bg-box">
                <div className="welcome-section">
                  <div className="welcome-text">
                    <h5>{AllCompleteRequests} </h5>
                    <p>Number of completed requests</p>
                  </div>
                </div>
              </div>
              {/* </Link> */}
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6 col-12 mb-24">
              {/* <Link to={"/TotalNoOfEvents"}> */}
              <div className="bg-box">
                <div className="welcome-section">
                  <div className="welcome-text">
                    <h5>{MainDriver}</h5>
                    <p>Number of available drivers</p>
                  </div>
                </div>
              </div>
              {/* </Link> */}
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6 col-12 mb-24">
              {/* <Link to={"/NoOfMatchesDashboard"}> */}
              <div className="bg-box">
                <div className="welcome-section">
                  <div className="welcome-text">
                    <h5>{AllPendingRequests}</h5>
                    <p>Total new requests</p>
                  </div>
                </div>
              </div>
              {/* </Link> */}
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6 col-12 mb-24">
              {/* <Link to={"/NoOfGuestlistSignups"}> */}
              <div className="bg-box">
                <div className="welcome-section">
                  <div className="welcome-text">
                    <h5>{AllInRouteRequests}</h5>
                    <p>Total in-route requests</p>
                  </div>
                </div>
              </div>
              {/* </Link> */}
            </div>
          </div>
        </div>
        {<Footer />}
      </div>
    </div>
  );
};

export default Statistics;

// import React, { useEffect, useState } from "react";
// import Sidebar from "./Sidebar";
// import Footer from "./Footer";
// import { ShowNewRequestsApi } from "../helper/integration";

// const Statistics = () => {
//   const [isLoad, setisLoad] = useState(false);
//   const [isUpdate, setisUpdate] = useState(false);
//   const [AllPendingRequests, setAllPendingRequests] = useState([]);
//   const [AllCompleteRequests, setCompleteRequests] = useState([]);
//   const [AllCancelRequests, setCancelRequests] = useState([]);
//   const [AllInRouteRequests, setAllInRouteRequests] = useState([]);
//   const [MainDriver, setMainDriver] = useState([]);

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const data = await ShowNewRequestsApi();
//         const pendingData = data.AllBook.filter(
//           (request) => request.status === "Pending"
//         );
//         const CompleteData = data.AllBook.filter(
//           (request) => request.status === "Complete"
//         );
//         const CancelData = data.AllBook.filter(
//           (request) => request.status === "Cancel"
//         );
//         const InRouteData = data.AllBook.filter(
//           (request) => request.status === "RideStart"
//         );

//         setAllPendingRequests(pendingData.length);
//         setCompleteRequests(CompleteData.length);
//         setCancelRequests(CancelData.length);
//         setAllInRouteRequests(InRouteData.length);
//         setisLoad(true);
//       } catch (error) {
//         console.error("Error fetching data:", error);
//       }
//     };

//     const fetchMainDriverCount = async () => {
//       try {
//         const data = await ShowNewRequestsApi();
//         let availableDriversCount = 0;
//         data.AllBook.forEach((element) => {
//           if (element.DriverID?.availableStatus === "available") {
//             availableDriversCount++;
//           }
//         });
//         setMainDriver(availableDriversCount);
//         setisLoad(true);
//       } catch (error) {
//         console.error("Error fetching main driver count:", error);
//       }
//     };

//     // Initial fetch
//     fetchData();
//     fetchMainDriverCount();

//     // Interval to refresh every 1 second
//     const interval = setInterval(() => {
//       fetchData();
//       fetchMainDriverCount();
//     }, 1000);

//     // Cleanup function to clear interval
//     return () => clearInterval(interval);
//   }, [isUpdate]);

//   return (
//     <div>
//       <Sidebar />
//       <div className="asside">
//         <div className="about-first">
//           <div className="row">
//             <div className="col-lg-3 col-md-4 col-sm-6 col-12 mb-24">
//               <div className="bg-box">
//                 <div className="welcome-section">
//                   <div className="welcome-text">
//                     <h5>{AllCancelRequests}</h5>
//                     <p>Number of requests canceled</p>
//                   </div>
//                 </div>
//               </div>
//             </div>
//             <div className="col-lg-3 col-md-4 col-sm-6 col-12 mb-24">
//               <div className="bg-box">
//                 <div className="welcome-section">
//                   <div className="welcome-text">
//                     <h5>{AllCompleteRequests} </h5>
//                     <p>Number of completed requests</p>
//                   </div>
//                 </div>
//               </div>
//             </div>
//             <div className="col-lg-3 col-md-4 col-sm-6 col-12 mb-24">
//               <div className="bg-box">
//                 <div className="welcome-section">
//                   <div className="welcome-text">
//                     <h5>{MainDriver}</h5>
//                     <p>Number of available drivers</p>
//                   </div>
//                 </div>
//               </div>
//             </div>
//             <div className="col-lg-3 col-md-4 col-sm-6 col-12 mb-24">
//               <div className="bg-box">
//                 <div className="welcome-section">
//                   <div className="welcome-text">
//                     <h5>{AllPendingRequests}</h5>
//                     <p>Total new requests</p>
//                   </div>
//                 </div>
//               </div>
//             </div>
//             <div className="col-lg-3 col-md-4 col-sm-6 col-12 mb-24">
//               <div className="bg-box">
//                 <div className="welcome-section">
//                   <div className="welcome-text">
//                     <h5>{AllInRouteRequests}</h5>
//                     <p>Total in-route requests</p>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//         <Footer />
//       </div>
//     </div>
//   );
// };

// export default Statistics;
