import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Sidebar from "./Sidebar";
import Footer from "./Footer";
import user from "../Image/user.png";
import Pagination from "../common/Paggination";
import {
  POSTAPI,
  RemovePendingBookingApi,
  ShowNewRequestsApi,
} from "../helper/integration";
import { Api } from "../utils/Api";
import toast from "react-hot-toast";
import NotFound from "../Image/notfound.png";
import ViewModal from "../common/dispatcher/ViewModal";
import UpdateModel2 from "../common/dispatcher/UpdateModel2";
const CurrentRequest = () => {
  const [isUpdate, setisUpdate] = useState(false);
  const [isLoad, setisLoad] = useState(false);
  const [SingleBooking, setSingleBooking] = useState(null);
  const [currentPage, setcurrentPage] = useState(1);
  const [isDisble, setisDisble] = useState(false);
  const [recordsPerPage] = useState(10);
  const [nPage, setnPage] = useState([1]);
  const [AllRequests, setAllRequests] = useState([]);
  const [Requests, setRequests] = useState([]);
  const [type, setType] = useState("");
  const [payment_type, setPayment_type] = useState("");
  const [search, setSearch] = useState("");
  const [ReqIdToDelete, setReqIdToDelete] = useState(null);

  const handleShowSingleData = (item) => setSingleBooking((prev) => item);

  const handleDeleteRequest = () => {
    if (ReqIdToDelete) {
      let obj = {
        user_id: ReqIdToDelete,
      };
      toast
        .promise(RemovePendingBookingApi(obj), {
          loading: "Wait Few Moment !",
          success: "Current Request Delete Successfully !",
          error: "Something Wrong !",
        })
        .then(() => {
          setisUpdate(!isUpdate);
        })
        .catch(() => {});
    }
  };

  const handleFilter = async (e) => {
    const { value } = e.target;
    setType(value);
    setisUpdate((prev) => !prev);
    console.log(value);
  };

  const handleFilterWithPayment = (e) => {
    const { value } = e.target;
    setPayment_type(value);
    setisUpdate((prev) => !prev);
    console.log(value);
  };

  const handleChangePage = (id) => {
    setcurrentPage(id);
    const indexOfLastRecord = id * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    let currentRecords = AllRequests.slice(
      indexOfFirstRecord,
      indexOfLastRecord
    );
    setRequests(currentRecords);
  };

  function convertDateFormat(dateString) {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    let hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12; // Handle midnight (0 hours)
    const time = hours.toString().padStart(2, "0") + ":" + minutes + " " + ampm;
    return `${day}-${month}-${year} ${time}`;
  }

  useEffect(() => {
    (async function () {
      try {
        const data = await ShowNewRequestsApi();
        let pendingData = data.AllBook.filter(
          (request) => request.status === "Complete"
        );

        pendingData.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        );

        if (type && type !== "All") {
          pendingData = pendingData.filter((request) => request.type === type);
        } else if (type === "Taxi Booking") {
          pendingData = pendingData.filter(
            (request) => request.type === "Taxi Booking"
          );
        } else if (type === "Courier Delivery") {
          pendingData = pendingData.filter(
            (request) => request.type === "Courier Delivery"
          );
        }

        if (payment_type && payment_type !== "All") {
          pendingData = pendingData.filter(
            (request) => request.payment_type === payment_type
          );
        } else if (payment_type === "Cash Payment") {
          pendingData = pendingData.filter(
            (request) => request.payment_type === "Cash Payment"
          );
        } else if (payment_type === "Card") {
          pendingData = pendingData.filter(
            (request) => request.payment_type === "Card"
          );
        }

        console.log(pendingData);
        setAllRequests(pendingData);
        let indexOfLastRecord = currentPage * recordsPerPage;
        let indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
        let currentRecords = pendingData.slice(
          indexOfFirstRecord,
          indexOfLastRecord
        );
        setRequests(currentRecords);
        let nPages = Math.ceil(pendingData?.length / recordsPerPage);
        let num = [...Array(nPages + 1).keys()].slice(1);
        setnPage(num);
      } catch (er) {
        console.log(er);
      } finally {
        setisLoad(true);
      }
    })();
  }, [isUpdate, type, payment_type]);

  useEffect(() => {
    // Reset to the first page when the search term changes
    setcurrentPage(1);
  }, [search]);

  useEffect(() => {
    // Update the filtered results when the search term changes
    const filteredData = AllRequests.filter((item) =>
      search.toLowerCase() === ""
        ? true
        : item.drop_locations.toLowerCase().includes(search.toLowerCase()) ||
          item?.UserID?.username.toLowerCase().includes(search.toLowerCase()) ||
          item?.DriverID?.username
            .toLowerCase()
            .includes(search.toLowerCase()) ||
          item.pickup_locations.toLowerCase().includes(search.toLowerCase())
    );
    const indexOfLastRecord = 1 * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const currentRecords = filteredData.slice(
      indexOfFirstRecord,
      indexOfLastRecord
    );
    setRequests(currentRecords);
    const nPages = Math.ceil(filteredData.length / recordsPerPage);
    const num = [...Array(nPages + 1).keys()].slice(1);
    setnPage(num);
  }, [search, AllRequests]);

  const handleUpdateRequestSuccess = (updatedData) => {
    setAllRequests((prevRequests) =>
      prevRequests.map((request) =>
        request._id === updatedData._id ? updatedData : request
      )
    );
    setisUpdate((prev) => !prev);
  };
  return (
    <div>
      {<Sidebar />}
      <div className="asside">
        <div className="about-first">
          <div className="row">
            <div className="col-12 mb-24">
              <div className="bg-box">
                <div class="tab-content" id="pills-tabContent">
                  <div>
                    <div className="pro-add-new px-0">
                      <p>CURRENT REQUEST</p>
                      <div className="form-floating small-floating">
                        <input
                          type="text"
                          className="form-control py-2 w-auto"
                          id="floatingInput"
                          placeholder="name@example.com"
                          onChange={(e) => setSearch(e.target.value)}
                        />
                        <label for="floatingInput">Search</label>
                      </div>
                    </div>
                    <div className="d-flex align-items-end justify-content-between">
                      <div className="d-flex">
                        <div className="mb-3 me-3">
                          <label className="mb-2">Service Type</label>
                          <select
                            className="form-select form-control"
                            id=""
                            aria-label="Floating label select example"
                            onChange={handleFilter}
                          >
                            <option Value="All">All</option>
                            <option value="Taxi Booking">Taxi Bookings</option>
                            <option value="Courier Delivery">
                              Courier Bookings
                            </option>
                          </select>
                        </div>
                        <div className="mb-3">
                          <label className="mb-2">Payment Method Filter</label>
                          <select
                            className="form-select form-control"
                            id=""
                            aria-label="Floating label select example"
                            onChange={handleFilterWithPayment}
                          >
                            <option Value="All">All</option>
                            <option value="Cash Payment">Cash</option>
                            <option value="Card">Card</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="responsive-table">
                      <table className="table">
                        <thead>
                          <tr>
                            <th>No</th>
                            <th>Trip ID</th>
                            <th>Customer Name</th>
                            <th>Driver Name</th>
                            <th>Pickup Location</th>
                            <th>Dropoff Location</th>
                            <th>Total Distance</th>
                            <th>Promo Code (If applied)</th>
                            <th>Payment Method</th>
                            {/* <th>Vehicle Type</th> */}
                            <th>Service Type</th>
                            <th>Total Amount</th>
                            <th>Created At</th>
                            <th>Status</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {!isLoad ? (
                            <tr>
                              <td colSpan={9}>
                                <div className="d-flex justify-content-center align-items-center">
                                  <div className="loader"></div>
                                </div>
                              </td>
                            </tr>
                          ) : !Requests.length ? (
                            <tr>
                              <td colSpan={9}>
                                <div
                                  className="d-flex flex-column  justify-content-center align-items-center mt-2  "
                                  style={{ height: "15vh" }}
                                >
                                  <img
                                    src={NotFound}
                                    style={{ height: "100px" }}
                                    alt=""
                                  />
                                  <div> New dispatch Request not found !</div>
                                </div>
                              </td>
                            </tr>
                          ) : Requests?.length ? (
                            Requests.filter((item) => {
                              return search.toLowerCase() === ""
                                ? true
                                : item.drop_locations
                                    .toLowerCase()
                                    .includes(search.toLowerCase()) ||
                                    item?.UserID?.username
                                      .toLowerCase()
                                      .includes(search.toLowerCase()) ||
                                    item?.DriverID?.username
                                      .toLowerCase()
                                      .includes(search.toLowerCase()) ||
                                    item.pickup_locations
                                      .toLowerCase()
                                      .includes(search.toLowerCase());
                            }).map((item, index) => (
                              <tr>
                                <td>
                                  {currentPage == 1
                                    ? index + 1
                                    : index + 1 + currentPage * 10 - 10}
                                </td>
                                <td>{item?.RideId}</td>
                                <td>{item?.UserID?.username}</td>
                                <td>{item?.DriverID?.username || "-"}</td>
                                <td>{item.pickup_locations}</td>
                                <td>{item.drop_locations}</td>
                                <td>{item.distance || "-"}</td>
                                <td>{item?.Discount || "-"}</td>
                                <td>{item.payment_type}</td>
                                {/* <td>{item.vehical}</td> */}
                                <td>
                                  <div>
                                    {item?.type == "Taxi Booking" && (
                                      <Link
                                        to={"#"}
                                        class="badge bg-info me-1 text-white"
                                      >
                                        Taxi Bookings
                                      </Link>
                                    )}
                                    {item?.type == "Courier Delivery" && (
                                      <Link
                                        to={"#"}
                                        class="badge bg-dark me-1 text-white"
                                      >
                                        Courier Bookings
                                      </Link>
                                    )}
                                  </div>
                                </td>
                                <td>{item.TotalAmount}</td>
                                <td>
                                  {item?.createdAt
                                    ? convertDateFormat(item?.createdAt)
                                    : "-"}
                                </td>
                                <td>
                                  <div>
                                    {item?.status == "Pending" && (
                                      <Link
                                        to={"#"}
                                        class="badge bg-warning me-1 text-white"
                                      >
                                        Pending
                                      </Link>
                                    )}
                                    {item?.status == "Complete" && (
                                      <Link
                                        to={"#"}
                                        class="badge bg-success me-1 text-white"
                                      >
                                        Complete
                                      </Link>
                                    )}

                                    {/* <Link
                                    to={"#"}
                                    class="badge bg-warning me-1 text-white"
                                  >
                                    Pending
                                  </Link> */}
                                  </div>
                                </td>

                                <td>
                                  <div className="icon-up-del">
                                    <Link
                                      type="button"
                                      data-bs-toggle="modal"
                                      data-bs-target="#exampleModaledit"
                                      onClick={() => handleShowSingleData(item)}
                                    >
                                      <i className="fa-solid fa-pen"></i>
                                    </Link>
                                    <Link>
                                      <i
                                        // onClick={() =>
                                        //   handleDeleteRequest(item?._id)
                                        // }
                                        // className="fa-solid fa-trash"
                                        className="fa-solid fa-trash"
                                        style={{ cursor: "pointer" }}
                                        onClick={() => {
                                          setReqIdToDelete(item._id);
                                        }}
                                        data-bs-toggle="modal"
                                        data-bs-target="#deleteConfirmationModal"
                                      ></i>
                                    </Link>
                                    <Link
                                      type="button"
                                      data-bs-toggle="modal"
                                      data-bs-target="#viewexampleModaledit"
                                      onClick={() => handleShowSingleData(item)}
                                    >
                                      <i className="fa-solid fa-eye"></i>
                                    </Link>
                                  </div>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan={9}>
                                <div
                                  className="d-flex flex-column  justify-content-center align-items-center mt-2  "
                                  style={{ height: "15vh" }}
                                >
                                  <img
                                    src={NotFound}
                                    style={{ height: "100px" }}
                                    alt=""
                                  />
                                  <div> New dispatch Request not found !</div>
                                </div>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                    <Pagination
                      currentPage={currentPage}
                      nPage={nPage}
                      handleChangePage={handleChangePage}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <!-- Edit Modal --> */}
        <UpdateModel2
          item={SingleBooking}
          setItem={setSingleBooking}
          isUpdate={isUpdate}
          setisUpdate={setisUpdate}
          onUpdateSuccess={handleUpdateRequestSuccess}
          // item={SingleBooking}
          // setItem={handleShowSingleData}
          // onUpdateSuccess={handleUpdateRequestSuccess}
        />

        {/* <!-- View Taxi Modal --> */}
        {/* <div
          className="modal fade"
          id="viewexampleModaledit"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  View Current Request
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <div className="user-details">
                  <form className="row">
                    <div className="mb-3 col-md-6 col-12">
                      <label className="mb-2">Trip ID</label>
                      <input
                        type="number"
                        className="form-control"
                        placeholder="001"
                        disabled
                      />
                    </div>
                    <div className="mb-3 col-md-6 col-12">
                      <label className="mb-2">Customer Name</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Customer Name"
                        disabled
                      />
                    </div>
                    <div className="mb-3 col-md-6 col-12">
                      <label className="mb-2">Driver Name</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Driver Name"
                        disabled
                      />
                    </div>
                    <div className="mb-3 col-md-6 col-12">
                      <label className="mb-2">Pickup Location</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Pickup Location"
                        disabled
                      />
                    </div>
                    <div className="mb-3 col-md-6 col-12">
                      <label className="mb-2">Dropoff Location</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Dropoff Location"
                        disabled
                      />
                    </div>
                    <div className="mb-3 col-md-6 col-12">
                      <label className="mb-2">Total Distance</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Total Distance"
                        disabled
                      />
                    </div>
                    <div className="mb-3 col-md-6 col-12">
                      <label className="mb-2">Promo Code</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="GET50"
                        disabled
                      />
                    </div>
                    <div className="mb-3 col-md-6 col-12">
                      <label className="mb-2">Payment Method</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Cash"
                        disabled
                      />
                    </div>
                    <div className="mb-3 col-md-6 col-12">
                      <label className="mb-2">Total Amount</label>
                      <input
                        type="number"
                        className="form-control"
                        placeholder="10"
                        disabled
                      />
                    </div>
                    <div className="mb-3 col-md-6 col-12">
                      <label className="mb-2">Created At</label>
                      <input
                        type="date-time"
                        className="form-control"
                        placeholder="10-01-2024 11:45 AM"
                        disabled
                      />
                    </div>
                    <div className="mb-3 col-md-6 col-12">
                      <label className="mb-2">Status</label>
                      <div>
                        <p class="badge bg-success me-1 text-white mb-0">
                          Approved
                        </p>
                        <p class="badge bg-warning me-1 text-white mb-0">
                          Pending
                        </p>
                      </div>
                    </div>
                    <div className="upload-reset-btn mb-0 justify-content-center pt-2">
                      <button className="btn btn-reset" data-bs-dismiss="modal">
                        Cancel
                      </button>
                      <button
                        className="btn btn-upload me-0"
                        data-bs-dismiss="modal"
                      >
                        Save
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        <ViewModal item={SingleBooking} />
        {/* <!--End View Modal --> */}

        {/* <!-- View Courier Modal --> */}
        {/* <div
          className="modal fade"
          id="viewexampleModalCourier"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  View Courier Bookings
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <div className="user-details">
                  <form className="row">
                    <div className="mb-3 col-md-6 col-12">
                      <label className="mb-2">Trip ID</label>
                      <input
                        type="number"
                        className="form-control"
                        placeholder="001"
                        disabled
                      />
                    </div>
                    <div className="mb-3 col-md-6 col-12">
                      <label className="mb-2">Customer Name</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Customer Name"
                        disabled
                      />
                    </div>
                    <div className="mb-3 col-md-6 col-12">
                      <label className="mb-2">Driver Name</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Driver Name"
                        disabled
                      />
                    </div>
                    <div className="mb-3 col-md-6 col-12">
                      <label className="mb-2">Pickup Location</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Pickup Location"
                        disabled
                      />
                    </div>
                    <div className="mb-3 col-md-6 col-12">
                      <label className="mb-2">Dropoff Location</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Dropoff Location"
                        disabled
                      />
                    </div>
                    <div className="mb-3 col-md-6 col-12">
                      <label className="mb-2">Total Distance</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Total Distance"
                        disabled
                      />
                    </div>
                    <div className="mb-3 col-md-6 col-12">
                      <label className="mb-2">Promo Code</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="GET50"
                        disabled
                      />
                    </div>
                    <div className="mb-3 col-md-6 col-12">
                      <label className="mb-2">Payment Method</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Cash"
                        disabled
                      />
                    </div>
                    <div className="mb-3 col-md-6 col-12">
                      <label className="mb-2">Total Amount</label>
                      <input
                        type="number"
                        className="form-control"
                        placeholder="10"
                        disabled
                      />
                    </div>
                    <div className="mb-3 col-md-6 col-12">
                      <label className="mb-2">Created At</label>
                      <input
                        type="date-time"
                        className="form-control"
                        placeholder="10-01-2024 11:45 AM"
                        disabled
                      />
                    </div>
                    <div className="mb-3 col-md-6 col-12">
                      <label className="mb-2">Status</label>
                      <div>
                        <p class="badge bg-success me-1 text-white mb-0">
                          Approved
                        </p>
                        <p class="badge bg-warning me-1 text-white mb-0">
                          Pending
                        </p>
                      </div>
                    </div>
                    <div className="upload-reset-btn mb-0 justify-content-center pt-2">
                      <button className="btn btn-reset" data-bs-dismiss="modal">
                        Cancel
                      </button>
                      <button
                        className="btn btn-upload me-0"
                        data-bs-dismiss="modal"
                      >
                        Save
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        {/* <!--End Courier Modal --> */}

        {/* Delete Confirmation Modal */}
        <div
          className="modal fade"
          id="deleteConfirmationModal"
          tabIndex="-1"
          aria-labelledby="deleteConfirmationModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
            <div className="modal-content">
              <div className="modal-header bg-primary">
                <h5
                  className="modal-title text-white"
                  id="deleteConfirmationModalLabel"
                >
                  Confirm Deletion
                </h5>
                <button
                  type="button"
                  className="btn-close bg-white"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <h4 className="py-4 mx-auto">
                  Are you sure you want to delete this current request?
                </h4>
                <div className="d-flex justify-content-center pt-2">
                  <button
                    id="confirmDeleteButton"
                    className="btn btn-primary me-2"
                    data-bs-dismiss="modal"
                    onClick={handleDeleteRequest}
                  >
                    Yes
                  </button>
                  <button className="btn btn-secondary" data-bs-dismiss="modal">
                    No
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {<Footer />}
      </div>
    </div>
  );
};

export default CurrentRequest;
