import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import Select from "react-select";
import Paggination from "../Paggination";
import {
  AddNotificationApi,
  RemoveNotificationApi,
  GetUsersApi,
} from "../../helper/integration";
import NotFound from "../../Image/notfound.png";
import bootstrap from "bootstrap/dist/js/bootstrap.bundle.min";

const options = [
  { value: "Select All", label: "Select All" },
  { value: "Amit", label: "Amit" },
  { value: "Ankit", label: "Ankit" },
  { value: "Chetan", label: "Chetan" },
  { value: "Rahul", label: "Rahul" },
];

const AddNotification = ({ type, handleReferesh }) => {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [users, setUsers] = useState([]);
  const [isLoad, setisLoad] = useState(false);
  const [isUpdate, setisUpdate] = useState(false);
  const { register, reset, handleSubmit } = useForm();

  useEffect(() => {
    (async function () {
      try {
        const data = await GetUsersApi();
        console.log(data);
        const userOptions = data.map((user) => ({
          value: user._id, // Assuming the driver ID is in the 'id' field
          label: `${user.username}`, // Display name and ID
        }));
        userOptions.unshift({ value: "Select All", label: "Select All" });

        setAllUsers(userOptions.slice(1)); // Exclude "Select All" for the full list
        setUsers(userOptions);
      } catch (er) {
      } finally {
        setisLoad(true);
      }
    })();
  }, [isUpdate]);

  const handleAddNotification = async (data) => {
    try {
      const selectedUserIDs = selectedOptions.map((option) => option.value);

      // Check if "Select All" is selected
      const shouldSendToAll = selectedUserIDs.includes("Select All");
      const userIDsToSend = shouldSendToAll
        ? allUsers.map((user) => user.value)
        : selectedUserIDs;

      const obj = { ...data, type, UserID: userIDsToSend };
      await toast.promise(AddNotificationApi(obj), {
        loading: "Wait Few Moment !",
        success: "Notification Add Successfully !",
        error: "Something Wrong !",
      });
      reset();
      handleReferesh();
    } catch (er) {
      console.log(er);
    }
  };

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      border: "2px solid #888", // Dark border
      boxShadow: "none",
      "&:hover": {
        border: state.isFocused ? "2px solid blue" : "2px solid #888",
      },
    }),
  };

  const handleChange = (selectedOption) => {
    if (selectedOption.some((option) => option.value === "Select All")) {
      // Select all drivers if "Select All" is selected
      setSelectedOptions([{ value: "Select All", label: "Selected All" }]);
    } else {
      setSelectedOptions(selectedOption);
    }
  };

  const selectedAllLabel = selectedOptions.some(
    (option) => option.value === "Select All"
  )
    ? "Selected All"
    : "Select Users";

  return (
    <div>
      <form className="mb-4" onSubmit={handleSubmit(handleAddNotification)}>
        <div className="mb-3">
          <label className="mb-2">Title</label>
          <input
            type="text"
            className="form-control"
            {...register("title", { required: true })}
          />
        </div>
        <div className="mb-3">
          <label className="mb-2">Description</label>
          <textarea
            className="form-control"
            {...register("detail", { required: true })}
            rows="3"
          ></textarea>
        </div>
        <div>
          <label className="mb-2">Select Users</label>
          <Select
            options={users}
            value={selectedOptions}
            onChange={handleChange}
            isMulti={true}
            styles={customStyles}
            placeholder={selectedAllLabel}
          />
        </div>
        <div className="upload-reset-btn mb-0 justify-content-center pt-2">
          <button
            className="btn btn-reset"
            data-bs-dismiss="modal"
            type="cancel"
          >
            Cancel
          </button>
          <button
            className="btn btn-upload me-0"
            type="submit"
            data-bs-dismiss="modal"
          >
            Send Notification
          </button>
        </div>
      </form>
    </div>
  );
};

const User = ({ UserData, isLoad, handleReferesh }) => {
  const [AllUsers, setAllUsers] = useState([]);
  const [UsersNo, setUsersNo] = useState([]);
  const [userMap, setUserMap] = useState({});
  const [currentPage, setcurrentPage] = useState(1);
  const [recordsPerPage] = useState(10);
  const [nPage, setnPage] = useState([1]);
  const [itemIdToDelete, setItemIdToDelete] = useState(null);

  const handleChangePage = (id) => {
    setcurrentPage(id);
    const indexOfLastRecord = id * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    let currentRecords = AllUsers.slice(indexOfFirstRecord, indexOfLastRecord);
    setUsersNo(currentRecords);
  };

  const handleDeleteNotification = (id) => {
    setItemIdToDelete(id); // Set the item ID to delete
    //setDeleteModalVisible(true);
    const deleteModal = new bootstrap.Modal(
      document.getElementById("deleteConfirmationModal2")
    );
    deleteModal.show();
  };

  const confirmDelete1 = async () => {
    if (itemIdToDelete) {
      try {
        await toast.promise(RemoveNotificationApi({ id: itemIdToDelete }), {
          loading: "Wait Few Moment !",
          success: "Notification Delete Successfully !",
          error: "Something Wrong !",
        });
        handleReferesh();
      } catch (er) {
        console.log(er);
      }
    }
  };
  const handleSearch = (e) => {
    let val = e.target.value;
    let newArray = AllUsers.filter(
      (item) =>
        item.title.toLowerCase().includes(val.toLowerCase()) ||
        item.detail.toLowerCase().includes(val.toLowerCase())
    );

    setcurrentPage(1);
    const indexOfLastRecord = 1 * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const currentRecords = newArray.slice(
      indexOfFirstRecord,
      indexOfLastRecord
    );
    setUsersNo(currentRecords);
    const nPages = Math.ceil(newArray?.length / recordsPerPage);
    const num = [...Array(nPages + 1).keys()].slice(1);
    setnPage(num);
  };
  useEffect(() => {
    (async function () {
      const users = await GetUsersApi();
      const usersMap = users.reduce((acc, user) => {
        acc[user._id] = user.username;
        return acc;
      }, {});
      console.log(usersMap);
      setUserMap(usersMap);

      setAllUsers((prev) => UserData);
      let indexOfLastRecord = currentPage * recordsPerPage;
      let indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
      let currentRecords = UserData?.slice(
        indexOfFirstRecord,
        indexOfLastRecord
      );
      setUsersNo((prev) => currentRecords);
      let nPages = Math.ceil(UserData?.length / recordsPerPage);
      let num = [...Array(nPages + 1).keys()].slice(1);
      setnPage(num);
    })();
  }, [UserData]);
  return (
    <div
      class="tab-pane fade"
      id="pills-profile"
      role="tabpanel"
      aria-labelledby="pills-profile-tab"
    >
      <div className="pro-add-new px-0">
        <p>ADD USER NOTIFICATION</p>
        <div className="form-floating small-floating">
          <input
            type="text"
            className="form-control py-2 w-auto"
            id="floatingInput"
            placeholder="name@example.com"
            onChange={handleSearch}
          />
          <label for="floatingInput">Search</label>
        </div>
      </div>

      <AddNotification type={"User"} handleReferesh={handleReferesh} />

      <div className="responsive-table">
        <table className="table">
          <thead>
            <tr>
              <th>ID</th>
              <th>Title</th>
              <th>Description</th>
              <th>Users</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {!isLoad ? (
              <tr>
                <td colSpan={4}>
                  <div
                    className="d-flex justify-content-center align-items-center  "
                    style={{ height: "10vh" }}
                  >
                    <div class="loader"></div>
                  </div>
                </td>
              </tr>
            ) : UsersNo?.length ? (
              UsersNo?.map((item, index) => {
                // Ensure DriverID is an array
                const UserIDs = Array.isArray(item?.UserID)
                  ? item.UserID
                  : [item?.UserID];

                const userNames = UserIDs.map((id) => userMap[id])
                  .filter((name) => name !== undefined)
                  .join(", ");

                return (
                  <tr>
                    <td>
                      {" "}
                      {currentPage == 1
                        ? index + 1
                        : index + 1 + currentPage * 10 - 10}
                    </td>
                    <td>{item?.title}</td>
                    <td>{item?.detail}</td>
                    <td>{userNames || "Not Found"}</td>
                    <td>
                      <div className="icon-up-del">
                        <Link
                          // onClick={() =>
                          //   handleRemoveNotification({ id: item?._id })
                          // }
                          onClick={() => handleDeleteNotification(item?._id)}
                        >
                          <i className="fa-solid fa-trash"></i>
                        </Link>
                      </div>
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan={4}>
                  <div
                    className="d-flex flex-column  mt-2 justify-content-center align-items-center  "
                    style={{ height: "15vh" }}
                  >
                    <img src={NotFound} style={{ height: "100px" }} alt="" />
                    <div>User Notification not found !</div>
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      {/* Delete Confirmation Modal */}
      <div
        className="modal fade"
        id="deleteConfirmationModal2"
        tabIndex="-1"
        aria-labelledby="deleteConfirmationModalLabel"
        aria-hidden="true"
        //style={{ visibility: "visible" }} // For debugging
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header bg-primary">
              <h5
                className="modal-title text-white"
                id="deleteConfirmationModalLabel"
              >
                Confirm Deletion
              </h5>
              <button
                type="button"
                className="btn-close bg-white"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <h4 className="py-4 mx-auto">
                Are you sure you want to delete this notification?
              </h4>
              <div className="d-flex justify-content-center pt-2">
                <button
                  className="btn btn-primary me-2"
                  data-bs-dismiss="modal"
                  onClick={confirmDelete1}
                >
                  Yes
                </button>
                <button className="btn btn-secondary" data-bs-dismiss="modal">
                  No
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Paggination
        nPage={nPage}
        currentPage={currentPage}
        handleChangePage={handleChangePage}
      />
    </div>
  );
};

export default User;
