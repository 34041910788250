import React, { useState } from "react";
import toast from "react-hot-toast";
import { POSTAPI } from "../../helper/integration";
import { Api } from "../../utils/Api";

const AddModal = ({ handleUpdte }) => {
  const [discountType, setDiscountType] = useState("");
  const [FormData, setFormData] = useState(null);
  const handleChange = (e) => {
    setDiscountType(e.target.value);
  };

  const handleAddPromoCode = () => {
    let data = { ...FormData, discount_type: discountType };

    toast
      .promise(POSTAPI(Api.AddPromoCode, data), {
        loading: "Wait Few Moment !",
        success: "PromoCode Add Successfully !",
        error: "Something Wrong !",
      })
      .then(() => {
        handleUpdte();
      })
      .catch(() => {});
    document.getElementById("closeAddPromo").click();
    setFormData(null);
    setDiscountType(null);
  };

  return (
    <div
      className="modal fade"
      id="exampleModalAdd"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              Add Promo Code
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <div className="user-details">
              <div className="row">
                <div className="mb-3 col-md-6 col-12">
                  <label className="mb-2">Promo Code Name</label>
                  <input
                    type="text"
                    className="form-control"
                    value={FormData?.name}
                    onChange={(e) =>
                      setFormData((prev) => ({ ...prev, name: e.target.value }))
                    }
                  />
                </div>
                <div className="mb-3 col-md-6 col-12">
                  <label className="mb-2">Promo Code</label>
                  <input
                    type="text"
                    className="form-control"
                    value={FormData?.Code}
                    onChange={(e) =>
                      setFormData((prev) => ({ ...prev, Code: e.target.value }))
                    }
                  />
                </div>
                <div className="mb-3 col-md-6 col-12">
                  <label className="mb-2">Discount Type</label>
                  <select
                    className="form-select form-control"
                    value={discountType}
                    onChange={handleChange}
                    aria-label="Floating label select example"
                  >
                    <option value="">Select Discount Type</option>
                    <option value="percentage">Percentage</option>
                    <option value="flat">Flat discount</option>
                  </select>
                </div>

                {discountType === "percentage" && (
                  <>
                    <div className="mb-3 col-md-6 col-12">
                      <label className="mb-2">No of Percentage</label>
                      <input
                        type="number"
                        className="form-control"
                        value={FormData?.Discount}
                        onChange={(e) =>
                          setFormData((prev) => ({
                            ...prev,
                            Discount: e.target.value,
                          }))
                        }
                      />
                    </div>
                    <div className="mb-3 col-md-6 col-12">
                      <label className="mb-2">
                        Minimum amount (For Booking)
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        value={FormData?.minimum}
                        onChange={(e) =>
                          setFormData((prev) => ({
                            ...prev,
                            minimum: e.target.value,
                          }))
                        }
                      />
                    </div>
                    <div className="mb-3 col-md-6 col-12">
                      <label className="mb-2">Maximum Discount</label>
                      <input
                        type="number"
                        className="form-control"
                        value={FormData?.maximum}
                        onChange={(e) =>
                          setFormData((prev) => ({
                            ...prev,
                            maximum: e.target.value,
                          }))
                        }
                      />
                    </div>
                  </>
                )}

                {discountType === "flat" && (
                  <>
                    <div className="mb-3 col-md-6 col-12">
                      <label className="mb-2">Flat Discount</label>
                      <input
                        type="number"
                        className="form-control"
                        value={FormData?.Discount}
                        onChange={(e) =>
                          setFormData((prev) => ({
                            ...prev,
                            Discount: e.target.value,
                          }))
                        }
                      />
                    </div>
                    <div className="mb-3 col-md-6 col-12">
                      <label className="mb-2">Required order amount</label>
                      <input
                        type="number"
                        className="form-control"
                        value={FormData?.minimum}
                        onChange={(e) =>
                          setFormData((prev) => ({
                            ...prev,
                            minimum: e.target.value,
                          }))
                        }
                      />
                    </div>
                  </>
                )}

                <div className="mb-3 col-md-6 col-12">
                  <label className="mb-2">Description</label>
                  <input
                    type="text"
                    className="form-control"
                    value={FormData?.discription}
                    onChange={(e) =>
                      setFormData((prev) => ({
                        ...prev,
                        discription: e.target.value,
                      }))
                    }
                  />
                </div>
                <div className="mb-3 col-md-6 col-12">
                  <label className="mb-2">Start Date</label>
                  <input
                    type="date"
                    className="form-control"
                    value={FormData?.start_date}
                    onChange={(e) =>
                      setFormData((prev) => ({
                        ...prev,
                        start_date: e.target.value,
                      }))
                    }
                  />
                </div>
                <div className="mb-3 col-md-6 col-12">
                  <label className="mb-2">Expiry Date</label>
                  <input
                    type="date"
                    className="form-control"
                    value={FormData?.end_date}
                    onChange={(e) =>
                      setFormData((prev) => ({
                        ...prev,
                        end_date: e.target.value,
                      }))
                    }
                  />
                </div>
                <div className="upload-reset-btn mb-0 justify-content-center pt-2">
                  <button
                    className="btn btn-reset"
                    data-bs-dismiss="modal"
                    id="closeAddPromo"
                  >
                    Cancel
                  </button>
                  <button
                    className="btn btn-upload me-0"
                    data-bs-dismiss="modal"
                    onClick={handleAddPromoCode}
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddModal;
