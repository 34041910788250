import React, { useEffect, useState } from "react";
import Sidebar from "./Sidebar";
import Footer from "./Footer";
import Driver from "../common/Notification/Driver";
import User from "../common/Notification/User";
import { ShowNotificationApi } from "../helper/integration";

const Notification = () => {
  const [Drivers, setDrivers] = useState([]);
  const [isLoad, setisLoad] = useState(false);
  const [isUpdate, setisUpdate] = useState(false);
  const [Users, setUsers] = useState([]);

  const handleReferesh = () => setisUpdate(!isUpdate);
  useEffect(() => {
    (async function () {
      try {
        const data = await ShowNotificationApi();
        console.log(data);

        setDrivers((prev) => data?.Driver);
        setUsers((prev) => data?.User);
      } catch (er) {
        console.log(er);
      } finally {
        setTimeout(() => {
          setisLoad(true);
        }, 0);
      }
    })();
  }, [isUpdate]);
  return (
    <div>
      {<Sidebar />}
      <div className="asside">
        <div className="about-first">
          <div className="row">
            <div className="col-12 mb-24">
              <div className="bg-box">
                <nav className="mb-3">
                  <div class="nav nav-tabs" id="nav-tab" role="tablist">
                    <button
                      class="nav-link active"
                      id="pills-home-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-home"
                      type="button"
                      role="tab"
                      aria-controls="pills-home"
                      aria-selected="true"
                    >
                      {" "}
                      Driver{" "}
                    </button>
                    <button
                      class="nav-link"
                      id="pills-profile-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-profile"
                      type="button"
                      role="tab"
                      aria-controls="pills-profile"
                      aria-selected="false"
                    >
                      {" "}
                      User{" "}
                    </button>
                  </div>
                </nav>
                <div class="tab-content" id="pills-tabContent">
                  <Driver
                    DriverData={Drivers}
                    isLoad={isLoad}
                    handleReferesh={handleReferesh}
                  />
                  <User
                    UserData={Users}
                    isLoad={isLoad}
                    handleReferesh={handleReferesh}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        {<Footer />}
      </div>
    </div>
  );
};

export default Notification;
