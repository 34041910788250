import React, { useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { UpdateBannerApi } from "../../helper/integration";

const UpdateModel = ({ item, setItem, isUpdate, setisUpdate }) => {
  const [Images, setImages] = useState(null);
  const { register, reset, handleSubmit } = useForm();
  const handleUpdateBanner = async (data) => {
    try {
      if (item?._id) {
        const newData = new FormData();
        newData.append("user_id", item?._id);
        newData.append("title", data?.title || item?.title);
        // newData.append(
        //   "types",
        //   data?.types !== item?.types ? data?.types : item?.types
        // );
        newData.append("status", data?.status || item?.status);
        if (Images) newData.append("image", Images);

        await toast.promise(UpdateBannerApi(newData), {
          loading: "Wait Few Moment !",
          success: "Banner Update Successfully !",
          error: "Something Wrong !",
        });
      }
    } catch (er) {
    } finally {
      reset();
      setItem(null);
      document.getElementById("closeUpdateBanner").click();
      setImages(null);
      setisUpdate(!isUpdate);
    }
  };

  return (
    <div
      className="modal fade"
      id="exampleModaledit"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              Edit Banner
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <div className="user-details">
              <form className="row" onSubmit={handleSubmit(handleUpdateBanner)}>
                {/* <div className="mb-3 col-12">
                  <label className="mb-2">Select Type</label>
                  <select
                    className="form-select form-control"
                    id="floatingSelectGrid"
                    aria-label="Floating label select example"
                    defaultValue={item?.types}
                    {...register("types", { required: true })}
                  >
                    <option value="User">User</option>
                    <option value="Driver">Driver</option>
                  </select>
                </div> */}
                <div className="mb-3 col-12">
                  <label className="mb-2">Name Of Promotion</label>
                  <input
                    type="text"
                    className="form-control"
                    defaultValue={item?.title}
                    {...register("title")}
                  />
                </div>
                <div className="mb-3 col-12">
                  <label className="mb-2">Banner Image</label>
                  <input
                    type="file"
                    className="form-control"
                    multiple
                    onChange={(e) => setImages(e.target.files[0])}
                  />
                </div>
                <div className="mb-3 col-12">
                  <label className="mb-2">Select Status</label>
                  <select
                    className="form-select form-control"
                    id="floatingSelectGrid"
                    aria-label="Floating label select example"
                    {...register("status")}
                  >
                    <option value="Active" selected={item?.status == "Active"}>
                      Active
                    </option>
                    <option
                      value="Deactive"
                      selected={item?.status == "Deactive"}
                    >
                      Inactive
                    </option>
                    {/* <option value="Active">Active</option>
                    <option value="Deactive">Inactive</option> */}
                  </select>
                </div>
                <div className="upload-reset-btn mb-0 justify-content-center pt-2">
                  <button
                    className="btn btn-reset"
                    id="closeUpdateBanner"
                    data-bs-dismiss="modal"
                    type="button"
                  >
                    Cancel
                  </button>
                  <button className="btn btn-upload me-0" type="submit">
                    Save
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateModel;
