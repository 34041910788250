import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { POSTAPI } from "../../helper/integration";
import { Api } from "../../utils/Api";
import ActiveList from "./ActiveList";
import Pagination from "../Paggination";
import NotFound from "../../Image/notfound.png";

const ActivePromo = ({ handleUpdateData, isReload }) => {
  const [load, setload] = useState(false);
  const [AllData, setAllData] = useState([]);
  const [MainData, setMainData] = useState([]);
  const [currentPage, setcurrentPage] = useState(1);
  const [isUpdate, setisUpdate] = useState(false);
  const [recordsPerPage] = useState(10);
  const [nPage, setnPage] = useState([1]);
  const handleSearch = (e) => {
    let val = e.target.value;
    let newArray = AllData.filter(
      (item) =>
        item.name?.toLowerCase()?.includes(val.toLowerCase()) ||
        item.Code?.toString()?.toLowerCase()?.includes(val.toLowerCase())
    );
    setcurrentPage(1);
    const indexOfLastRecord = 1 * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const currentRecords = newArray.slice(
      indexOfFirstRecord,
      indexOfLastRecord
    );
    setMainData(currentRecords);
    const nPages = Math.ceil(newArray?.length / recordsPerPage);
    const num = [...Array(nPages + 1).keys()].slice(1);
    setnPage(num);
  };
  const handleStatusChange = (obj) => {
    toast
      .promise(POSTAPI(Api.ChangePromoCode, obj), {
        loading: "Wait Few Moment !",
        success: "Status Change Successfully !",
        error: "Somthing Wrong ",
      })
      .then(() => {
        setisUpdate(!isUpdate);
        handleUpdateData();
      })
      .catch(() => {});
  };
  const handleChangePage = (id) => {
    setcurrentPage(id);
    const indexOfLastRecord = id * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    let currentRecords = AllData.slice(indexOfFirstRecord, indexOfLastRecord);
    setMainData(currentRecords);
  };
  useEffect(() => {
    (async () => {
      try {
        setload(false);
        const data = await POSTAPI(Api.ShowPromoCode);
        let MainList = data.data?.filter((el) => el.Status == "Active");
        setAllData((prev) => MainList);
        let indexOfLastRecord = currentPage * recordsPerPage;
        let indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
        let currentRecords = MainList.slice(
          indexOfFirstRecord,
          indexOfLastRecord
        );
        setMainData(currentRecords);
        let nPages = Math.ceil(MainList?.length / recordsPerPage);
        let num = [...Array(nPages + 1).keys()].slice(1);
        setnPage(num);
      } catch (er) {
      } finally {
        setload(true);
      }
    })();
  }, [isUpdate, isReload]);
  return (
    <div
      class="tab-pane fade"
      id="pills-profile"
      role="tabpanel"
      aria-labelledby="pills-profile-tab"
    >
      <div className="pro-add-new px-0">
        <p> ACTIVE PROMO CODE </p>
        <div className="form-floating small-floating">
          <input
            type="text"
            className="form-control py-2 w-auto"
            id="floatingInput"
            placeholder="name@example.com"
            onChange={handleSearch}
          />
          <label for="floatingInput">Search</label>
        </div>
      </div>
      <div className="responsive-table">
        <table className="table">
          <thead>
            <tr>
              <th>ID</th>
              <th>Promo Code Name</th>
              <th>Promo Code</th>
              <th>Discount Type ( Percentage / Flat Discount )</th>
              <th>Discription</th>
              <th>Start Date</th>
              <th>Expiry Date</th>
              <th>Change Status</th>
            </tr>
          </thead>
          <tbody>
            {!load ? (
              <tr>
                <td colSpan={8}>
                  <div className="d-flex justify-content-center  align-items-center ">
                    <div className="loader"></div>
                  </div>
                </td>
              </tr>
            ) : !MainData?.length ? (
              <tr>
                <td colSpan={12}>
                  <div className="d-flex justify-content-center flex-column  align-items-center ">
                    <img src={NotFound} className="w-25 h-25" alt="" />
                    <p>PromoCode Not Found !</p>
                  </div>
                </td>
              </tr>
            ) : (
              MainData?.map((item, index) => {
                return (
                  <ActiveList
                    currentPage={currentPage}
                    item={item}
                    index={index}
                    handleChangeStatus={handleStatusChange}
                  />
                );
              })
            )}
          </tbody>
        </table>
      </div>
      <Pagination
        nPage={nPage}
        currentPage={currentPage}
        handleChangePage={handleChangePage}
      />
    </div>
  );
};

export default ActivePromo;
