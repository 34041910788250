import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Paggination from "../Paggination";
import toast from "react-hot-toast";
import { DeleteFAQApi } from "../../helper/integration";
import NotFound from "../../Image/notfound.png";
import bootstrap from "bootstrap/dist/js/bootstrap.bundle.min";

const Driver = ({ DriverData, isLoad, handleReferesh, setItem }) => {
  const [AllDriverData, setAllDriverData] = useState([]);
  const [MainDriverData, setMainDriverData] = useState([]);
  const [currentPage, setcurrentPage] = useState(1);
  const [recordsPerPage] = useState(10);
  const [nPage, setnPage] = useState([1]);
  const [itemIdToDelete, setItemIdToDelete] = useState(null);

  const handleSearch = (e) => {
    let val = e.target.value;
    let newArray = AllDriverData.filter(
      (item) =>
        item.question.toLowerCase().includes(val.toLowerCase()) ||
        item.answer.toLowerCase().includes(val.toLowerCase())
    );

    setcurrentPage(1);
    const indexOfLastRecord = 1 * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const currentRecords = newArray.slice(
      indexOfFirstRecord,
      indexOfLastRecord
    );
    setMainDriverData(currentRecords);
    const nPages = Math.ceil(newArray?.length / recordsPerPage);
    const num = [...Array(nPages + 1).keys()].slice(1);
    setnPage(num);
  };

  const handleDeleteFAQ = (id) => {
    setItemIdToDelete(id); // Set the item ID to delete
    //setDeleteModalVisible(true);
    const deleteModal = new bootstrap.Modal(
      document.getElementById("deleteConfirmationModal")
    );
    deleteModal.show();
  };

  const confirmDelete = async () => {
    if (itemIdToDelete) {
      try {
        await toast.promise(DeleteFAQApi({ id: itemIdToDelete }), {
          loading: "Wait For Moment !",
          success: "FAQ Delete Successfully !",
          error: "Somethig Wrong !",
        });
        handleReferesh();
      } catch (er) {
        console.log(er);
      }
    }
  };

  const handleChangePage = (id) => {
    setcurrentPage(id);
    const indexOfLastRecord = id * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    let currentRecords = AllDriverData.slice(
      indexOfFirstRecord,
      indexOfLastRecord
    );
    setMainDriverData(currentRecords);
  };
  useEffect(() => {
    (function () {
      setAllDriverData((prev) => DriverData);
      let indexOfLastRecord = currentPage * recordsPerPage;
      let indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
      let currentRecords = DriverData?.slice(
        indexOfFirstRecord,
        indexOfLastRecord
      );
      setMainDriverData((prev) => currentRecords);
      let nPages = Math.ceil(DriverData?.length / recordsPerPage);
      let num = [...Array(nPages + 1).keys()].slice(1);
      setnPage(num);
    })();
  }, [DriverData]);
  return (
    <div
      class="tab-pane fade show active"
      id="pills-home"
      role="tabpanel"
      aria-labelledby="pills-home-tab"
    >
      <div className="pro-add-new px-0">
        <p>DRIVER FAQ</p>
        <div className="form-floating small-floating">
          <input
            type="text"
            className="form-control py-2 w-auto"
            id="floatingInput"
            placeholder="name@example.com"
            onChange={handleSearch}
          />
          <label for="floatingInput">Search</label>
        </div>
      </div>
      <div className="d-flex align-items-end justify-content-between">
        <div></div>
        <div className="mb-3 pro-add-new px-0">
          <Link
            type="button"
            className="btn add-new"
            data-bs-toggle="modal"
            data-bs-target="#adddriverfaq"
          >
            <i className="fa-solid fa-plus"></i> Add Driver FAQ
          </Link>
        </div>
      </div>
      <div className="responsive-table">
        <table className="table">
          <thead>
            <tr>
              <th>ID</th>
              <th>Question</th>
              <th>Answer</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {!isLoad ? (
              <tr>
                <td colSpan={4}>
                  <div
                    className="d-flex justify-content-center align-items-center  "
                    style={{ height: "10vh" }}
                  >
                    <div class="loader"></div>
                  </div>
                </td>
              </tr>
            ) : MainDriverData?.length ? (
              MainDriverData?.map((item, index) => {
                return (
                  <tr key={item?._id}>
                    <td>
                      {" "}
                      {currentPage == 1
                        ? index + 1
                        : index + 1 + currentPage * 10 - 10}
                    </td>
                    <td>{item?.question}</td>
                    <td>{item?.answer}</td>
                    <td>
                      <div className="icon-up-del">
                        <Link
                          onClick={() => setItem(item)}
                          type="button"
                          data-bs-toggle="modal"
                          data-bs-target="#editdriverfaq"
                        >
                          <i className="fa-solid fa-pen"></i>
                        </Link>
                        <Link onClick={() => handleDeleteFAQ(item?._id)}>
                          <i className="fa-solid fa-trash"></i>
                        </Link>
                      </div>
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan={4}>
                  <div
                    className="d-flex flex-column  mt-2 justify-content-center align-items-center  "
                    style={{ height: "15vh" }}
                  >
                    <img src={NotFound} style={{ height: "100px" }} alt="" />
                    <div>Driver FAQ not found !</div>
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      {/* Delete Confirmation Modal */}
      <div
        className="modal fade"
        id="deleteConfirmationModal"
        tabIndex="-1"
        aria-labelledby="deleteConfirmationModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header bg-primary">
              <h5
                className="modal-title text-white"
                id="deleteConfirmationModalLabel"
              >
                Confirm Deletion
              </h5>
              <button
                type="button"
                className="btn-close bg-white"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <h4 className="py-4 mx-auto">
                Are you sure you want to delete this Driver FAQ?
              </h4>
              <div className="d-flex justify-content-center pt-2">
                <button
                  id="confirmDeleteButton"
                  className="btn btn-primary me-2"
                  data-bs-dismiss="modal"
                  onClick={confirmDelete}
                >
                  Yes
                </button>
                <button className="btn btn-secondary" data-bs-dismiss="modal">
                  No
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Paggination
        nPage={nPage}
        currentPage={currentPage}
        handleChangePage={handleChangePage}
      />
    </div>
  );
};

export default Driver;
