import React from "react";

const ViewModal2 = ({ item }) => {
  return (
    <div
      className="modal fade"
      id="viewexampleModaledit"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              View New Request
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <div>
              <div className="user-details">
                <form>
                  <div className="mb-3">
                    <label className="mb-2">Select Customer Name</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder={item?.UserID?.username}
                      disabled
                    />
                  </div>
                  <div className="mb-3">
                    <label className="mb-2"> Service Type</label>
                    <div>
                      {item?.type == "Taxi Booking" && (
                        <p class="badge bg-info me-1 text-white mb-0">
                          Taxi Bookings
                        </p>
                      )}
                      {item?.type == "Courier Delivery" && (
                        <p class="badge bg-dark me-1 text-white mb-0">
                          Courier Bookings
                        </p>
                      )}
                      {/* <p class="badge bg-info me-1 text-white mb-0">
                          Taxi Bookings
                        </p>
                        <p class="badge bg-dark me-1 text-white mb-0">
                          Courier Bookings
                        </p> */}
                    </div>
                  </div>
                  <div className="mb-3">
                    <label className="mb-2"> Mobile Verification</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder={item?.UserID?.mobilenumberStatus}
                      disabled
                    />
                  </div>
                  <div className="mb-3">
                    <label className="mb-2">Pickup Location</label>
                    <input
                      type="text"
                      className="form-control"
                      //placeholder="RTO Circle"
                      placeholder={item?.pickup_locations}
                      disabled
                    />
                  </div>
                  <div className="mb-3">
                    <label className="mb-2">Dropoff Location</label>
                    <input
                      type="text"
                      className="form-control"
                      //placeholder="Jewels Circle"
                      disabled
                      placeholder={item?.drop_locations}
                    />
                  </div>
                  <div className="mb-3">
                    <label className="mb-2">Total Amount</label>
                    <input
                      type="number"
                      className="form-control"
                      //placeholder="10$"
                      placeholder={item?.TotalAmount}
                      disabled
                    />
                  </div>
                  <div className="mb-3">
                    <label className="mb-2">Payment Method</label>
                    <input
                      type="text"
                      className="form-control"
                      //placeholder="Cash"
                      placeholder={item?.payment_type}
                      disabled
                    />
                  </div>
                  <div className="mb-3 col-md-6 col-12">
                    <label className="mb-2">Status</label>
                    <div>
                      {item?.status == "Pending" ? (
                        <p class="badge bg-warning me-1 text-white mb-0">
                          Pending
                        </p>
                      ) : (
                        <p class="badge bg-success me-1 text-white mb-0">
                          Complete
                        </p>
                      )}
                      {/* <p class="badge bg-success me-1 text-white mb-0">
                          Approved
                        </p>
                        <p class="badge bg-warning me-1 text-white mb-0">
                          Pending
                        </p> */}
                    </div>
                  </div>
                  {/* <div className="upload-reset-btn mb-0 justify-content-center pt-2">
                    <button className="btn btn-reset" data-bs-dismiss="modal">
                      Cancel
                    </button>
                    <button
                      className="btn btn-upload me-0"
                      data-bs-dismiss="modal"
                    >
                      Save
                    </button>
                  </div> */}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewModal2;
