import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Sidebar from "./Sidebar";
import Footer from "./Footer";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Api } from "../utils/Api";
import toast from "react-hot-toast";
import { ShowContentApi, UpdateContent } from "../helper/integration";

const PrivacyPolicy = () => {
  const [Title, setTitle] = useState("");
  const [Text, setText] = useState("");
  const [OldTitle, setOldTitle] = useState("");
  const [OldText, setOldText] = useState("");
  const [isLoad, setisLoad] = useState(false);
  let modules = {
    toolbar: [
      [{ size: ["small", false, "large", "huge"] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link", "image"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
        { align: [] },
      ],
      [
        {
          color: [
            "#000000",
            "#e60000",
            "#ff9900",
            "#ffff00",
            "#008a00",
            "#0066cc",
            "#9933ff",
            "#ffffff",
            "#facccc",
            "#ffebcc",
            "#ffffcc",
            "#cce8cc",
            "#cce0f5",
            "#ebd6ff",
            "#bbbbbb",
            "#f06666",
            "#ffc266",
            "#ffff66",
            "#66b966",
            "#66a3e0",
            "#c285ff",
            "#888888",
            "#a10000",
            "#b26b00",
            "#b2b200",
            "#006100",
            "#0047b2",
            "#6b24b2",
            "#444444",
            "#5c0000",
            "#663d00",
            "#666600",
            "#003700",
            "#002966",
            "#3d1466",
            "custom-color",
          ],
        },
      ],
    ],
  };
  let formats = [
    "header",
    "height",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "color",
    "bullet",
    "indent",
    "link",
    "image",
    "align",
    "size",
  ];

  const handleAddData = () => {
    toast
      .promise(
        UpdateContent({
          type: "About",
          title: Title,
          detail: Text,
        }),
        {
          loading: "Wait Few Moment !",
          success: "Content Update Successfully !",
          error: "Something Wrong !",
        }
      )
      .then(() => {
        setOldTitle(Title);
        setOldText(Text);
      })
      .catch(() => {});
  };

  const handleCancel = () => {
    setTitle(OldText);
    setText(OldTitle);
    toast.success("Undo Successfully ! ");
  };
  useEffect(() => {
    (async function () {
      try {
        const data = await ShowContentApi(Api.ShowContent, { type: "Policy" });
        if (data?.length) {
          setTitle((prev) => data[0].title);
          setText((prev) => data[0]?.detail);
          setOldText((prev) => data[0].title);
          setOldTitle((prev) => data[0]?.detail);
        }
        setisLoad(true);
      } catch (er) {
        console.log(er);
      }
    })();
  }, []);

  return (
    <div>
      {<Sidebar />}
      <div className="asside">
        <div className="about-first">
          <div className="row">
            <div className="col-12 mb-24">
              <div className="bg-box">
                <div className="pro-add-new px-0">
                  <p>PRIVACY POLICY</p>
                </div>
                <div>
                  <div className="mb-3">
                    <label className="mb-2">Title</label>
                    <input
                      type="text"
                      className="form-control"
                      value={Title}
                      onChange={(e) => setTitle(e.target.value)}
                    />
                  </div>
                  <div className="mb-3">
                    <label className="mb-2">Details</label>
                    <ReactQuill
                      theme="snow"
                      modules={modules}
                      formats={formats}
                      placeholder="write your content ...."
                      value={Text}
                      onChange={(e) => {
                        setText(e);
                      }}
                    ></ReactQuill>
                  </div>

                  <div className="upload-reset-btn mb-0 justify-content-center pt-2">
                    <Link
                      onClick={handleCancel}
                      className="btn btn-reset"
                      data-bs-dismiss="modal"
                    >
                      Cancel
                    </Link>
                    <button
                      className="btn btn-upload me-0"
                      data-bs-dismiss="modal"
                      onClick={handleAddData}
                    >
                      Update
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {<Footer />}
      </div>
    </div>
  );
};

export default PrivacyPolicy;
