import React, { useId, useState } from "react";

const ActiveList = ({ item, index, currentPage, handleChangeStatus }) => {
  const [Status, setStatus] = useState(item?.Status);
  const id = useId();
  return (
    <tr>
      <td>
        {" "}
        {currentPage == 1 ? index + 1 : index + 1 + currentPage * 10 - 10}
      </td>
      <td>{item?.name || "-"}</td>
      <td>{item?.Code || "-"}</td>
      <td>{item?.Discount}%</td>
      <td>{item?.discription || "-"}</td>
      <td>{item?.start_date}</td>
      <td>{item?.end_date}</td>
      <td>
        <div
          class="btn-group"
          role="group"
          aria-label="Basic radio toggle button group"
        >
          <input
            type="radio"
            class="btn-check btn-active"
            name={`${id}`}
            id={`banner${id}`}
            checked={Status == "Active" ? true : false}
            onClick={() => {
              handleChangeStatus({
                id: item?._id,
                status: "Active",
              });
            }}
          />
          <label
            class="btn active-btn btn-outline-success btn-sm"
            for={`banner${id}`}
          >
            Active
          </label>

          <input
            type="radio"
            class={`btn-check btn-inactive ${
              Status == "Deactive" && "inactive-active"
            } `}
            name={`${id}`}
            id={`banner2${id}`}
            autocomplete="off"
            onClick={() => {
              handleChangeStatus({
                id: item?._id,
                status: "Deactive",
              });
            }}
          />
          <label
            class="btn inactive-btn btn-outline-danger btn-sm"
            for={`banner2${id}`}
          >
            Inactive
          </label>
        </div>
      </td>
    </tr>
  );
};

export default ActiveList;
