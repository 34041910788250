import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Sidebar from "./Sidebar";
import Footer from "./Footer";
import user from "../Image/user.png";
import ViewModal from "../common/user/ViewModal";
import TypeViewModel from "../common/booking/ViewModal";
import ShowDriver from "../common/driver/ShowDriver";
import { ShowFilterWiseBookApi } from "../helper/integration";
import Paggination from "../common/Paggination";
import NotFound from "../Image/notfound.png";

function convertDateFormat(dateString) {
  const date = new Date(dateString);
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear();
  let hours = date.getHours();
  const minutes = date.getMinutes().toString().padStart(2, "0");
  const ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12; // Handle midnight (0 hours)
  const time = hours.toString().padStart(2, "0") + ":" + minutes + " " + ampm;
  return `${day}-${month}-${year} ${time}`;
}

const VehicleTypes = () => {
  const [AllData, setAllData] = useState([]);
  const [SearchData, setSearchData] = useState([]);
  const [Books, setBooks] = useState([]);
  const [isUpdate, setisUpdate] = useState(false);
  const [StartDate, setStartDate] = useState(null);
  const [EndDate, setEndDate] = useState(null);
  const [SignleData, setSignleData] = useState(null);
  const [BookingType, setBookingType] = useState("");
  const [PaymentType, setPaymentType] = useState("");
  const [currentPage, setcurrentPage] = useState(1);
  const [recordsPerPage] = useState(10);
  const [nPage, setnPage] = useState([1]);
  const [isLoad, setisLoad] = useState(false);
  const handleSearch = (e) => {
    let val = e.target.value;
    let newArray;

    newArray = AllData.filter(
      (item) =>
        item.RideId?.toLowerCase().includes(val.toLowerCase()) ||
        item.UserID?.username?.toLowerCase()?.includes(val.toLowerCase()) ||
        item.DriverID?.username?.toLowerCase()?.includes(val.toLowerCase()) ||
        item.createdAt?.toLowerCase()?.includes(val.toLowerCase())
    );
    setSearchData(newArray);
    setcurrentPage(1);
    const indexOfLastRecord = 1 * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const currentRecords = newArray.slice(
      indexOfFirstRecord,
      indexOfLastRecord
    );
    setBooks(currentRecords);
    const nPages = Math.ceil(newArray?.length / recordsPerPage);
    const num = [...Array(nPages + 1).keys()].slice(1);
    setnPage(num);
  };
  const handleChangePage = (id) => {
    setcurrentPage(id);
    const indexOfLastRecord = id * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    let currentRecords;
    if (SearchData?.length) {
      currentRecords = SearchData.slice(indexOfFirstRecord, indexOfLastRecord);
    } else {
      currentRecords = AllData.slice(indexOfFirstRecord, indexOfLastRecord);
    }
    setBooks(currentRecords);
  };
  useEffect(() => {
    (async () => {
      try {
        setisLoad(false);
        let obj = {
          bookType: BookingType,
          cardType: PaymentType,
          startDate: StartDate,
          endDate: EndDate,
        };
        const data = await ShowFilterWiseBookApi(obj);
        setAllData(data);
        let indexOfLastRecord = currentPage * recordsPerPage;
        let indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
        let currentRecords = data?.slice(indexOfFirstRecord, indexOfLastRecord);
        setBooks(currentRecords);
        let nPages = Math.ceil(data?.length / recordsPerPage);
        let num = [...Array(nPages + 1).keys()].slice(1);
        setnPage(num);
        setisLoad(true);
      } catch (er) {
        console.log(er);
      }
    })();
  }, [isUpdate]);
  return (
    <div>
      {<Sidebar />}
      <div className="asside">
        <div className="about-first">
          <div className="row">
            <div className="col-12 mb-24">
              <div className="bg-box">
                <div className="pro-add-new px-0">
                  <p>TRANSACTIONS</p>
                  <div className="form-floating small-floating">
                    <input
                      type="text"
                      className="form-control py-2 w-auto"
                      id="floatingInput"
                      placeholder="name@example.com"
                      onChange={handleSearch}
                    />
                    <label for="floatingInput">Search</label>
                  </div>
                </div>
                <div className="d-flex">
                  <div className="mb-3">
                    <label className="mb-2">Start Date & End Date</label>
                    <div className="d-flex align-items-center">
                      <input
                        type="date"
                        onChange={(e) => {
                          setStartDate((prev) => e.target.value);
                          setisUpdate(!isUpdate);
                        }}
                        className="form-control me-3"
                      />
                      <p className="mb-0 me-3">To</p>
                      <input
                        type="date"
                        onChange={(e) => {
                          setEndDate((prev) => e.target.value);
                          setisUpdate(!isUpdate);
                        }}
                        className="form-control me-3"
                      />
                    </div>
                  </div>
                  <div className="mb-3 me-3">
                    <label className="mb-2">Payment Type</label>
                    <select
                      className="form-select form-control"
                      id=""
                      aria-label="Floating label select example"
                      onChange={(e) => {
                        setPaymentType((prev) => e.target.value);
                        setisUpdate(!isUpdate);
                      }}
                    >
                      <option selected value="">
                        Select Type
                      </option>
                      <option value="Card">Credit</option>
                      <option value="Cash Payment">Debit</option>
                    </select>
                  </div>
                  <div className="mb-3 me-3">
                    <label className="mb-2">Booking Type</label>
                    <select
                      className="form-select form-control"
                      id=""
                      aria-label="Floating label select example"
                      onChange={(e) => {
                        setBookingType((prev) => e.target.value);
                        setisUpdate(!isUpdate);
                      }}
                    >
                      <option selected value="">
                        Select Type
                      </option>
                      <option value="Taxi Booking">Taxi Bookings</option>
                      <option value="Courier Delivery">Courier Bookings</option>
                    </select>
                  </div>
                </div>
                <div className="responsive-table">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Trip ID</th>
                        <th>Driver Name</th>
                        <th>User Name</th>
                        <th>Booking Type</th>
                        <th>Booking Amount</th>
                        <th>Payment Type</th>
                        <th>Admin Earning</th>
                        <th>Date Time</th>
                      </tr>
                    </thead>
                    <tbody>
                      {!isLoad ? (
                        <tr>
                          <td colSpan={8}>
                            <div
                              className="d-flex justify-content-center  align-items-center "
                              style={{ height: "10vh" }}
                            >
                              <div className="loader"></div>
                            </div>
                          </td>
                        </tr>
                      ) : Books?.length ? (
                        Books.map((item) => {
                          return (
                            <tr>
                              <td>
                                <Link
                                  onClick={() => setSignleData(item)}
                                  type="button"
                                  data-bs-toggle="modal"
                                  data-bs-target="#viewexampleModaledit"
                                >
                                  {item?.RideId}
                                </Link>
                              </td>
                              <td>
                                {item?.DriverID?.username ? (
                                  <Link
                                    onClick={() => setSignleData(item)}
                                    type="button"
                                    data-bs-toggle="modal"
                                    data-bs-target="#viewDriver"
                                  >
                                    {item?.DriverID?.username}
                                  </Link>
                                ) : (
                                  "-"
                                )}
                              </td>
                              <td>
                                {item?.UserID?.username ? (
                                  <Link
                                    onClick={() => setSignleData(item)}
                                    type="button"
                                    data-bs-target="#viewUser"
                                    data-bs-toggle="modal"
                                  >
                                    {item?.UserID?.username}
                                  </Link>
                                ) : (
                                  "-"
                                )}
                              </td>

                              <td>{item?.type}</td>
                              <td>{item?.TotalAmount}</td>
                              <td>
                                {item?.payment_type == "Card" ? (
                                  <Link
                                    to={"#"}  
                                    class="badge bg-success me-1 text-white"
                                  >
                                    Credit
                                  </Link>
                                ) : (
                                  <Link
                                    to={"#"}
                                    class="badge bg-warning me-1 text-white"
                                  >
                                    Debit
                                  </Link>
                                )}
                              </td>
                              <td>10</td>
                              <td>
                                {item?.createdAt
                                  ? convertDateFormat(item?.createdAt)
                                  : "-"}
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan={8}>
                            <div
                              className="d-flex flex-column  justify-content-center align-items-center mt-2  "
                              style={{ height: "15vh" }}
                            >
                              <img
                                src={NotFound}
                                style={{ height: "100px" }}
                                alt=""
                              />
                              <div> Driver Document not found !</div>
                            </div>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
                <Paggination
                  nPage={nPage}
                  currentPage={currentPage}
                  handleChangePage={handleChangePage}
                />
              </div>
            </div>
          </div>
        </div>

        <TypeViewModel item={SignleData} />

        {/* <!-- View Driver Modal --> */}
        <ShowDriver SingleDriver={SignleData?.DriverID} />
        {/* <!--End Driver Modal --> */}

        {/* <!-- View User Modal --> */}
        <ViewModal item={SignleData?.UserID} />
        {/* <!--End User Modal --> */}

        {<Footer />}
      </div>
    </div>
  );
};

export default VehicleTypes;
