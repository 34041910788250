import React, { useEffect, useState } from "react";

import Sidebar from "./Sidebar";
import Footer from "./Footer";

import AllPromo from "../common/PromoCode/AllPromo";
import ActivePromo from "../common/PromoCode/ActivePromo";
import AddModal from "../common/PromoCode/AddModal";
import { POSTAPI } from "../helper/integration";
import { Api } from "../utils/Api";

const Promotions = () => {
  const [discountType, setDiscountType] = useState("");
  const [isLoad, setisLoad] = useState(false);
  const [isUpdate, setisUpdate] = useState(false);
  const [Codes, setCodes] = useState([]);
  const handleChange = (e) => {
    setDiscountType(e.target.value);
  };

  const handleUpdateData = () => setisUpdate(!isUpdate);

  useEffect(() => {
    (async () => {
      setisLoad(false);
      try {
        const data = await POSTAPI(Api.ShowPromoCode);

        setCodes((prev) => data.data);
      } catch (er) {
        console.log(er);
      } finally {
        setTimeout(() => {
          setisLoad(true);
        }, 100);
      }
    })();
  }, [isUpdate]);
  return (
    <div>
      {<Sidebar />}
      <div className="asside">
        <div className="about-first">
          <div className="row">
            <div className="col-12 mb-24">
              <div className="bg-box">
                <nav className="mb-3">
                  <div class="nav nav-tabs" id="nav-tab" role="tablist">
                    <button
                      class="nav-link active"
                      id="pills-home-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-home"
                      type="button"
                      role="tab"
                      aria-controls="pills-home"
                      aria-selected="true"
                    >
                      {" "}
                      All Promo Code{" "}
                    </button>
                    <button
                      class="nav-link position-relative"
                      id="pills-profile-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-profile"
                      type="button"
                      role="tab"
                      aria-controls="pills-profile"
                      aria-selected="false"
                    >
                      Active Promo Code
                      <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                        {Codes?.filter((el) => el.Status == "Active").length}
                      </span>
                    </button>
                  </div>
                </nav>
                <div class="tab-content" id="pills-tabContent">
                  <AllPromo
                    handleUpdateData={handleUpdateData}
                    isReload={isUpdate}
                  />
                  <ActivePromo
                    handleUpdateData={handleUpdateData}
                    isReload={isUpdate}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <!-- Edit Modal --> */}

        {/* <!--End Edit Modal --> */}

        {/* <!-- Add Modal --> */}
        <AddModal handleUpdte={handleUpdateData} />
        {/* <!--End Add Modal --> */}

        {<Footer />}
      </div>
    </div>
  );
};

export default Promotions;
