import React, { useState } from "react";
import { Link } from "react-router-dom";
import user from "../../Image/user.png";

const BannerList = ({
  item,
  index,
  handleChangeStatus,
  isDisble,
  handleDeleteBanner,
  handleSetSingleData,
}) => {
  const [status, setstatus] = useState(item?.status);
  return (
    <tr>
      <td>{index}</td>
      <td>{item?.title}</td>
      <td>{item?.types}</td>
      <td>
        <div className="profile-img">
          <img
            src={item?.image}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = user;
            }}
            className="img-fluid"
          />
        </div>
      </td>

      <td>
        <div
          class="btn-group"
          role="group"
          aria-label="Basic radio toggle button group"
        >
          <input
            type="radio"
            class="btn-check btn-active"
            name={`${index}`}
            id={`banner${index}`}
            checked={status == "Active" && true}
            disabled={isDisble}
            onClick={() => {
              setstatus((prev) => "Active");
              handleChangeStatus({ user_id: item?._id, status: "Active" });
            }}
          />
          <label
            class="btn active-btn btn-outline-success btn-sm"
            for={`banner${index}`}
          >
            Active
          </label>

          <input
            type="radio"
            class="btn-check btn-inactive"
            name={`${index}`}
            id={`banner2${index}`}
            autocomplete="off"
            disabled={isDisble}
            checked={status == "Deactive" && true}
            onClick={() => {
              setstatus((prev) => "Deactive");
              handleChangeStatus({ user_id: item?._id, status: "Deactive" });
            }}
          />
          <label
            class="btn inactive-btn btn-outline-danger btn-sm"
            for={`banner2${index}`}
          >
            Inactive
          </label>
        </div>
      </td>
      <td>
        <div className="icon-up-del">
          <Link
            onClick={() => handleSetSingleData(item)}
            type="button"
            data-bs-toggle="modal"
            data-bs-target="#exampleModaledit"
          >
            <i className="fa-solid fa-pen"></i>
          </Link>
          <span
            style={{ cursor: "pointer" }}
            onClick={() => handleDeleteBanner(item?._id)}
          >
            <i className="fa-solid fa-trash"></i>
          </span>
        </div>
      </td>
    </tr>
  );
};

export default BannerList;
