import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Dashboard from "./components/Dashboard";
import UserDetails from "./components/UserDetails";
import DisputeSupport from "./components/DisputeSupport";
import PaymentSupport from "./components/PaymentSupport";
import OtherSupport from "./components/OtherSupport";
import NewUser from "./components/NewUser";
import DriverDetails from "./components/DriverDetails";
import PromoCode from "./components/PromoCode";
import PromotionBanner from "./components/PromotionBanner";
import Transactions from "./components/Transactions";
import Notification from "./components/Notification";
import FAQ from "./components/FAQ";
import AboutUs from "./components/AboutUs";
import PrivacyPolicy from "./components/PrivacyPolicy";
import TermsConditions from "./components/TermsConditions";
import VehicleTypes from "./components/VehicleTypes";
import DriverDocuments from "./components/DriverDocuments";
import Bookings from "./components/Bookings";
import TotalBooking from "./components/TotalBooking";
import CompleteBooking from "./components/CompleteBooking";
import BirdEyeView from "./components/BirdEyeView";
import Refunded from "./components/Refunded";
import Pending from "./components/Pending";
import HelpSupport from "./components/HelpSupport";
import Scrolltotop from "./Scrolltotop";
import Signin from "./components/Signin";
import EmailVerification from "./components/EmailVerification";
import ForgotPassword from "./components/ForgotPassword";
import Successfullysentmail from "./components/Successfullysentmail";
import ChangePassword from "./components/ChangePassword";
import Profile from "./components/Profile";
import NewRequest from "./components/NewRequest";
import CurrentRequest from "./components/CurrentRequest";
import Statistics from "./components/Statistics";
import AddNewRequest from "./components/AddNewRequest";
import { Toaster } from "react-hot-toast";

function App() {
  return (
    <div className="App">
      <Router>
        <Scrolltotop />
        <Routes>
          <Route path="/" element={<Signin />} />
          <Route path="/ForgotPassword" element={<ForgotPassword />} />
          <Route path="/EmailVerification" element={<EmailVerification />} />
          <Route path="/Dashboard" element={<Dashboard />} />
          <Route path="/UserDetails" element={<UserDetails />} />
          <Route path="/DisputeSupport" element={<DisputeSupport />} />
          <Route path="/PaymentSupport" element={<PaymentSupport />} />
          <Route path="/OtherSupport" element={<OtherSupport />} />
          <Route path="/NewUser" element={<NewUser />} />
          <Route path="/DriverDetails" element={<DriverDetails />} />
          <Route path="/PromoCode" element={<PromoCode />} />
          <Route path="/PromotionBanner" element={<PromotionBanner />} />
          <Route path="/Transactions" element={<Transactions />} />
          <Route path="/Notification" element={<Notification />} />
          <Route path="/FAQ" element={<FAQ />} />
          <Route path="/AboutUs" element={<AboutUs />} />
          <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
          <Route path="/TermsConditions" element={<TermsConditions />} />
          <Route path="/VehicleTypes" element={<VehicleTypes />} />
          <Route path="/DriverDocuments" element={<DriverDocuments />} />
          <Route path="/Bookings" element={<Bookings />} />
          <Route path="/TotalBooking" element={<TotalBooking />} />
          <Route path="/CompleteBooking" element={<CompleteBooking />} />
          <Route path="/BirdEyeView" element={<BirdEyeView />} />
          <Route path="/Refunded" element={<Refunded />} />
          <Route path="/Pending" element={<Pending />} />
          <Route path="/HelpSupport" element={<HelpSupport />} />
          <Route path="/Successfullysentmail" element={<Successfullysentmail />} />
          <Route path="/ChangePassword" element={<ChangePassword />} />
          <Route path="/Profile" element={<Profile />} />
          <Route path="/NewRequest" element={<NewRequest />} />
          <Route path="/CurrentRequest" element={<CurrentRequest />} />
          <Route path="/Statistics" element={<Statistics />} />
          <Route path="/AddNewRequest" element={<AddNewRequest />} />
        </Routes>
      </Router>
      <Toaster />
    </div>
  );
}

export default App;
