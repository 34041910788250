import React, { useEffect, useState } from "react";
import user from "../../Image/user.png";
import { Link } from "react-router-dom";
import Paggination from "../Paggination";
import toast from "react-hot-toast";
import { DriverStatusChange } from "../../helper/integration";
import NotFound from "../../Image/notfound.png";
const DriverRequest = ({ drivers, isLoad, isUpdate, setisUpdate }) => {
  const [AllDrivers, setAllDrivers] = useState([]);
  const [Drivers, setDrivers] = useState([]);
  const [currentPage, setcurrentPage] = useState(1);
  const [recordsPerPage] = useState(10);
  const [nPage, setnPage] = useState([1]);

  const handleSearch = (e) => {
    let val = e.target.value;
    let newArray;

    newArray = AllDrivers.filter(
      (item) =>
        item.username.toLowerCase().includes(val.toLowerCase()) ||
        item.email.toLowerCase().includes(val.toLowerCase()) ||
        item.mobilenumber.toLowerCase().includes(val.toLowerCase())
    );
    setcurrentPage(1);
    const indexOfLastRecord = 1 * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const currentRecords = newArray.slice(
      indexOfFirstRecord,
      indexOfLastRecord
    );
    setDrivers(currentRecords);
    const nPages = Math.ceil(newArray?.length / recordsPerPage);
    const num = [...Array(nPages + 1).keys()].slice(1);
    setnPage(num);
  };

  const handleChangeStatus = (obj) => {
    toast
      .promise(DriverStatusChange(obj), {
        loading: "Wait Few Moment !",
        error: "Something Wrong !",
        success: `Driver ${obj.type} successfully !`,
      })
      .then(() => {
        setisUpdate(!isUpdate);
      })
      .catch(() => {});
  };

  const handleChangePage = (id) => {
    setcurrentPage(id);
    const indexOfLastRecord = id * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    let currentRecords;
    currentRecords = AllDrivers.slice(indexOfFirstRecord, indexOfLastRecord);
    setDrivers(currentRecords);
  };
  useEffect(() => {
    (function () {
      let newData = drivers?.filter((item) => item?.status == "pending");
      setAllDrivers((prev) => newData);
      let indexOfLastRecord = currentPage * recordsPerPage;
      let indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
      let currentRecords = newData.slice(indexOfFirstRecord, indexOfLastRecord);
      setDrivers((prev) => currentRecords);
      let nPages = Math.ceil(newData?.length / recordsPerPage);
      let num = [...Array(nPages + 1).keys()].slice(1);
      setnPage(num);
    })();
  }, [drivers]);
  return (
    <div
      class="tab-pane fade"
      id="pills-profile"
      role="tabpanel"
      aria-labelledby="pills-profile-tab"
    >
      <div className="pro-add-new px-0">
        <p>REQUEST DETAILS</p>
        <div className="form-floating small-floating">
          <input
            type="text"
            className="form-control py-2 w-auto"
            id="floatingInput"
            placeholder="name@example.com"
            onChange={handleSearch}
          />
          <label for="floatingInput">Search</label>
        </div>
      </div>
      {/* <div className="d-flex align-items-end justify-content-between">
        <div className="mb-3 col-lg-2 col-md-3 col-5">
          <label className="mb-2">Filter</label>
          <select
            className="form-select form-control"
            id=""
            aria-label="Floating label select example"
          >
            <option selected>All </option>
            <option value="1">Verified </option>
            <option value="2">Pending</option>
          </select>
        </div>
        <div
          class="mb-3 btn-group"
          role="group"
          aria-label="Basic outlined example"
        >
          <button type="button" class="btn btn-outline-primary shadow-none">
            EXPORT CSV
          </button>
          <button type="button" class="btn btn-outline-primary shadow-none">
            IMPORT CSV
          </button>
        </div>
      </div> */}
      <div className="responsive-table">
        <table className="table">
          <thead>
            <tr>
              <th>ID</th>
              <th>Driver Id</th>
              <th>Profile Image</th>
              <th>User Name</th>
              <th>Mobile Number</th>
              <th>Email</th>
              <th>Residence Address</th>
              <th>Driving License</th>
              <th>Insurance Certificate</th>
              <th>Safety Documents</th>
              <th>Vehicle Pictures</th>
              <th>Vehicle Details</th>
              <th>Vehicle Type</th>
              <th>Vehicle Company Name</th>
              <th>Vehicle Model Name</th>
              <th>Plate Number</th>
              <th>VIN Number</th>
              <th>Vehicle Color</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {!isLoad ? (
              <tr>
                <td colSpan={10}>
                  <div
                    className="d-flex justify-content-center align-items-center  "
                    style={{ height: "10vh" }}
                  >
                    <div class="loader"></div>
                  </div>
                </td>
              </tr>
            ) : !Drivers?.length ? (
              <tr>
                <td colSpan={10}>
                  <div>
                    <div
                      className="d-flex   flex-column  justify-content-center align-items-center  "
                      style={{ height: "15vh" }}
                    >
                      <img src={NotFound} style={{ height: "100px" }} alt="" />
                      <div>Driver Request not found !</div>
                    </div>
                  </div>
                </td>
              </tr>
            ) : (
              Drivers?.map((item, index) => {
                return (
                  <tr>
                    <td>
                      {currentPage == 1
                        ? index + 1
                        : index + 1 + currentPage * 10 - 10}
                    </td>
                    <td>{item?._id}</td>
                    <td>
                      <div className="profile-img">
                        <img
                          src={item?.profileUrl}
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.src = user;
                          }}
                          className="img-fluid"
                        />
                      </div>
                    </td>
                    <td>{item?.username}</td>
                    <td>{item?.mobilenumber}</td>
                    <td>{item?.email}</td>
                    <td>{item?.Residence_Address}</td>
                    <td>
                      <div className="profile-img">
                        <img
                          src={item?.drivingLicenseUrl}
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.src = user;
                          }}
                        />
                      </div>
                    </td>
                    <td>
                      <div className="profile-img">
                        <img
                          src={item?.insuranceCertificateUrl}
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.src = user;
                          }}
                        />
                      </div>
                    </td>
                    <td>
                      <div className="profile-img">
                        <img
                          src={item?.safetyDocumentsUrl}
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.src = user;
                          }}
                        />
                      </div>
                    </td>
                    <td>
                      <div className="profile-img">
                        <img
                          src={item?.vehicle_pictures1_Url}
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.src = user;
                          }}
                        />
                        <img
                          src={item?.vehicle_pictures2_Url}
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.src = user;
                          }}
                        />
                        <img
                          src={item?.vehicle_pictures3_Url}
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.src = user;
                          }}
                        />
                        <img
                          src={item?.vehicle_pictures4_Url}
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.src = user;
                          }}
                        />
                      </div>
                    </td>
                    <td>{item?.Vehicle_Details}</td>
                    <td>{item?.Vehicle_type}</td>
                    <td>{item?.Company_name}</td>
                    <td>{item?.Model_name}</td>
                    <td>{item?.Plate_number}</td>

                    <td>{item?.VINumber}</td>
                    <td>{item?.Vehicle_Color}</td>
                    <td>
                      <div className="mb-2">
                        <Link
                          onClick={() =>
                            handleChangeStatus({
                              user_id: item?._id,
                              type: "approve",
                            })
                          }
                          class="btn btn-success btn-sm me-1"
                        >
                          Approve
                        </Link>
                        <Link
                          onClick={() =>
                            handleChangeStatus({
                              user_id: item?._id,
                              type: "reject",
                            })
                          }
                          class="btn btn-danger btn-sm me-1"
                        >
                          Reject
                        </Link>
                      </div>
                    </td>
                  </tr>
                );
              })
            )}
          </tbody>
        </table>
      </div>
      <Paggination
        nPage={nPage}
        currentPage={currentPage}
        handleChangePage={handleChangePage}
      />
    </div>
  );
};

export default DriverRequest;
