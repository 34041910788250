import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Sidebar from "./Sidebar";
import Footer from "./Footer";
import UpdateModel from "../common/banner/UpdateModel";
import AddBanner from "../common/banner/AddBanner";
import {
  ChangeBannerStatusApi,
  DeleteBannerApi,
  ShowBannerApi,
} from "../helper/integration";
import BannerList from "../common/banner/BannerList";
import Paggination from "../common/Paggination";
import toast from "react-hot-toast";
import NotFound from "../Image/notfound.png";
import bootstrap from "bootstrap/dist/js/bootstrap.bundle.min";

const VehicleTypes = () => {
  const [AllBanner, setAllBanner] = useState([]);
  const [Banners, setBanners] = useState([]);
  const [SignleData, setSignleData] = useState(null);
  const [isLoad, setisLoad] = useState(false);
  const [isUpdate, setisUpdate] = useState(false);
  const [currentPage, setcurrentPage] = useState(1);
  const [isDisble, setisDisble] = useState(false);
  const [recordsPerPage] = useState(10);
  const [nPage, setnPage] = useState([1]);
  const [itemIdToDelete, setItemIdToDelete] = useState(null);
  const handleChangePage = (id) => {
    setcurrentPage(id);
    const indexOfLastRecord = id * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    let currentRecords = AllBanner.slice(indexOfFirstRecord, indexOfLastRecord);
    setBanners(currentRecords);
  };

  const handleDeleteBanner = (id) => {
    setItemIdToDelete(id); // Set the item ID to delete
    const deleteModal = new bootstrap.Modal(
      document.getElementById("deleteConfirmationModal")
    );
    deleteModal.show();
  };
  const confirmDelete = async () => {
    if (itemIdToDelete) {
      try {
        await toast.promise(DeleteBannerApi({ user_id: itemIdToDelete }), {
          loading: "Wait Few Moment !",
          success: "Banner Delete Successfully !",
          error: "Something Wrong !",
        });
        setisUpdate(!isUpdate);
        setItemIdToDelete(null); // Reset item ID
      } catch (er) {}
    }
  };

  const handleSearch = (e) => {
    let val = e.target.value;
    let newArray = AllBanner.filter((item) => {
      return (
        item.title.toLowerCase().includes(val.toLowerCase()),
        item.types.toLowerCase().includes(val.toLowerCase())
      );
    });
    setcurrentPage(1);
    const indexOfLastRecord = 1 * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const currentRecords = newArray.slice(
      indexOfFirstRecord,
      indexOfLastRecord
    );
    setBanners(currentRecords);
    const nPages = Math.ceil(newArray?.length / recordsPerPage);
    const num = [...Array(nPages + 1).keys()].slice(1);
    setnPage(num);
  };

  const handleSetSingleData = (item) => setSignleData((prev) => item);

  const handleChangeStatus = async (obj) => {
    try {
      setisDisble(true);
      await ChangeBannerStatusApi(obj);
      setisDisble(false);
    } catch (er) {
      console.log(er);
    }
  };
  useEffect(() => {
    (async function () {
      try {
        const data = await ShowBannerApi();
        setAllBanner(data);
        let indexOfLastRecord = currentPage * recordsPerPage;
        let indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
        let currentRecords = data.slice(indexOfFirstRecord, indexOfLastRecord);
        setBanners(currentRecords);
        let nPages = Math.ceil(data?.length / recordsPerPage);
        let num = [...Array(nPages + 1).keys()].slice(1);
        setnPage(num);
      } catch (er) {
        console.log(er);
      } finally {
        setisLoad(true);
      }
    })();
  }, [isUpdate]);
  return (
    <div>
      {<Sidebar />}
      <div className="asside">
        <div className="about-first">
          <div className="row">
            <div className="col-12 mb-24">
              <div className="bg-box">
                <div className="pro-add-new px-0">
                  <p>PROMOTION BANNER</p>
                  <div className="form-floating small-floating">
                    <input
                      type="text"
                      className="form-control py-2 w-auto"
                      id="floatingInput"
                      placeholder="name@example.com"
                      onChange={handleSearch}
                    />
                    <label for="floatingInput">Search</label>
                  </div>
                </div>
                <div className="d-flex align-items-end justify-content-between">
                  <div></div>
                  <div className="mb-3 pro-add-new px-0">
                    <Link
                      type="button"
                      className="btn add-new"
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModalAdd"
                    >
                      <i className="fa-solid fa-plus"></i> Add Banner
                    </Link>
                  </div>
                </div>
                <div className="responsive-table">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>Name Of Promotion</th>
                        <th>Type</th>
                        <th>Banner Image</th>
                        <th>Change Status</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {!isLoad ? (
                        <tr>
                          <td colSpan={5}>
                            <div
                              className="d-flex  justify-content-center  align-items-center "
                              style={{ height: "10vh" }}
                            >
                              <div class="loader"></div>
                            </div>
                          </td>
                        </tr>
                      ) : Banners?.length ? (
                        Banners?.map((item, index) => {
                          return (
                            <BannerList
                              item={item}
                              isDisble={isDisble}
                              handleChangeStatus={handleChangeStatus}
                              handleDeleteBanner={handleDeleteBanner}
                              handleSetSingleData={handleSetSingleData}
                              index={
                                currentPage == 1
                                  ? index + 1
                                  : index + 1 + currentPage * 10 - 10
                              }
                            />
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan={5}>
                            <div
                              className="d-flex flex-column  justify-content-center mt-2 align-items-center  "
                              style={{ height: "15vh" }}
                            >
                              <img
                                src={NotFound}
                                style={{ height: "100px" }}
                                alt=""
                              />
                              <div>Banner not found !</div>
                            </div>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
                <Paggination
                  nPage={nPage}
                  currentPage={currentPage}
                  handleChangePage={handleChangePage}
                />
              </div>
            </div>
          </div>
        </div>
        {/* <!-- Edit Modal --> */}
        <UpdateModel
          item={SignleData}
          setItem={setSignleData}
          isUpdate={isUpdate}
          setisUpdate={setisUpdate}
        />
        {/* <!--End Edit Modal --> */}
        {/* <!-- Add Modal --> */}
        <AddBanner isUpdate={isUpdate} setisUpdate={setisUpdate} />
        {/* <!--End Add Modal --> */}

        {/* Delete Confirmation Modal */}
        <div
          className="modal fade"
          id="deleteConfirmationModal"
          tabIndex="-1"
          aria-labelledby="deleteConfirmationModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
            <div className="modal-content">
              <div className="modal-header bg-primary">
                <h5
                  className="modal-title text-white"
                  id="deleteConfirmationModalLabel"
                >
                  Confirm Deletion
                </h5>
                <button
                  type="button"
                  className="btn-close bg-white"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <h4 className="py-4 mx-auto">
                  Are you sure you want to delete this banner?
                </h4>
                <div className="d-flex justify-content-center pt-2">
                  <button
                    id="confirmDeleteButton"
                    className="btn btn-primary me-2"
                    data-bs-dismiss="modal"
                    onClick={confirmDelete}
                  >
                    Yes
                  </button>
                  <button className="btn btn-secondary" data-bs-dismiss="modal">
                    No
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {<Footer />}
      </div>
    </div>
  );
};

export default VehicleTypes;
