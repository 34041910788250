import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "../logo.svg";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { LoginApi } from "../helper/integration";
import { useCookies } from "react-cookie";
const Signin = () => {
  const { register, reset, handleSubmit } = useForm();
  const [cookie, setCookie, removeCookie] = useCookies(["admin"]);
  const [remember_me, setRemember_me] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const adminCookie = cookie.admin;

    if (adminCookie) {
      const expirationDate = new Date(adminCookie.expiry);
      if (expirationDate > new Date()) {
        navigate("/Dashboard");
      } else {
        removeCookie("admin");
      }
    }
  }, [cookie, navigate, remember_me, removeCookie]);

  const OnSubmit = (data) => {
    data.remember_me = remember_me;
    //alert(remember_me);
    console.log(data);
    toast
      .promise(LoginApi(data), {
        loading: "Wait Few Moment !",
        success: "Login Successfully !",
        error: "Email or Password is wrong !",
      })
      .then((val) => {
        //const rememberMe = data.remember_me;
        const expirationDate = new Date();

        if (data.remember_me) {
          //expirationDate.setHours(expirationDate.getHours() + 240); //240 = to 10 days
          expirationDate.setDate(expirationDate.getDate() + 10); // 10 days
        } else {
          expirationDate.setMinutes(expirationDate.getMinutes() + 30);
          // navigate("/");
        }

        const adminData = {
          _id: val?._id,
          expiry: expirationDate.toISOString(),
        };

        setCookie("admin", adminData, { expires: expirationDate });
        navigate("/Dashboard");
      })
      .catch(() => {});
  };

  const handleRememberMeChange = (e) => {
    setRemember_me(e.target.checked);
    if (!e.target.checked) {
      removeCookie("admin");
    }
  };
  return (
    <div>
      <div className="container">
        <div className="row vh-100 d-flex align-items-center justify-content-center">
          <div className="col-lg-6 col-md-8 col-12 my-3">
            <div className="login-top">
              <div className="row align-items-center">
                <div className="col-8 top-login-text">
                  <h2>Welcome to Rider App ! 👋</h2>
                  <p>Please sign-in to your account and start the adventure</p>
                </div>
                <div className="col-4">
                  <img src={logo} className="img-fluid" />
                </div>
              </div>
            </div>
            <div className="signin-page">
              <form onSubmit={handleSubmit(OnSubmit)}>
                <div className="form-floating mb-4">
                  <input
                    type="name"
                    className="form-control"
                    id="floatingInput"
                    placeholder="name@example.com"
                    {...register("email", { required: true })}
                  />
                  <label for="floatingInput">Email</label>
                </div>
                <div className="form-floating">
                  <input
                    type="password"
                    className="form-control"
                    id="floatingPassword"
                    {...register("password", { required: true })}
                    placeholder="Password"
                  />
                  <label for="floatingPassword">Password</label>
                </div>
                <div className="d-flex justify-content-between align-content-center my-3">
                  <div className="form-check remember-me">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="gridCheck"
                      {...register("remember_me", { required: false })}
                      onChange={handleRememberMeChange}
                    />
                    <label className="form-check-label" for="gridCheck">
                      Remember me
                    </label>
                  </div>
                  <Link to={"EmailVerification"} className="forgot-pass">
                    Forgot Password?
                  </Link>
                </div>
                <button type="submit" className="btn btn-login">
                  Sign in
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signin;

// import React, { useEffect, useState } from "react";
// import { Link, useNavigate } from "react-router-dom";
// import logo from "../logo.svg";
// import { useForm } from "react-hook-form";
// import toast from "react-hot-toast";
// import { LoginApi } from "../helper/integration";
// import { useCookies } from "react-cookie";
// const Signin = () => {
//   const { register, reset, handleSubmit } = useForm();
//   const [cookie, setCookie, removeCookie] = useCookies(["admin"]);
//   const [remember_me, setRemember_me] = useState(false);
//   const navigate = useNavigate();

//   useEffect(() => {
//     const adminCookie = cookie.admin;

//     if (adminCookie && remember_me) {
//       navigate("/Dashboard");
//     } else {
//       navigate("/");
//     }
//   }, [cookie, navigate, remember_me]);

//   const OnSubmit = (data) => {
//     data.remember_me = remember_me;
//     //alert(remember_me);
//     console.log(data);
//     toast
//       .promise(LoginApi(data), {
//         loading: "Wait Few Moment !",
//         success: "Login Successfully !",
//         error: "Email or Password is wrong !",
//       })
//       .then((val) => {
//         const rememberMe = data.remember_me;
//         const expirationDate = new Date();

//         if (rememberMe) {
//           //expirationDate.setHours(expirationDate.getHours() + 240); //240 = to 10 days
//           expirationDate.setDate(expirationDate.getDate() + 10); // 10 days
//         } else {
//           expirationDate.setMinutes(expirationDate.getMinutes() + 30);
//           // navigate("/");
//         }

//         setCookie("admin", val?._id, { expires: expirationDate });
//         navigate("/Dashboard");
//       })
//       .catch(() => {});
//   };

//   const handleRememberMeChange = (e) => {
//     setRemember_me(e.target.checked);
//     if (!e.target.checked) {
//       removeCookie("admin");
//     }
//   };
//   return (
//     <div>
//       <div className="container">
//         <div className="row vh-100 d-flex align-items-center justify-content-center">
//           <div className="col-lg-6 col-md-8 col-12 my-3">
//             <div className="login-top">
//               <div className="row align-items-center">
//                 <div className="col-8 top-login-text">
//                   <h2>Welcome to Rider App ! 👋</h2>
//                   <p>Please sign-in to your account and start the adventure</p>
//                 </div>
//                 <div className="col-4">
//                   <img src={logo} className="img-fluid" />
//                 </div>
//               </div>
//             </div>
//             <div className="signin-page">
//               <form onSubmit={handleSubmit(OnSubmit)}>
//                 <div className="form-floating mb-4">
//                   <input
//                     type="email"
//                     className="form-control"
//                     id="floatingInput"
//                     placeholder="name@example.com"
//                     {...register("email", { required: true })}
//                   />
//                   <label for="floatingInput">Email</label>
//                 </div>
//                 <div className="form-floating">
//                   <input
//                     type="password"
//                     className="form-control"
//                     id="floatingPassword"
//                     {...register("password", { required: true })}
//                     placeholder="Password"
//                   />
//                   <label for="floatingPassword">Password</label>
//                 </div>
//                 <div className="d-flex justify-content-between align-content-center my-3">
//                   <div className="form-check remember-me">
//                     <input
//                       className="form-check-input"
//                       type="checkbox"
//                       id="gridCheck"
//                       {...register("remember_me", { required: false })}
//                       onChange={handleRememberMeChange}
//                     />
//                     <label className="form-check-label" for="gridCheck">
//                       Remember me
//                     </label>
//                   </div>
//                   <Link to={"EmailVerification"} className="forgot-pass">
//                     Forgot Password?
//                   </Link>
//                 </div>
//                 <button type="submit" className="btn btn-login">
//                   Sign in
//                 </button>
//               </form>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Signin;

// import { useCookies } from "react-cookie";

// const Signin = () => {
//   const { register, reset, handleSubmit } = useForm();
//   const [cookie, setCookie, removeCookie] = useCookies(["admin"]);
//   const [remember_me, setRemember_me] = useState(false);
//   const navigate = useNavigate();

//   useEffect(() => {
//     const adminCookie = cookie.admin;
//     if (adminCookie && remember_me) {
//       navigate("/Dashboard");
//     } else {
//       navigate("/");
//     }
//   }, [cookie, navigate, remember_me]);

//   const OnSubmit = (data) => {
//     data.remember_me = remember_me;
//     console.log(data);
//     toast
//       .promise(LoginApi(data), {
//         loading: "Wait Few Moment !",
//         success: "Login Successfully !",
//         error: "Email or Password is wrong !",
//       })
//       .then((val) => {
//         const rememberMe = data.remember_me;
//         const expirationDate = new Date();

//         if (rememberMe) {
//           expirationDate.setDate(expirationDate.getDate() + 10); // 10 days
//         } else {
//           expirationDate.setMinutes(expirationDate.getMinutes() + 30);
//         }

//         setCookie("admin", val?._id, { expires: expirationDate });
//         navigate("/Dashboard");
//       })
//       .catch(() => {});
//   };

//   const handleRememberMeChange = (e) => {
//     setRemember_me(e.target.checked);
//     if (!e.target.checked) {
//       removeCookie("admin");
//     }
//   };
