import React, { useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { POSTAPI } from "../../helper/integration";
import { Api } from "../../utils/Api";
const UpdateModal = ({ update, item }) => {
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [hourlyRideSupported, setHourlyRideSupported] = useState(
    item?.hourly_ride || false
  );
  const [Image, setImage] = useState(null);

  const handleAddData = (data) => {
    let newForm = new FormData();
    newForm.append("id", item?._id);
    newForm.append("type", data.type || item?.type);
    newForm.append("vehicle_name", data.name || item?.vehicle_name);
    newForm.append("number_of_sit", data.no_of_sit || item?.number_of_sit);
    newForm.append("base_price", data.base_price || item?.base_price);
    newForm.append("price_per_km", data.price_per_km || item?.price_per_km);
    newForm.append(
      "price_per_minute",
      data.price_per_min || item?.price_per_minute
    );
    newForm.append(
      "driver_commission",
      data.driver_commission || item?.driver_commission
    );
    newForm.append("detail", data.detail || item?.detail);
    newForm.append("sub_detail", data.sub_detail || item?.sub_detail);
    newForm.append("pool_ride", data.pool_ride || item?.pool_ride);
    newForm.append("hourly_ride", hourlyRideSupported);
    newForm.append(
      "per_hour_charge",
      data.per_hour_charge || item?.per_hour_charge
    );
    if (Image) newForm.append("image", Image);
    console.log(Image);
    toast
      .promise(POSTAPI(Api.UpdateVehicle, newForm), {
        loading: "Wait Few Moment !",
        success: "Update  SuccessFully !",
        error: "Something Wrong !",
      })
      .then(() => {
        update();
      })
      .catch((er) => {});
    document.getElementById("closeAddVehicle").click();
    reset();
  };

  const handleHourlyRideChange = (event) => {
    setHourlyRideSupported(event.target.value === "option3");
  };
  return (
    <div
      className="modal fade"
      id="exampleModaledit"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              Update Vehicle Details
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <div className="user-details">
              <form className="row" onSubmit={handleSubmit(handleAddData)}>
                <div className="mb-3 col-md-6 col-12">
                  <label className="mb-2">Service Type</label>
                  <select
                    className="form-select form-control"
                    id="floatingSelectGrid"
                    aria-label="Floating label select example"
                    {...register("type")}
                  >
                    <option value="ride" selected={item?.type == "ride"}>
                      Ride
                    </option>
                    <option value="courier" selected={item?.type == "courier"}>
                      Courier
                    </option>
                  </select>
                </div>
                <div className="mb-3 col-md-6 col-12">
                  <label className="mb-2">Name Of Vehicle Type</label>
                  <input
                    type="text"
                    className="form-control"
                    defaultValue={item?.vehicle_name}
                    {...register("name")}
                  />
                </div>
                <div className="mb-3 col-md-6 col-12">
                  <label className="mb-2">Vehicle Image </label>
                  <input
                    type="file"
                    className="form-control"
                    multiple
                    onChange={(e) => setImage(e.target.files[0])}
                  />
                </div>

                <div className="mb-3 col-md-6 col-12">
                  <label className="mb-2">No Of Sit</label>
                  <input
                    type="number"
                    className="form-control"
                    defaultValue={item?.number_of_sit}
                    {...register("no_of_sit")}
                  />
                </div>
                <div className="mb-3 col-md-6 col-12">
                  <label className="mb-2">Base Price</label>
                  <input
                    type="number"
                    className="form-control"
                    defaultValue={item?.base_price}
                    {...register("base_price")}
                  />
                </div>
                <div className="mb-3 col-md-6 col-12">
                  <label className="mb-2">Price (Per KM)</label>
                  <input
                    type="number"
                    className="form-control"
                    defaultValue={item?.price_per_km}
                    {...register("price_per_km")}
                  />
                </div>
                <div className="mb-3 col-md-6 col-12">
                  <label className="mb-2">Price (Per Minute)</label>
                  <input
                    type="number"
                    className="form-control"
                    defaultValue={item?.price_per_minute}
                    {...register("price_per_min")}
                  />
                </div>
                <div className="mb-3 col-md-6 col-12">
                  <label className="mb-2">Driver Commission (In %)</label>
                  <input
                    type="number"
                    className="form-control"
                    defaultValue={item?.driver_commission}
                    {...register("driver_commission")}
                  />
                </div>
                <div className="mb-3 col-md-6 col-12">
                  <label className="mb-2">Features</label>
                  <input
                    type="text"
                    className="form-control"
                    defaultValue={item?.detail}
                    {...register("detail")}
                  />
                </div>
                <div className="mb-3 col-md-6 col-12">
                  <label className="mb-2">Vehicle Information</label>
                  <input
                    type="text"
                    defaultValue={item?.sub_detail}
                    className="form-control"
                    {...register("sub_detail")}
                  />
                </div>
                <div className="mb-3 col-md-6 col-12">
                  <label className="mb-2">Pool ride supported ?</label>
                  <div>
                    {item?.pool_ride ? (
                      <>
                        <div class="form-check form-check-inline">
                          <input
                            class="form-check-input"
                            type="radio"
                            name="inlineRadioOptions"
                            id="inlineRadio1"
                            checked={true}
                            value={true}
                            {...register("pool_ride")}
                          />
                          <label class="form-check-label" for="inlineRadio1">
                            Yes
                          </label>
                        </div>
                        <div class="form-check form-check-inline">
                          <input
                            class="form-check-input"
                            type="radio"
                            name="inlineRadioOptions"
                            id="inlineRadio2"
                            value={false}
                            {...register("pool_ride")}
                          />
                          <label class="form-check-label" for="inlineRadio2">
                            No
                          </label>
                        </div>
                      </>
                    ) : (
                      <>
                        <div class="form-check form-check-inline">
                          <input
                            class="form-check-input"
                            type="radio"
                            name="inlineRadioOptions"
                            id="inlineRadio1"
                            value={true}
                            {...register("pool_ride")}
                          />
                          <label class="form-check-label" for="inlineRadio1">
                            Yes
                          </label>
                        </div>
                        <div class="form-check form-check-inline">
                          <input
                            class="form-check-input"
                            type="radio"
                            name="inlineRadioOptions"
                            id="inlineRadio2"
                            value={false}
                            checked={true}
                            {...register("pool_ride")}
                          />
                          <label class="form-check-label" for="inlineRadio2">
                            No
                          </label>
                        </div>
                      </>
                    )}
                  </div>
                </div>
                <div className="mb-3 col-md-6 col-12">
                  <label className="mb-2">Hourly ride supported ?</label>
                  <div>
                    <div class="form-check form-check-inline">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="HourlyinlineRadioOptions"
                        id="inlineRadio3"
                        value="option3"
                        checked={hourlyRideSupported}
                        onChange={handleHourlyRideChange}
                      />
                      <label class="form-check-label" for="inlineRadio3">
                        Yes
                      </label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="HourlyinlineRadioOptions"
                        id="inlineRadio4"
                        value="option4"
                        checked={!hourlyRideSupported}
                        onChange={handleHourlyRideChange}
                      />
                      <label class="form-check-label" for="inlineRadio4">
                        No
                      </label>
                    </div>
                  </div>
                </div>
                {hourlyRideSupported && (
                  <div className="mb-3 col-md-6 col-12">
                    <label className="mb-2"> Per hour charge</label>
                    <input
                      type="number"
                      className="form-control"
                      defaultValue={item?.per_hour_charge}
                      {...register("per_hour_charge")}
                    />
                  </div>
                )}
                <div className="upload-reset-btn mb-0 justify-content-center pt-2">
                  <button
                    type="button"
                    className="btn btn-reset"
                    data-bs-dismiss="modal"
                    id="closeAddVehicle"
                  >
                    Cancel
                  </button>
                  <button type="submit" className="btn btn-upload me-0">
                    Save
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateModal;
