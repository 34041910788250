import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Sidebar from "./Sidebar";
import Footer from "./Footer";
import user from "../Image/user.png";
import AddModal from "../common/vehicle/AddModal";
import { POSTAPI } from "../helper/integration";
import { Api } from "../utils/Api";
import Pagination from "../common/Paggination";
import NotFound from "../Image/notfound.png";
import UpdateModal from "../common/vehicle/UpdateModal";
import toast from "react-hot-toast";

function convertDateFormat(dateString) {
  const date = new Date(dateString);
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear();
  let hours = date.getHours();
  const minutes = date.getMinutes().toString().padStart(2, "0");
  const ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12; // Handle midnight (0 hours)
  const time = hours.toString().padStart(2, "0") + ":" + minutes + " " + ampm;
  return `${day}-${month}-${year} ${time}`;
}

const VehicleTypes = () => {
  const [AllVehicle, setAllVehicle] = useState([]);
  const [Vehicles, setVehicles] = useState([]);
  const [SignleData, setSignleData] = useState(null);
  const [isLoad, setisLoad] = useState(false);
  const [isUpdate, setisUpdate] = useState(false);
  const [currentPage, setcurrentPage] = useState(1);
  const [isDisble, setisDisble] = useState(false);
  const [recordsPerPage] = useState(10);
  const [nPage, setnPage] = useState([1]);
  const [vehicleIdToDelete, setVehicleIdToDelete] = useState(null);

  const handleChangePage = (id) => {
    setcurrentPage(id);
    const indexOfLastRecord = id * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    let currentRecords = AllVehicle.slice(
      indexOfFirstRecord,
      indexOfLastRecord
    );
    setVehicles(currentRecords);
  };
  const handleUpdate = () => setisUpdate(!isUpdate);

  const handleDeleteVehicle = async () => {
    if (vehicleIdToDelete) {
      try {
        await toast.promise(
          POSTAPI(Api.DeleteVehicle, { id: vehicleIdToDelete }),
          {
            loading: "Wait Few Moment !",
            success: "Vehicle Delete Successfully !",
            error: "Something Wrong !",
          }
        );
        setisUpdate(!isUpdate);
        setVehicleIdToDelete(null); // Clear the ID after deletion
      } catch (er) {}
    }
  };

  const handleSearch = (e) => {
    let val = e.target.value;
    let newArray = AllVehicle.filter(
      (item) =>
        item.type.toLowerCase().includes(val.toLowerCase()) ||
        item.vehicle_name.toLowerCase().includes(val.toLowerCase())
    );
    setcurrentPage(1);
    const indexOfLastRecord = 1 * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const currentRecords = newArray.slice(
      indexOfFirstRecord,
      indexOfLastRecord
    );
    setVehicles(currentRecords);
    const nPages = Math.ceil(newArray?.length / recordsPerPage);
    const num = [...Array(nPages + 1).keys()].slice(1);
    setnPage(num);
  };

  const handleSetSingleData = (item) => setSignleData((prev) => item);

  const handleChangeStatus = async (obj) => {};
  useEffect(() => {
    (async function () {
      try {
        const data = await POSTAPI(Api.ShowVehicle);
        setAllVehicle(data.data);
        let indexOfLastRecord = currentPage * recordsPerPage;
        let indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
        let currentRecords = data.data.slice(
          indexOfFirstRecord,
          indexOfLastRecord
        );
        setVehicles(currentRecords);
        let nPages = Math.ceil(data.data?.length / recordsPerPage);
        let num = [...Array(nPages + 1).keys()].slice(1);
        setnPage(num);
      } catch (er) {
        console.log(er);
      } finally {
        setisLoad(true);
      }
    })();
  }, [isUpdate]);
  return (
    <div>
      {<Sidebar />}
      <div className="asside">
        <div className="about-first">
          <div className="row">
            <div className="col-12 mb-24">
              <div className="bg-box">
                <div className="pro-add-new px-0">
                  <p>VEHICLE TYPES</p>
                  <div className="form-floating small-floating">
                    <input
                      type="text"
                      className="form-control py-2 w-auto"
                      id="floatingInput"
                      placeholder="name@example.com"
                      onChange={handleSearch}
                    />
                    <label for="floatingInput">Search</label>
                  </div>
                </div>
                <div className="d-flex align-items-end justify-content-between">
                  <div></div>
                  <div className="mb-3 pro-add-new px-0">
                    <Link
                      type="button"
                      className="btn add-new"
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModalAdd"
                    >
                      <i className="fa-solid fa-plus"></i> Add Vehicle
                    </Link>
                  </div>
                </div>
                <div className="responsive-table">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>Service Type</th>
                        <th>Vehicle Image</th>
                        <th>Name Of Vehicle Type</th>
                        <th>No Of Sit</th>
                        <th>Best Price</th>
                        <th>Price (Per KM)</th>
                        <th>Price (Per Minute)</th>
                        <th>Driver Commission (In %)</th>
                        <th>Features</th>
                        <th>Vehicle Information</th>
                        <th>Pool ride supported</th>
                        <th>Hourly ride supported</th>
                        <th>Per hour charge</th>
                        <th>Created at</th>
                        {/* <th>Change Status</th> */}
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {!isLoad ? (
                        <tr>
                          <td colSpan={9}>
                            <div className="d-flex  justify-content-center  align-items-center ">
                              <div className="loader"></div>
                            </div>
                          </td>
                        </tr>
                      ) : !Vehicles.length ? (
                        <tr>
                          <td colSpan={9}>
                            <div
                              className="d-flex flex-column  justify-content-center align-items-center mt-2  "
                              style={{ height: "15vh" }}
                            >
                              <img
                                src={NotFound}
                                style={{ height: "100px" }}
                                alt=""
                              />
                              <div> Driver Document not found !</div>
                            </div>
                          </td>
                        </tr>
                      ) : (
                        Vehicles.map((item, index) => (
                          <tr>
                            <td>
                              {" "}
                              {currentPage == 1
                                ? index + 1
                                : index + 1 + currentPage * 10 - 10}
                            </td>
                            <td className="text-capitalize ">{item?.type}</td>
                            <td>
                              <div className="profile-img">
                                <img
                                  src={item?.image}
                                  onError={(e) => (e.currentTarget.src = user)}
                                  className="img-fluid"
                                />
                              </div>
                            </td>
                            <td>{item?.vehicle_name}</td>
                            <td>{item?.number_of_sit || "-"}</td>
                            <td>{item?.base_price || "-"}</td>
                            <td>{item?.price_per_km || "-"}</td>
                            <td>{item?.price_per_minute || "-"}</td>
                            <td>{item?.driver_commission || "-"}</td>
                            <td>{item?.detail}</td>
                            <td>{item?.sub_detail}</td>
                            <td>{item?.pool_ride ? "Yes" : "No"}</td>
                            <td>{item?.hourly_ride ? "Yes" : "No"}</td>
                            <td>
                              {item?.per_hour_charge
                                ? item?.per_hour_charge
                                : "-"}
                            </td>
                            <td>
                              {item?.createdAt
                                ? convertDateFormat(item?.createdAt)
                                : "-"}
                            </td>
                            {/* <td>
                              <div
                                class="btn-group"
                                role="group"
                                aria-label="Basic radio toggle button group"
                              >
                                <input
                                  type="radio"
                                  class="btn-check btn-active"
                                  name="btnradio"
                                  id="btnradio1"
                                  autocomplete="off"
                                />
                                <label
                                  class="btn btn-outline-success btn-sm"
                                  for="btnradio1"
                                >
                                  Active
                                </label>

                                <input
                                  type="radio"
                                  class="btn-check btn-inactive"
                                  name="btnradio"
                                  id="btnradio2"
                                  autocomplete="off"
                                />
                                <label
                                  class="btn btn-outline-danger btn-sm"
                                  for="btnradio2"
                                >
                                  Inactive
                                </label>
                              </div>
                            </td> */}
                            <td>
                              <div className="icon-up-del">
                                <Link
                                  onClick={() => setSignleData((prev) => item)}
                                  type="button"
                                  data-bs-toggle="modal"
                                  data-bs-target="#exampleModaledit"
                                >
                                  <i className="fa-solid fa-pen"></i>
                                </Link>
                                <Link
                                  // onClick={() => handleDeleteVehicle(item?._id)}
                                  onClick={() => {
                                    setVehicleIdToDelete(item?._id); // Set the vehicle ID to delete
                                  }}
                                  data-bs-toggle="modal"
                                  data-bs-target="#deleteConfirmationModal"
                                >
                                  <i className="fa-solid fa-trash"></i>
                                </Link>
                              </div>
                            </td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </table>
                </div>
                <Pagination
                  nPage={nPage}
                  currentPage={currentPage}
                  handleChangePage={handleChangePage}
                />
              </div>
            </div>
          </div>
        </div>

        {/* <!-- Edit Modal --> */}
        <UpdateModal update={handleUpdate} item={SignleData} />
        {/* <!--End Edit Modal --> */}

        {/* <!-- Add Modal --> */}
        <AddModal update={handleUpdate} />
        {/* <!--End Add Modal --> */}

        {/* Delete Confirmation Modal */}
        <div
          className="modal fade"
          id="deleteConfirmationModal"
          tabIndex="-1"
          aria-labelledby="deleteConfirmationModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
            <div className="modal-content">
              <div className="modal-header bg-primary">
                <h5
                  className="modal-title text-white"
                  id="deleteConfirmationModalLabel"
                >
                  Confirm Deletion
                </h5>
                <button
                  type="button"
                  className="btn-close bg-white"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <h4 className="py-4 mx-auto">
                  Are you sure you want to delete this vehicle?
                </h4>
                <div className="d-flex justify-content-center pt-2">
                  <button
                    id="confirmDeleteButton"
                    className="btn btn-primary me-2"
                    data-bs-dismiss="modal"
                    onClick={handleDeleteVehicle}
                  >
                    Yes
                  </button>
                  <button className="btn btn-secondary" data-bs-dismiss="modal">
                    No
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {<Footer />}
      </div>
    </div>
  );
};

export default VehicleTypes;
