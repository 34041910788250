import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Sidebar from "./Sidebar";
import Footer from "./Footer";
import { Api } from "../utils/Api";
import { RemoveSupport, ShowSupport } from "../helper/integration";
import toast from "react-hot-toast";
import Paggination from "../common/Paggination";
import NotFound from "../Image/notfound.png";

import Driver from "../common/otherSupport/Driver";
import User from "../common/otherSupport/User";

const OtherSupport = () => {
  const [MainData, setMainData] = useState([]);
  const [ListData, setListData] = useState([]);
  const [isUpdate, setisUpdate] = useState(false);
  const [isLoad, setisLoad] = useState(false);
  const [currentPage, setcurrentPage] = useState(1);
  const [recordsPerPage] = useState(10);
  const [nPage, setnPage] = useState([1]);

  // const handleSearch = (e) => {
  //   let val = e.target.value;
  //   let newArray = MainData?.filter(
  //     (item) =>
  //       item?.username?.toLowerCase()?.includes(val?.toLowerCase()) ||
  //       item?.mobilenumber?.toLowerCase()?.includes(val?.toLowerCase()) ||
  //       item?.email?.toLowerCase()?.includes(val?.toLowerCase()) ||
  //       item?.descriptions?.toLowerCase()?.includes(val?.toLowerCase())
  //   );

  //   setcurrentPage(1);
  //   const indexOfLastRecord = 1 * recordsPerPage;
  //   const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  //   const currentRecords = newArray.slice(
  //     indexOfFirstRecord,
  //     indexOfLastRecord
  //   );
  //   setListData(currentRecords);
  //   const nPages = Math.ceil(newArray?.length / recordsPerPage);
  //   const num = [...Array(nPages + 1).keys()].slice(1);
  //   setnPage(num);
  // };

  const handleDeleteSupport = (obj) => {
    toast
      .promise(RemoveSupport(Api.DeleteOther, obj), {
        loading: "Wait Few Moment !",
        success: "Other Delete Successfully !",
        error: "Something Wrong !",
      })
      .then(() => {
        setisUpdate(!isUpdate);
      })
      .catch(() => {});
  };
  // const handleChangePage = (id) => {
  //   setcurrentPage(id);
  //   const indexOfLastRecord = id * recordsPerPage;
  //   const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  //   let currentRecords = MainData.slice(indexOfFirstRecord, indexOfLastRecord);
  //   setListData(currentRecords);
  // };
  useEffect(() => {
    (async function () {
      try {
        const data = await ShowSupport(Api.ShowOther);
        setMainData(data);
        let indexOfLastRecord = currentPage * recordsPerPage;
        let indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
        let currentRecords = data?.slice(indexOfFirstRecord, indexOfLastRecord);
        setListData((prev) => currentRecords);
        let nPages = Math.ceil(data?.length / recordsPerPage);
        let num = [...Array(nPages + 1).keys()].slice(1);
        setnPage(num);
      } catch (er) {
        console.log(er);
      } finally {
        setisLoad(true);
      }
    })();
  }, [isUpdate]);
  return (
    <div>
      {<Sidebar />}
      <div className="asside">
        <div className="about-first">
          <div className="row">
            <div className="col-12 mb-24">
              <div className="bg-box">
                <nav className="mb-3">
                  <div class="nav nav-tabs" id="nav-tab" role="tablist">
                    <button
                      class="nav-link"
                      id="pills-profile-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-profile"
                      type="button"
                      role="tab"
                      aria-controls="pills-profile"
                      aria-selected="false"
                    >
                      {" "}
                      Driver{" "}
                    </button>
                    <button
                      class="nav-link active"
                      id="pills-home-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-home"
                      type="button"
                      role="tab"
                      aria-controls="pills-home"
                      aria-selected="true"
                    >
                      {" "}
                      User{" "}
                    </button>
                  </div>
                </nav>
                <div class="tab-content" id="pills-tabContent">
                  <div
                    className="tab-pane fade show active"
                    id="pills-home"
                    role="tabpanel"
                    aria-labelledby="pills-home-tab"
                  >
                    <User
                      isLoad={isLoad}
                      handleShow={ShowSupport}
                      handleRemove={handleDeleteSupport}
                      newLoad={isUpdate}
                    />
                  </div>
                  <div
                    className="tab-pane fade"
                    id="pills-profile"
                    role="tabpanel"
                    aria-labelledby="pills-profile-tab"
                  >
                    <Driver
                      isLoad={isLoad}
                      handleShow={ShowSupport}
                      handleRemove={handleDeleteSupport}
                      newLoad={isUpdate}
                    />
                  </div>
                </div>
                {/* <div className="responsive-table">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>Name</th>
                        <th>Mobile Number</th>
                        <th>Email ID</th>
                        <th>Subject</th>
                        <th>Description</th>
                        <th>Date & Time</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {!isLoad ? (
                        <tr>
                          <td colSpan={8}>
                            <div
                              className="d-flex justify-content-center align-items-center  "
                              style={{ height: "10vh" }}
                            >
                              <div class="loader"></div>
                            </div>
                          </td>
                        </tr>
                      ) : ListData?.length ? (
                        ListData?.map((item, index) => {
                          return (
                            <tr>
                              <td>
                                {currentPage == 1
                                  ? index + 1
                                  : index + 1 + currentPage * 10 - 10}
                              </td>
                              <td>{item?.username}</td>
                              <td>{item?.mobilenumber}</td>
                              <td>{item?.email}</td>
                              <td>{item?.subject ?? "-"}</td>
                              <td>{item?.descriptions ?? "-"}</td>
                              <td>{item?.createdAt ?? "-"}</td>
                              <td>
                                <div className="icon-up-del">
                                  <Link
                                    onClick={() =>
                                      handleDeleteSupport({ id: item?._id })
                                    }
                                  >
                                    <i className="fa-solid fa-trash"></i>
                                  </Link>
                                </div>
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan={8}>
                            <div
                              className="d-flex flex-column  mt-2 justify-content-center align-items-center  "
                              style={{ height: "15vh" }}
                            >
                              <img
                                src={NotFound}
                                style={{ height: "100px" }}
                                alt=""
                              />
                              <div>Other Support not found !</div>
                            </div>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div> */}
                {/* <Paggination
                  nPage={nPage}
                  currentPage={currentPage}
                  handleChangePage={handleChangePage}
                /> */}
              </div>
            </div>
          </div>
        </div>
        {<Footer />}
      </div>
    </div>
  );
};

export default OtherSupport;
