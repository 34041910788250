import React, { useRef, useEffect, useState } from "react";

const GoogleMap2 = ({
  current_latitude,
  current_longitude,
  onLocationChange,
}) => {
  const mapRef = useRef(null);
  const [marker, setMarker] = useState(null);

  useEffect(() => {
    const map = new window.google.maps.Map(mapRef.current, {
      center: { lat: current_latitude, lng: current_longitude },
      zoom: 15, // Zoom level closer to the location
      mapTypeControlOptions: false,
    });

    const initialMarker = new window.google.maps.Marker({
      position: { lat: current_latitude, lng: current_longitude },
      map,
    });
    setMarker(initialMarker);

    map.addListener("click", (event) => {
      const lat = event.latLng.lat();
      const lng = event.latLng.lng();

      // Update marker position
      initialMarker.setPosition({ lat, lng });

      // Get location name using Geocoding API
      const geocoder = new window.google.maps.Geocoder();
      geocoder.geocode({ location: { lat, lng } }, (results, status) => {
        if (status === "OK" && results[0]) {
          console.log("Location name:", results[0].formatted_address);
          const locationName = results[0].formatted_address;
          onLocationChange({ lat, lng, locationName });
        } else {
          console.error("Geocoder failed due to:", status);
        }
      });

      console.log("Latitude:", lat, "Longitude:", lng);
    });
  }, [current_latitude, current_latitude, onLocationChange]);

  return <div ref={mapRef} style={{ width: "100%", height: "400px" }} />;
};

export default GoogleMap2;
