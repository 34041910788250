import React, { useRef, useEffect } from "react";
import { FaCar } from "react-icons/fa6";
import { renderToString } from "react-dom/server";

const GoogleMap = ({ initialLat, initialLng, points }) => {
  const mapRef = useRef(null);
  let map;

  useEffect(() => {
    map = new window.google.maps.Map(mapRef.current, {
      center: { lat: initialLat, lng: initialLng },
      zoom: 5,
    });

    points?.map((element) => {
      let markerIcon = {
        url: `data:image/svg+xml;charset=UTF-8,${encodeURIComponent(
          renderToString(
            <FaCar
              title="Driver"
              color={`${element?.available == "busy" ? "#FF0000" : "#0FBB6B"}`}
            />
          )
        )}`, // Change the color here
        scaledSize: new window.google.maps.Size(40, 40), // Adjust the size if needed
      };
      const marker = new window.google.maps.Marker({
        position: { lat: Number(element.lat), lng: Number(element.lng) },
        map,
        icon: markerIcon,
      });

      const infoWindow = new window.google.maps.InfoWindow({
        content: `<div>${element.username}</div>`, // Display driver's name here
      });

      marker.addListener("click", () => {
        infoWindow.open(map, marker);
      });
    });
  }, [initialLat, initialLng, points]);

  return <div ref={mapRef} style={{ width: "100%", height: "400px" }} />;
};

export default GoogleMap;
