import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { UpdateDriverApi, POSTAPI } from "../../helper/integration";
import toast from "react-hot-toast";
import { Api } from "../../../src/utils/Api";

const UpdateDriver = ({
  item,
  SingleDriver,
  setisUpdate,
  isUpdate,
  setItem,
}) => {
  const { register, reset, handleSubmit, setValue } = useForm();
  const [profile, setprofile] = useState(null);
  const [license, setlicense] = useState(null);
  const [seftyDoc, setseftyDoc] = useState(null);
  const [insurance, setinsurance] = useState(null);

  useEffect(() => {
    if (item) {
      setprofile(null); // Clear profile state to avoid outdated values
      setlicense(null); // Clear license state to avoid outdated values
      setseftyDoc(null); // Clear safetyDoc state to avoid outdated values
      setinsurance(null); // Clear insurance state to avoid outdated values

      // Optionally reset form values (not needed if using controlled inputs)
      setValue("profile_image", item?.profile);
      setValue("driver_license", item?.driver_license);
      setValue("insurance", item?.insurance);
      setValue("safety", item?.safety);
    }
  }, [item, setValue]);
  const handleUpdateDriver = (data) => {
    if (item?._id) {
      let newData = new FormData();
      if (profile) newData.append("profile_image", profile);
      if (license) newData.append("driver_license", license);
      if (insurance) newData.append("insurance", insurance);
      if (seftyDoc) newData.append("safety", seftyDoc);
      //if (Vehicle_type) newData.append("Vehicle_type", Vehicle_type);

      newData.append("user_id", item?._id);

      //newData.append("email", data?.email || item?.email);
      newData.append("profile_image", data?.profile || item?.profile);
      newData.append(
        "driver_license",
        data?.driver_license || item?.driver_license
      );
      newData.append("insurance", data?.insurance || item?.insurance);
      newData.append("safety", data?.safety || item?.safety);

      setItem(null);
      toast
        .promise(UpdateDriverApi(newData), {
          loading: "Wait Few Moment !",
          success: "Driver updated Successfully !",
          error: "Something Wrong !",
        })
        .then(() => {
          reset();
          document.getElementById("closeUpdateModal").click();
          setisUpdate(!isUpdate);
        })
        .catch((error) => {
          reset();
          document.getElementById("closeUpdateModal").click();
          console.log(error);
        });
    }
  };

  return (
    <div
      className="modal fade"
      id="exampleModaledit"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              Edit Driver Document
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <div className="user-details">
              <form className="row" onSubmit={handleSubmit(handleUpdateDriver)}>
                <div className="mb-3 col-md-6 col-12">
                  <label className="mb-2">Profile Image</label>
                  <input
                    type="file"
                    className="form-control"
                    onChange={(e) => setprofile(e.target.files[0])}
                  />
                </div>

                <div className="mb-3 col-md-6 col-12">
                  <label className="mb-2">Driver Name</label>
                  <input
                    type="text"
                    className="form-control"
                    defaultValue={item?.username}
                    disabled
                    //{...register("username")}
                  />
                </div>
                <div className="mb-3 col-md-6 col-12">
                  <label className="mb-2">Driver Email</label>
                  <input
                    type="mail"
                    className="form-control"
                    defaultValue={item?.email}
                    disabled
                    //{...register("email")}
                  />
                </div>
                <div className="mb-3 col-md-6 col-12">
                  <label className="mb-2">Driving License</label>
                  <input
                    type="file"
                    className="form-control"
                    onChange={(e) => setlicense(e.target.files[0])}
                  />
                </div>
                <div className="mb-3 col-md-6 col-12">
                  <label className="mb-2">Insurance Certificate</label>
                  <input
                    type="file"
                    className="form-control"
                    onChange={(e) => setinsurance(e.target.files[0])}
                  />
                </div>
                <div className="mb-3 col-md-6 col-12">
                  <label className="mb-2">Safety Documents</label>
                  <input
                    type="file"
                    className="form-control"
                    onChange={(e) => setseftyDoc(e.target.files[0])}
                  />
                </div>

                <div className="upload-reset-btn mb-0 justify-content-center pt-2">
                  <button
                    className="btn btn-reset"
                    id="closeUpdateModal"
                    data-bs-dismiss="modal"
                  >
                    Cancel
                  </button>
                  <button className="btn btn-upload me-0" type="submit">
                    Save
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateDriver;
