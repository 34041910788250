import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Paggination from "../Paggination";
import NotFound from "../../Image/notfound.png";
import { CSVLink } from "react-csv";
import toast from "react-hot-toast";
import Papa from "papaparse";
import { POSTAPI, ShowBookingApi } from "../../helper/integration";
import { Api } from "../../utils/Api";
import UpdateModelCourier from "./UpdateModelCourier";

const CourierBooking = ({ isLoad, handleShow, handleRemove, newLoad }) => {
  const [AllData, setAllData] = useState([]);
  const [Load, setLoad] = useState(false);
  const [PageData, setPageData] = useState([]);
  const [currentPage, setcurrentPage] = useState(1);
  const [isUpdate, setisUpdate] = useState(false);
  const [MainFilter, setMainFilter] = useState([]);
  const [recordsPerPage] = useState(10);
  const [ImportCourier, setImportCourier] = useState([]);
  const [FilterData, setFilterData] = useState("");
  const [FilterPayment, setFilterPayment] = useState("");
  const [nPage, setnPage] = useState([1]);
  const [SingleBooking, setSingleBooking] = useState(null);

  const handleShowSingleData = (item) => setSingleBooking((prev) => item);

  const handleSearch = (e) => {
    let val = e.target.value;
    let MainData;
    if (FilterData && FilterPayment) {
      let newData =
        FilterData == "Complete"
          ? AllData?.filter(
              (item) =>
                item?.status == "Complete" &&
                item?.payment_type == FilterPayment
            )
          : AllData?.filter(
              (item) =>
                item?.status != "Complete" &&
                item?.payment_type == FilterPayment
            );
      MainData = newData;
    } else if (FilterData) {
      let newData =
        FilterData == "Complete"
          ? AllData?.filter((item) => item?.status == "Complete")
          : AllData?.filter((item) => item?.status != "Complete");
      MainData = newData;
    } else if (FilterPayment) {
      MainData = AllData?.filter((item) => item?.payment_type == FilterPayment);
    } else {
      MainData = AllData;
    }
    let newArray = MainData?.filter(
      (item) =>
        item?.UserID?.username.toLowerCase().includes(val.toLowerCase()) ||
        item?.Discount.toLowerCase().includes(val.toLowerCase()) ||
        item.drop_locations.toLowerCase().includes(val.toLowerCase()) ||
        item.pickup_locations.toLowerCase().includes(val.toLowerCase()) ||
        item?.DriverID?.username.toLowerCase().includes(val.toLowerCase())
    );
    setMainFilter(newArray);
    setcurrentPage(1);
    const indexOfLastRecord = 1 * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const currentRecords = newArray.slice(
      indexOfFirstRecord,
      indexOfLastRecord
    );
    setPageData(currentRecords);
    const nPages = Math.ceil(newArray?.length / recordsPerPage);
    const num = [...Array(nPages + 1).keys()].slice(1);
    if (!val) setMainFilter([]);
    setnPage(num);
  };
  const handleChangePage = (id) => {
    setcurrentPage(id);
    const indexOfLastRecord = id * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    let currentRecords;
    if (MainFilter?.length) {
      currentRecords = MainFilter.slice(indexOfFirstRecord, indexOfLastRecord);
    } else {
      if (FilterData && FilterPayment) {
        let newData =
          FilterData == "Complete"
            ? AllData?.filter(
                (item) =>
                  item?.status == "Complete" &&
                  item?.payment_type == FilterPayment
              )
            : AllData?.filter(
                (item) =>
                  item?.status != "Complete" &&
                  item?.payment_type == FilterPayment
              );
        currentRecords = newData.slice(indexOfFirstRecord, indexOfLastRecord);
      } else if (FilterData) {
        let newData =
          FilterData == "Complete"
            ? AllData?.filter((item) => item?.status == "Complete")
            : AllData?.filter((item) => item?.status != "Complete");
        currentRecords = newData.slice(indexOfFirstRecord, indexOfLastRecord);
      } else if (FilterPayment) {
        currentRecords = AllData?.filter(
          (item) => item?.payment_type == FilterPayment
        ).slice(indexOfFirstRecord, indexOfLastRecord);
      } else {
        currentRecords = AllData.slice(indexOfFirstRecord, indexOfLastRecord);
      }
    }
    // }
    setPageData(currentRecords);
  };

  const handleFilter = (e) => {
    const { value } = e.target;
    setMainFilter([]);
    setcurrentPage(1);
    let newData;
    if (value) {
      setFilterData(value);
      if (value == "Complete")
        newData = FilterPayment
          ? AllData?.filter(
              (item) =>
                item?.status == "Complete" &&
                item?.payment_type == FilterPayment
            )
          : AllData?.filter((item) => item?.status == "Complete");
      else
        newData = FilterPayment
          ? AllData?.filter(
              (item) =>
                item?.status != "Complete" &&
                item?.payment_type == FilterPayment
            )
          : AllData?.filter((item) => item?.status != "Complete");
    } else {
      setFilterData("");
      newData = FilterPayment
        ? AllData?.filter((item) => item?.payment_type == FilterPayment)
        : AllData;
    }
    let indexOfLastRecord = currentPage * recordsPerPage;
    let indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    let currentRecords = newData?.slice(indexOfFirstRecord, indexOfLastRecord);
    setPageData((prev) => currentRecords);
    let nPages = Math.ceil(newData?.length / recordsPerPage);
    let num = [...Array(nPages + 1).keys()]?.slice(1);
    setnPage(num);
  };

  const handleFilterWithPayment = (e) => {
    setMainFilter([]);
    const { value } = e.target;
    setcurrentPage(1);
    let newData;
    if (value) {
      setFilterPayment(value);
      if (FilterData) {
        newData =
          FilterData == "Complete"
            ? AllData?.filter(
                (item) =>
                  item?.payment_type == value && item?.status == "Complete"
              )
            : AllData?.filter(
                (item) =>
                  item?.payment_type == value && item?.status != "Complete"
              );
      } else newData = AllData?.filter((item) => item?.payment_type == value);
    } else {
      if (FilterData) {
        newData =
          FilterData == "Complete"
            ? AllData?.filter((item) => item?.status == "Complete")
            : AllData?.filter((item) => item?.status != "Complete");
      }
      setFilterPayment("");
      newData = AllData;
    }
    let indexOfLastRecord = currentPage * recordsPerPage;
    let indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    let currentRecords = newData?.slice(indexOfFirstRecord, indexOfLastRecord);
    setPageData((prev) => currentRecords);
    let nPages = Math.ceil(newData?.length / recordsPerPage);
    let num = [...Array(nPages + 1).keys()]?.slice(1);
    setnPage(num);
  };

  function convertDateFormat(dateString) {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    let hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12; // Handle midnight (0 hours)
    const time = hours.toString().padStart(2, "0") + ":" + minutes + " " + ampm;
    return `${day}-${month}-${year} ${time}`;
  }

  const handleCourierImport = (e) => {
    const file = e.target.files[0];
    console.log(file);
    if (file) {
      Papa.parse(file, {
        complete: function (result) {
          const headerRow = result.data[0];
          const rowsWithData = result.data.slice(1);
          const formattedRows = rowsWithData.map((row) => {
            const formattedRow = {};
            headerRow.forEach((header, index) => {
              formattedRow[header] = row[index];
            });
            return formattedRow;
          });

          setImportCourier((prev) => formattedRows);
          document.getElementById("CourierOpen").click();
        },
        header: false, // Assuming no header row in the CSV
      });
    }
    e.target.files = null;
  };

  useEffect(() => {
    (async () => {
      try {
        setLoad(false);
        const data = await ShowBookingApi();
        const itemData = data?.Courier;
        setAllData((prev) => itemData);
        let indexOfLastRecord = currentPage * recordsPerPage;
        let indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
        let currentRecords = itemData?.slice(
          indexOfFirstRecord,
          indexOfLastRecord
        );
        setPageData((prev) => currentRecords);
        let nPages = Math.ceil(itemData?.length / recordsPerPage);
        let num = [...Array(nPages + 1).keys()]?.slice(1);
        setnPage(num);
      } catch (er) {
      } finally {
        setLoad(true);
      }
    })();
  }, [newLoad, isUpdate]);

  const handleUpdateRequestSuccess = (updatedData) => {
    setPageData((prevRequests) =>
      prevRequests.map((request) =>
        request._id === updatedData._id ? updatedData : request
      )
    );
    setisUpdate((prev) => !prev);
  };
  return (
    <div
      class="tab-pane fade"
      id="pills-profile"
      role="tabpanel"
      aria-labelledby="pills-profile-tab"
    >
      <div className="pro-add-new px-0">
        <p>COURIER BOOKINGS</p>
        <div className="form-floating small-floating">
          <input
            type="text"
            className="form-control py-2 w-auto"
            id="floatingInput"
            placeholder="name@example.com"
            onChange={handleSearch}
          />
          <label for="floatingInput">Search</label>
        </div>
      </div>
      <div className="d-flex align-items-end justify-content-between">
        <div className="d-flex">
          <div className="mb-3 me-3">
            <label className="mb-2">Status Filter</label>
            <select
              className="form-select form-control"
              id=""
              aria-label="Floating label select example"
              onChange={handleFilter}
            >
              <option selected value="">
                Select Status
              </option>
              <option value="Complete">Completed</option>
              <option value="Pending">Pending</option>
            </select>
          </div>
          <div className="mb-3">
            <label className="mb-2">Payment Method Filter</label>
            <select
              className="form-select form-control"
              id=""
              aria-label="Floating label select example"
              onChange={handleFilterWithPayment}
            >
              <option selected value="">
                Select Payment{" "}
              </option>
              <option value="Cash Payment">Cash</option>
              <option value="Card">Card</option>
            </select>
          </div>
        </div>
        <div
          class="mb-3 btn-group"
          role="group"
          aria-label="Basic outlined example"
        >
          <CSVLink
            id="exportCourier"
            data={AllData}
            filename={"Courier.csv"}
            type="button"
            class="btn btn-outline-primary shadow-none"
            hidden
          >
            EXPORT CSV
          </CSVLink>
          <Link
            data-bs-toggle="modal"
            data-bs-target="#exportCourierDetail"
            type="button"
            class="btn btn-outline-primary shadow-none"
          >
            EXPORT CSV
          </Link>
          <Link
            id="CourierOpen"
            data-bs-toggle="modal"
            data-bs-target="#importCourierDetail"
            type="button"
            class="btn btn-outline-primary shadow-none"
            hidden
          >
            Import CSV
          </Link>
          <input
            type="file"
            id="importCourier"
            onChange={handleCourierImport}
            hidden
          />
          <label htmlFor="importCourier">
            <div class="btn btn-outline-primary shadow-none   ">IMPORT CSV</div>
          </label>
        </div>
      </div>
      <div className="responsive-table">
        <table className="table">
          <thead>
            <tr>
              <th>No</th>
              <th>Trip ID</th>
              <th>Customer Name</th>
              <th>Driver Name</th>
              <th>Pickup Location</th>
              <th>Dropoff Location</th>
              <th>Total Distance</th>
              <th>Promo Code (If applied)</th>
              <th>Payment Method</th>
              <th>Total Amount</th>
              <th>Created At</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {!isLoad ? (
              <tr>
                <td colSpan={9}>
                  <div
                    className="d-flex justify-content-center align-items-center  "
                    style={{ height: "10vh" }}
                  >
                    <div class="loader"></div>
                  </div>
                </td>
              </tr>
            ) : PageData?.length ? (
              PageData?.map((item, index) => {
                return (
                  <tr>
                    <td>
                      {currentPage == 1
                        ? index + 1
                        : index + 1 + currentPage * 10 - 10}
                    </td>
                    <td>{item?.RideId}</td>
                    <td>{item?.UserID?.username}</td>
                    <td>{item?.DriverID?.username}</td>
                    <td>{item?.pickup_locations}</td>
                    <td>{item?.drop_locations}</td>
                    <td>{item?.distance ?? "-"} </td>
                    <td>{item?.Discount || "-"}</td>
                    <td>{item?.payment_type}</td>
                    <td>{item?.TotalAmount}</td>
                    <td>
                      {item?.createdAt
                        ? convertDateFormat(item?.createdAt)
                        : "-"}
                    </td>
                    <td>
                      <div>
                        {item?.status == "Complete" ? (
                          <Link
                            to={"#"}
                            class="badge bg-success me-1 text-white"
                          >
                            Completed
                          </Link>
                        ) : (
                          <Link
                            to={"#"}
                            class="badge bg-warning me-1 text-white"
                          >
                            Pending
                          </Link>
                        )}
                      </div>
                    </td>
                    <td>
                      <div className="icon-up-del">
                        <Link
                          type="button"
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModaledit2"
                          onClick={() => handleShowSingleData(item)}
                        >
                          <i className="fa-solid fa-pen"></i>
                        </Link>
                        <Link
                          onClick={() => handleShow(item)}
                          type="button"
                          data-bs-toggle="modal"
                          data-bs-target="#viewexampleModaledit"
                        >
                          <i className="fa-solid fa-eye"></i>
                        </Link>
                        <Link onClick={() => handleRemove(item?._id)}>
                          <i className="fa-solid fa-trash"></i>
                        </Link>
                      </div>
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan={10}>
                  <div
                    className="d-flex flex-column  justify-content-center align-items-center  "
                    style={{ height: "15vh" }}
                  >
                    <img src={NotFound} style={{ height: "100px" }} alt="" />
                    <div>Courier Booking not found !</div>
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {/* Export  */}
      <div
        className="modal fade"
        id="exportCourierDetail"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Export Courier Details
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="user-details">
                <div>
                  <div>
                    <h4 className="py-4 mx-auto ">
                      Are sure to want export Texi Detail !
                    </h4>
                  </div>
                  <div className="upload-reset-btn mb-0 justify-content-center pt-2">
                    <button
                      id="closeEdit"
                      className="btn btn-reset"
                      data-bs-dismiss="modal"
                    >
                      No
                    </button>
                    <button
                      onClick={() => {
                        document.getElementById("exportCourier").click();
                      }}
                      data-bs-dismiss="modal"
                      className="btn btn-upload me-0"
                    >
                      Yes
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Import  */}
      <div
        className="modal fade"
        id="importCourierDetail"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Import Courier Details
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="user-details">
                <div>
                  <div>
                    <h4 className="py-4 mx-auto ">
                      Do You Want Add Duplicate Data ?{" "}
                    </h4>
                    <p>Total Data : {AllData.length}</p>
                    <p>
                      Duplicate Data :{" "}
                      {
                        ImportCourier?.filter((el) =>
                          AllData?.some((item) => item?._id == el?._id)
                        ).length
                      }
                    </p>
                    <p>
                      New Data :{" "}
                      {
                        ImportCourier?.filter(
                          (el) => !AllData?.some((item) => item?._id == el?._id)
                        ).length
                      }
                    </p>
                  </div>
                  <div className="upload-reset-btn mb-0 justify-content-center pt-2">
                    <button
                      onClick={() => {
                        let newData = ImportCourier?.filter(
                          (el) => !AllData?.some((item) => item?._id == el?._id)
                        );
                        if (!newData?.length)
                          return toast.error("Data Not Found !");
                        toast
                          .promise(
                            POSTAPI(Api.AddBookingCSV, {
                              data: newData?.map((item) => {
                                let { _id, ...newRow } = item;
                                return newRow;
                              }),
                            }),
                            {
                              loading: "Wait Few Moment !",
                              success: "Courier Add Successfully !",
                              error: "CSV Structure Not Match !",
                            }
                          )
                          .then(() => {
                            setisUpdate(!isUpdate);
                          })
                          .catch(() => {});
                      }}
                      className="btn btn-reset"
                      data-bs-dismiss="modal"
                    >
                      No
                    </button>
                    <button
                      onClick={() => {
                        toast
                          .promise(
                            POSTAPI(Api.AddBookingCSV, {
                              data: ImportCourier?.map((item) => {
                                let { _id, ...newRow } = item;
                                return newRow;
                              }),
                            }),
                            {
                              loading: "Wait Few Moment !",
                              success: "Courier Add Successfully !",
                              error: "CSV Structure Not Match !",
                            }
                          )
                          .then(() => {
                            setisUpdate(!isUpdate);
                          })
                          .catch(() => {});
                      }}
                      data-bs-dismiss="modal"
                      className="btn btn-upload me-0"
                    >
                      Yes
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <UpdateModelCourier
        item={SingleBooking}
        setItem={setSingleBooking}
        isUpdate={isUpdate}
        setisUpdate={setisUpdate}
        onUpdateSuccess={handleUpdateRequestSuccess}
      />

      <Paggination
        currentPage={currentPage}
        nPage={nPage}
        handleChangePage={handleChangePage}
      />
    </div>
  );
};

export default CourierBooking;
