import React from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { UpdateFAQApi } from "../../helper/integration";

const EditUserModel = ({ item, setItem, handleReferesh }) => {
  const { register, reset, handleSubmit } = useForm();
  const handleUpdateDriver = (data) => {
    if (item?._id) {
      try {
        let obj = {
          id: item?._id,
          question: data?.question || item?.question,
          answer: data?.answer || item?.answer,
        };
        toast
          .promise(UpdateFAQApi(obj), {
            loading: "Wait Few Moment !",
            success: "Update FAQ Successfully !",
            error: "Something Wrong !",
          })
          .then(() => {
            handleReferesh();
            setItem(null);
          });
      } catch (er) {
        console.log(er);
      } finally {
        document.getElementById("closeEditUser").click();
        reset();
      }
    }
  };
  return (
    <div
      className="modal fade"
      id="edituserfaq"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              Edit User FAQ
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <div className="user-details">
              <form className="row" onSubmit={handleSubmit(handleUpdateDriver)}>
                <div className="mb-3">
                  <label className="mb-2">Edit Question</label>
                  <input
                    type="text"
                    className="form-control"
                    defaultValue={item?.question}
                    {...register("question")}
                  />
                </div>
                <div className="mb-3">
                  <label className="mb-2">Edit Answer</label>
                  <textarea
                    class="form-control"
                    id="exampleFormControlTextarea1"
                    rows="3"
                    defaultValue={item?.answer}
                    {...register("answer")}
                  ></textarea>
                </div>
                <div className="upload-reset-btn mb-0 justify-content-center pt-2">
                  <button
                    className="btn btn-reset"
                    id="closeEditUser"
                    data-bs-dismiss="modal"
                    type="button"
                  >
                    Cancel
                  </button>
                  <button className="btn btn-upload me-0" type="submit">
                    Save
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditUserModel;
