import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Pagination from "../Paggination";
import AllList from "./AllList";
import { POSTAPI } from "../../helper/integration";
import { Api } from "../../utils/Api";
import toast from "react-hot-toast";
import NotFound from "../../Image/notfound.png";
import UpdateModel from "./UpdateModel";
import bootstrap from "bootstrap/dist/js/bootstrap.bundle.min";

const AllPromo = ({ handleUpdateData, isReload }) => {
  const [load, setload] = useState(false);
  const [AllData, setAllData] = useState([]);
  const [SingleData, setSingleData] = useState(null);
  const [MainData, setMainData] = useState([]);
  const [currentPage, setcurrentPage] = useState(1);
  const [isUpdate, setisUpdate] = useState(false);
  const [recordsPerPage] = useState(10);
  const [nPage, setnPage] = useState([1]);
  const [itemIdToDelete, setItemIdToDelete] = useState(null);

  const handleDelete = (id) => {
    setItemIdToDelete(id); // Set the item ID to delete
    const deleteModal = new bootstrap.Modal(
      document.getElementById("deleteConfirmationModal")
    );
    deleteModal.show();
  };

  const confirmDelete = () => {
    if (itemIdToDelete) {
      toast
        .promise(POSTAPI(Api.DeletePromoCode, { id: itemIdToDelete }), {
          loading: "Wait Few Moment !",
          success: "PromoCode Delete Successfully !",
          error: "Something Went Wrong !",
        })
        .then(() => {
          handleUpdateData();
          setItemIdToDelete(null);
        })
        .catch(() => {});
    }
  };

  const handleSearch = (e) => {
    let val = e.target.value;
    let newArray = AllData.filter(
      (item) =>
        item.name?.toLowerCase()?.includes(val.toLowerCase()) ||
        item.Code?.toString()?.toLowerCase()?.includes(val.toLowerCase())
    );
    setcurrentPage(1);
    const indexOfLastRecord = 1 * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const currentRecords = newArray.slice(
      indexOfFirstRecord,
      indexOfLastRecord
    );
    setMainData(currentRecords);
    const nPages = Math.ceil(newArray?.length / recordsPerPage);
    const num = [...Array(nPages + 1).keys()].slice(1);
    setnPage(num);
  };

  const handleStatusChange = (obj) => {
    toast
      .promise(POSTAPI(Api.ChangePromoCode, obj), {
        loading: "Wait Few Moment !",
        success: "Status Change Successfully !",
        error: "Somthing Wrong ",
      })
      .then(() => {
        setisUpdate(!isUpdate);
        handleUpdateData();
      })
      .catch(() => {});
  };
  const handleChangePage = (id) => {
    setcurrentPage(id);
    const indexOfLastRecord = id * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    let currentRecords = AllData.slice(indexOfFirstRecord, indexOfLastRecord);
    setMainData(currentRecords);
  };
  useEffect(() => {
    (async () => {
      try {
        setload(false);
        const data = await POSTAPI(Api.ShowPromoCode);
        setAllData((prev) => data.data);
        let indexOfLastRecord = currentPage * recordsPerPage;
        let indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
        let currentRecords = data.data.slice(
          indexOfFirstRecord,
          indexOfLastRecord
        );
        setMainData(currentRecords);
        let nPages = Math.ceil(data.data?.length / recordsPerPage);
        let num = [...Array(nPages + 1).keys()].slice(1);
        setnPage(num);
      } catch (er) {
      } finally {
        setload(true);
      }
    })();
  }, [isUpdate, isReload]);
  return (
    <div
      class="tab-pane fade show active"
      id="pills-home"
      role="tabpanel"
      aria-labelledby="pills-home-tab"
    >
      <div className="pro-add-new px-0">
        <p> ALL PROMO CODE </p>
        <div className="form-floating small-floating">
          <input
            type="text"
            className="form-control py-2 w-auto"
            id="floatingInput"
            placeholder="name@example.com"
            onChange={handleSearch}
          />
          <label for="floatingInput">Search</label>
        </div>
      </div>
      <div className="d-flex align-items-end justify-content-between">
        <div></div>
        <div className="mb-3 pro-add-new px-0">
          <Link
            type="button"
            className="btn add-new"
            data-bs-toggle="modal"
            data-bs-target="#exampleModalAdd"
          >
            <i className="fa-solid fa-plus"></i> Add Promo Code
          </Link>
        </div>
      </div>
      <div className="responsive-table">
        <table className="table">
          <thead>
            <tr>
              <th>ID</th>
              <th>Promo Code Name</th>
              <th>Promo Code</th>
              <th>Discount Type</th>
              <th>Discount</th>
              <th>Minimum</th>
              <th>Maximum</th>
              <th>Discription</th>
              <th>Start Date</th>
              <th>Expiry Date</th>
              <th>Change Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {!load ? (
              <tr>
                <td colSpan={12}>
                  <div className="d-flex justify-content-center  align-items-center ">
                    <div className="loader"></div>
                  </div>
                </td>
              </tr>
            ) : !MainData?.length ? (
              <tr>
                <td colSpan={12}>
                  <div className="d-flex justify-content-center flex-column  align-items-center ">
                    <img src={NotFound} className="w-25 h-25" alt="" />
                    <p>PromoCode Not Found !</p>
                  </div>
                </td>
              </tr>
            ) : (
              MainData?.map((item, index) => {
                return (
                  <AllList
                    item={item}
                    index={index}
                    currentPage={currentPage}
                    handleChangeStatus={handleStatusChange}
                    setItem={setSingleData}
                    handleDelete={handleDelete}
                  />
                );
              })
            )}
          </tbody>
        </table>
      </div>
      <Pagination
        nPage={nPage}
        currentPage={currentPage}
        handleChangePage={handleChangePage}
      />
      {/* Delete Confirmation Modal */}
      <div
        className="modal fade"
        id="deleteConfirmationModal"
        tabIndex="-1"
        aria-labelledby="deleteConfirmationModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header bg-primary">
              <h5
                className="modal-title text-white"
                id="deleteConfirmationModalLabel"
              >
                Confirm Deletion
              </h5>
              <button
                type="button"
                className="btn-close bg-white"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <h4 className="py-4 mx-auto">
                Are you sure you want to delete this promo code?
              </h4>
              <div className="d-flex justify-content-center pt-2">
                <button
                  id="confirmDeleteButton"
                  className="btn btn-primary me-2"
                  data-bs-dismiss="modal"
                  onClick={confirmDelete}
                >
                  Yes
                </button>
                <button className="btn btn-secondary" data-bs-dismiss="modal">
                  No
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <UpdateModel item={SingleData} update={handleUpdateData} />
    </div>
  );
};

export default AllPromo;
