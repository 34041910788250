import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "../logo.svg";
import { useCookies } from "react-cookie";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { ChangeAdminPasswordApi } from "../helper/integration";

const ChangePassword = () => {
  const [cookie] = useCookies(["admin"]);
  const navigate = useNavigate();
  const { register, reset, handleSubmit } = useForm();
  const handleChangePassword = (data) => {
    if (data?.newpassword !== data?.compassword)
      return toast.error("Password or Confirm Password Not Match !");
    let obj = {
      id: cookie.admin,
      ...data,
    };
    toast
      .promise(ChangeAdminPasswordApi(obj), {
        loading: "Wait Few Moment !",
        success: "Password Change Successfully !",
        error: "Current Password is wrong !",
      })
      .then(() => {
        reset();
        navigate("/Dashboard");
      })
      .catch(() => {});
  };
  useEffect(() => {
    if (!cookie.admin) window.location = "/";
  });
  return (
    <div>
      <div className="container">
        <div className="row vh-100 d-flex align-items-center justify-content-center">
          <div className="col-lg-6 col-md-8 col-12 my-3">
            <div className="login-top">
              <div className="row align-items-center">
                <div className="col-8 top-login-text">
                  <h2 className="mb-0">Change Password</h2>
                </div>
                <div className="col-4">
                  <img src={logo} className="img-fluid" />
                </div>
              </div>
            </div>
            <div className="signin-page">
              <form onSubmit={handleSubmit(handleChangePassword)}>
                <div className="form-floating mb-4">
                  <input
                    type="password"
                    className="form-control"
                    id="floatingInput"
                    placeholder="name@example.com"
                    {...register("oldpassword", { required: true })}
                  />
                  <label for="floatingInput">Current Password</label>
                </div>
                <div className="form-floating mb-4">
                  <input
                    type="password"
                    className="form-control"
                    id="floatingInput"
                    placeholder="name@example.com"
                    {...register("newpassword", { required: true })}
                  />
                  <label for="floatingInput">New Password</label>
                </div>
                <div className="form-floating mb-4">
                  <input
                    type="password"
                    className="form-control"
                    id="floatingPassword"
                    placeholder="Password"
                    {...register("compassword", { required: true })}
                  />
                  <label for="floatingPassword">Confirm New Password</label>
                </div>

                <button type="submit" className="btn btn-login">
                  Confirm
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
