import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { UpdateDriverApi, POSTAPI } from "../../helper/integration";
import toast from "react-hot-toast";
import { Api } from "../../../src/utils/Api";

const UpdateDriver = ({
  item,
  SingleDriver,
  setisUpdate,
  isUpdate,
  setItem,
}) => {
  const { register, reset, handleSubmit } = useForm();
  const [profile, setprofile] = useState(null);
  const [license, setlicense] = useState(null);
  const [seftyDoc, setseftyDoc] = useState(null);
  const [insurance, setinsurance] = useState(null);
  const [vehicle1, setvehicle1] = useState(null);
  const [vehicle2, setvehicle2] = useState(null);
  const [vehicle3, setvehicle3] = useState(null);
  const [vehicle4, setvehicle4] = useState(null);

  const [AllVehicle, setAllVehicle] = useState([]);
  const [Vehicle_type, setVehicle_type] = useState("");

  const handleUpdateDriver = (data) => {
    if (item?._id) {
      let newData = new FormData();
      if (profile) newData.append("profile_image", profile);
      if (license) newData.append("driver_license", license);
      if (insurance) newData.append("insurance", insurance);
      if (seftyDoc) newData.append("safety", seftyDoc);
      if (vehicle1) newData.append("vehicle_pictures1_Url", vehicle1);
      if (vehicle2) newData.append("vehicle_pictures2_Url", vehicle2);
      if (vehicle3) newData.append("vehicle_pictures3_Url", vehicle3);
      if (vehicle4) newData.append("vehicle_pictures4_Url", vehicle4);
      //if (Vehicle_type) newData.append("Vehicle_type", Vehicle_type);

      newData.append("user_id", item?._id);
      newData.append("username", data?.username || item?.username);
      newData.append("mobilenumber", data?.mobilenumber || item?.mobilenumber);
      newData.append("email", data?.email || item?.email);
      newData.append(
        "Residence_Address",
        data?.Residence_Address || item?.email
      );
      newData.append(
        "driver_license",
        data?.driver_license || item?.driver_license
      );
      newData.append("insurance", data?.insurance || item?.insurance);
      newData.append("safety", data?.safety || item?.safety);
      newData.append("vehicle_pictures1_Url", data?.vehicle_pictures1_Url);
      newData.append("vehicle_pictures2_Url", data?.vehicle_pictures2_Url);
      newData.append("vehicle_pictures3_Url", data?.vehicle_pictures3_Url);
      newData.append("vehicle_pictures4_Url", data?.vehicle_pictures4_Url);
      newData.append(
        "Vehicle_Details",
        data?.Vehicle_Details || item?.Vehicle_Details
      );
      newData.append("Vehicle_type", data?.Vehicle_type || item?.Vehicle_type);
      newData.append("Company_name", data?.Company_name || item?.Company_name);
      newData.append("Model_name", data?.Model_name || item?.Model_name);
      newData.append("Plate_number", data?.Plate_number || item?.Plate_number);
      newData.append("VINumber", data?.VINumber || item?.VINumber);
      newData.append(
        "Vehicle_Color",
        data?.Vehicle_Color || item?.Vehicle_Color
      );

      setItem(null);
      toast
        .promise(UpdateDriverApi(newData), {
          loading: "Wait Few Moment !",
          success: "Driver updated Successfully !",
          error: "Something Wrong !",
        })
        .then(() => {
          reset();
          document.getElementById("closeUpdateModal").click();
          setisUpdate(!isUpdate);
        })
        .catch(() => {
          reset();
          document.getElementById("closeUpdateModal").click();
        });
    }
  };

  useEffect(() => {
    (async function () {
      try {
        const data = await POSTAPI(Api.ShowVehicle);
        setAllVehicle(data.data);
        console.log(data.data);
        //setVehicles(currentRecords);
      } catch (er) {
        console.log(er);
      }
    })();
  }, []);

  const handleOptionChange = (event) => {
    setVehicle_type(event.target.value);
    console.log("Selected vehicle type:", event.target.value);
  };

  return (
    <div
      className="modal fade"
      id="exampleModaledit"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              Edit Driver
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <div className="user-details">
              <form className="row" onSubmit={handleSubmit(handleUpdateDriver)}>
                <div className="mb-3 col-md-6 col-12">
                  <label className="mb-2">Profile Image</label>
                  <input
                    type="file"
                    className="form-control"
                    onChange={(e) => setprofile(e.target.files[0])}
                  />
                </div>

                <div className="mb-3 col-md-6 col-12">
                  <label className="mb-2">User Name</label>
                  <input
                    type="text"
                    className="form-control"
                    defaultValue={item?.username}
                    {...register("username")}
                  />
                </div>
                <div className="mb-3 col-md-6 col-12">
                  <label className="mb-2">Mobile Number</label>
                  <input
                    type="text"
                    className="form-control"
                    maxLength={10}
                    defaultValue={item?.mobilenumber}
                    {...register("mobilenumber")}
                  />
                </div>
                <div className="mb-3 col-md-6 col-12">
                  <label className="mb-2">Email</label>
                  <input
                    type="mail"
                    className="form-control"
                    defaultValue={item?.email}
                    {...register("email")}
                  />
                </div>
                <div className="mb-3 col-md-6 col-12">
                  <label className="mb-2">Residence Address</label>
                  <input
                    type="text"
                    className="form-control"
                    defaultValue={item?.Residence_Address}
                    {...register("Residence_Address")}
                  />
                </div>
                <div className="mb-3 col-md-6 col-12">
                  <label className="mb-2">Driving License</label>
                  <input
                    type="file"
                    className="form-control"
                    onChange={(e) => setlicense(e.target.files[0])}
                  />
                </div>
                <div className="mb-3 col-md-6 col-12">
                  <label className="mb-2">Insurance Certificate</label>
                  <input
                    type="file"
                    className="form-control"
                    onChange={(e) => setinsurance(e.target.files[0])}
                  />
                </div>
                <div className="mb-3 col-md-6 col-12">
                  <label className="mb-2">Safety Documents</label>
                  <input
                    type="file"
                    className="form-control"
                    onChange={(e) => setseftyDoc(e.target.files[0])}
                  />
                </div>
                <div className="mb-3 col-md-6 col-12">
                  <label className="mb-2">Vehicle Pictures Front View</label>
                  <input
                    type="file"
                    className="form-control"
                    multiple
                    onChange={(e) => setvehicle1(e.target.files[0])}
                  />
                </div>
                <div className="mb-3 col-md-6 col-12">
                  <label className="mb-2">Vehicle Pictures Back View</label>
                  <input
                    type="file"
                    className="form-control"
                    multiple
                    onChange={(e) => setvehicle2(e.target.files[0])}
                  />
                </div>
                <div className="mb-3 col-md-6 col-12">
                  <label className="mb-2">Vehicle Pictures Right Side</label>
                  <input
                    type="file"
                    className="form-control"
                    multiple
                    onChange={(e) => setvehicle3(e.target.files[0])}
                  />
                </div>
                <div className="mb-3 col-md-6 col-12">
                  <label className="mb-2">Vehicle Pictures Left Side</label>
                  <input
                    type="file"
                    className="form-control"
                    multiple
                    onChange={(e) => setvehicle4(e.target.files[0])}
                  />
                </div>

                <div className="mb-3 col-md-6 col-12">
                  <label className="mb-2">Vehicle Details</label>
                  <input
                    type="text"
                    className="form-control"
                    defaultValue={item?.Vehicle_Details}
                    {...register("Vehicle_Details")}
                  />
                </div>
                <div className="mb-3 col-md-6 col-12">
                  <label className="mb-2">Vehicle Type</label>
                  <select
                    className="form-select form-control"
                    id=""
                    aria-label="Floating label select example"
                    {...register("Vehicle_type", { required: true })}
                    onChange={handleOptionChange}
                  >
                    <options value="All">All</options>
                    {AllVehicle?.map((v) => (
                      <option
                        key={v.vehicle_name}
                        value={v.vehicle_name}
                        // onClick={() => handleOptionClick(lvl.name)}
                      >
                        {v.vehicle_name}
                      </option>
                    ))}
                  </select>
                </div>
                {/* <div className="mb-3 col-md-6 col-12">
                  <label className="mb-2">Vehicle Type</label>
                  <input
                    type="text"
                    className="form-control"
                    defaultValue={item?.Vehicle_type}
                    {...register("Vehicle_type")}
                  />
                </div> */}

                <div className="mb-3 col-md-6 col-12">
                  <label className="mb-2">Vehicle Company Name</label>
                  <input
                    type="text"
                    className="form-control"
                    defaultValue={item?.Company_name}
                    {...register("Company_name")}
                  />
                </div>
                <div className="mb-3 col-md-6 col-12">
                  <label className="mb-2">Vehicle Model Name</label>
                  <input
                    type="text"
                    className="form-control"
                    defaultValue={item?.Model_name}
                    {...register("Model_name")}
                  />
                </div>
                <div className="mb-3 col-md-6 col-12">
                  <label className="mb-2">Plate Number</label>
                  <input
                    type="text"
                    className="form-control"
                    defaultValue={item?.Plate_number}
                    {...register("Plate_number")}
                  />
                </div>
                <div className="mb-3 col-md-6 col-12">
                  <label className="mb-2">VIN Number</label>
                  <input
                    type="text"
                    className="form-control"
                    defaultValue={item?.VINumber}
                    {...register("VINumber")}
                  />
                </div>
                <div className="mb-3 col-md-6 col-12">
                  <label className="mb-2">Vehicle Color</label>
                  <input
                    type="text"
                    className="form-control"
                    defaultValue={item?.Vehicle_Color}
                    {...register("Vehicle_Color")}
                  />
                </div>
                <div className="upload-reset-btn mb-0 justify-content-center pt-2">
                  <button
                    className="btn btn-reset"
                    id="closeUpdateModal"
                    data-bs-dismiss="modal"
                  >
                    Cancel
                  </button>
                  <button className="btn btn-upload me-0" type="submit">
                    Save
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateDriver;
