import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import user from "../../Image/user.png";
import Paggination from "../Paggination";
import toast from "react-hot-toast";
import {
  POSTAPI,
  RemoveDriverApi,
  DriverStatusChange,
} from "../../helper/integration";
import NotFound from "../../Image/notfound.png";
import { CSVLink } from "react-csv";
import { Api } from "../../utils/Api";
import Papa from "papaparse";

const AllDriverDetails = ({
  drivers,
  isLoad,
  setSingleDriver,
  isUpdate,
  setisUpdate,
}) => {
  const [AllDrivers, setAllDrivers] = useState([]);
  const [Drivers, setDrivers] = useState([]);
  const [FilterData, setFilterData] = useState([]);
  const [currentPage, setcurrentPage] = useState(1);
  const [ImportDriver, setImportDriver] = useState([]);
  const [recordsPerPage] = useState(10);
  const [nPage, setnPage] = useState([1]);

  const [imagePreviewUrl, setImagePreviewUrl] = useState("");
  const [driverIdToDelete, setDriverIdToDelete] = useState(null);
  const HandleFilterWithType = (e) => {
    let { value } = e.target;
    if (value == "approve") {
      let data = AllDrivers.filter((item) => item?.status == "approve");
      setFilterData(data);
      let indexOfLastRecord = 1 * recordsPerPage;
      let indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
      let currentRecords = data.slice(indexOfFirstRecord, indexOfLastRecord);
      setcurrentPage(1);
      setDrivers(currentRecords);
      let nPages = Math.ceil(data?.length / recordsPerPage);
      let num = [...Array(nPages + 1).keys()].slice(1);
      setnPage(num);
    } else if (value == "pending") {
      let data = AllDrivers.filter((item) => item?.status == "pending");
      setFilterData(data);
      let indexOfLastRecord = 1 * recordsPerPage;
      let indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
      let currentRecords = data.slice(indexOfFirstRecord, indexOfLastRecord);
      setcurrentPage(1);
      setDrivers(currentRecords);
      let nPages = Math.ceil(data?.length / recordsPerPage);
      console.log(nPages);
      let num = [...Array(nPages + 1).keys()].slice(1);
      setnPage(num);
    } else if (value == "reject") {
      let data = AllDrivers.filter((item) => item?.status == "reject");
      setFilterData(data);
      let indexOfLastRecord = 1 * recordsPerPage;
      let indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
      let currentRecords = data.slice(indexOfFirstRecord, indexOfLastRecord);
      setcurrentPage(1);
      setDrivers(currentRecords);
      let nPages = Math.ceil(data?.length / recordsPerPage);
      console.log(nPages);
      let num = [...Array(nPages + 1).keys()].slice(1);
      setnPage(num);
    } else {
      setFilterData([]);
      let indexOfLastRecord = 1 * recordsPerPage;
      let indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
      let currentRecords = AllDrivers.slice(
        indexOfFirstRecord,
        indexOfLastRecord
      );
      setcurrentPage(1);
      setDrivers(currentRecords);
      let nPages = Math.ceil(AllDrivers?.length / recordsPerPage);
      let num = [...Array(nPages + 1).keys()].slice(1);
      setnPage(num);
    }
  };
  const handleChangePage = (id) => {
    setcurrentPage(id);
    const indexOfLastRecord = id * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    let currentRecords;
    if (FilterData?.length) {
      currentRecords = FilterData.slice(indexOfFirstRecord, indexOfLastRecord);
    } else {
      currentRecords = AllDrivers.slice(indexOfFirstRecord, indexOfLastRecord);
    }
    setDrivers(currentRecords);
  };
  const handleSearch = (e) => {
    let val = e.target.value;
    let newArray;
    if (FilterData?.length) {
      newArray = FilterData.filter(
        (item) =>
          item.username.toLowerCase().includes(val.toLowerCase()) ||
          item.email.toLowerCase().includes(val.toLowerCase()) ||
          item.mobilenumber.toLowerCase().includes(val.toLowerCase())
      );
    } else {
      newArray = AllDrivers.filter((item) => {
        return (
          (item.username &&
            item.username.toLowerCase().includes(val.toLowerCase())) ||
          (item.email &&
            item.email.toLowerCase().includes(val.toLowerCase())) ||
          (item.mobilenumber &&
            item.mobilenumber.toLowerCase().includes(val.toLowerCase()))
        );
      });
    }
    setcurrentPage(1);
    const indexOfLastRecord = 1 * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const currentRecords = newArray.slice(
      indexOfFirstRecord,
      indexOfLastRecord
    );
    setDrivers(currentRecords);
    const nPages = Math.ceil(newArray?.length / recordsPerPage);
    const num = [...Array(nPages + 1).keys()].slice(1);
    setnPage(num);
  };

  const handleImportData = (e) => {
    const file = e.target.files[0];
    if (file) {
      Papa.parse(file, {
        complete: function (result) {
          const headerRow = result.data[0];
          const rowsWithData = result.data.slice(1);
          const formattedRows = rowsWithData.map((row) => {
            const formattedRow = {};
            headerRow.forEach((header, index) => {
              formattedRow[header] = row[index];
            });
            return formattedRow;
          });
          setImportDriver((prev) => formattedRows);
          document.getElementById("OpenImportModal").click();
        },
        header: false, // Assuming no header row in the CSV
      });
    }
    e.target.files = null;
  };

  const handleRemoveDriver = (obj) => {
    if (driverIdToDelete) {
      let obj = {
        user_id: driverIdToDelete,
      };
      toast
        .promise(RemoveDriverApi(obj), {
          loading: "Wait Few Moment !",
          success: "Drive Remove Successfully !",
          error: "Something Wrong !",
        })
        .then(() => {
          setisUpdate(!isUpdate);
          setDriverIdToDelete(null); // Clear the ID after deletion
        })
        .catch(() => {});
    }
  };

  useEffect(() => {
    (function () {
      setAllDrivers((prev) => drivers);
      let indexOfLastRecord = currentPage * recordsPerPage;
      let indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
      let currentRecords = drivers.slice(indexOfFirstRecord, indexOfLastRecord);
      setDrivers((prev) => currentRecords);
      let nPages = Math.ceil(drivers?.length / recordsPerPage);
      let num = [...Array(nPages + 1).keys()].slice(1);
      setnPage(num);
    })();
  }, [drivers]);

  const handleImageClick = (url) => {
    setImagePreviewUrl(url);
    const imageModal = new window.bootstrap.Modal(
      document.getElementById("imagePreviewModal")
    );
    imageModal.show();
  };

  const handleChangeStatus = (obj) => {
    toast
      .promise(DriverStatusChange(obj), {
        loading: "Wait Few Moment !",
        error: "Something Wrong !",
        success: `Driver ${obj.type} successfully !`,
      })
      .then(() => {
        setisUpdate(!isUpdate);
      })
      .catch(() => {});
  };
  return (
    <div
      class="tab-pane fade show active"
      id="pills-home"
      role="tabpanel"
      aria-labelledby="pills-home-tab"
    >
      <div className="pro-add-new px-0">
        <p>DRIVER DETAILS</p>
        <div className="form-floating small-floating">
          <input
            type="text"
            className="form-control py-2 w-auto"
            id="floatingInput"
            placeholder="name@example.com"
            onChange={handleSearch}
          />
          <label for="floatingInput">Search</label>
        </div>
      </div>
      <div className="d-flex align-items-end justify-content-between">
        <div className="mb-3 col-lg-2 col-md-3 col-5">
          <label className="mb-2">Filter</label>
          <select
            className="form-select form-control"
            id=""
            aria-label="Floating label select example"
            onChange={HandleFilterWithType}
          >
            <option selected>All </option>
            <option value="approve">Approve </option>
            <option value="pending">Pending</option>
            <option value="reject">Reject</option>
          </select>
        </div>
        <div className="d-flex align-items-center">
          <div
            class="mb-3 btn-group me-3"
            role="group"
            aria-label="Basic outlined example"
          >
            <CSVLink
              data={AllDrivers}
              filename={"Driver.csv"}
              type="button"
              id="exportDriver"
              class="btn btn-outline-primary shadow-none"
              hidden
            >
              EXPORT CSV
            </CSVLink>
            <Link
              data-bs-toggle="modal"
              data-bs-target="#exportDriverDetail"
              type="button"
              class="btn btn-outline-primary shadow-none"
            >
              EXPORT CSV
            </Link>
            <Link
              id="OpenImportModal"
              data-bs-toggle="modal"
              data-bs-target="#importDriverDetail"
              type="button"
              class="btn btn-outline-primary shadow-none"
              hidden
            >
              Import CSV
            </Link>
            <input
              type="file"
              id="importUser"
              onChange={handleImportData}
              hidden
            />
            <label htmlFor="importUser">
              <div class="btn btn-outline-primary shadow-none   ">
                IMPORT CSV
              </div>
            </label>
          </div>
          <div className="mb-3 pro-add-new px-0">
            <Link
              type="button"
              className="btn add-new"
              data-bs-toggle="modal"
              data-bs-target="#exampleModalAdd"
            >
              <i className="fa-solid fa-plus"></i> Add Driver
            </Link>
          </div>
        </div>
      </div>
      <div className="responsive-table">
        <table className="table">
          <thead>
            <tr>
              <th>ID</th>
              <th>Driver ID</th>
              <th>Profile Image</th>
              <th>User Name</th>
              <th>Mobile Number</th>
              <th>Email</th>
              <th>Residence Address</th>
              <th>Driving License</th>
              <th>Insurance Certificate</th>
              <th>Safety Documents</th>
              <th>Vehicle Pictures</th>
              <th>Vehicle Details</th>
              <th>Vehicle Type</th>
              <th>Vehicle Company Name</th>
              <th>Vehicle Model Name</th>
              <th>Plate Number</th>
              <th>VIN Number</th>
              <th>Vehicle Color</th>
              <th>Status</th>
              <th>Action</th>
              <th>Pay</th>
            </tr>
          </thead>
          <tbody>
            {!isLoad ? (
              <tr>
                <td colSpan={10}>
                  <div
                    className="d-flex justify-content-center align-items-center  "
                    style={{ height: "10vh" }}
                  >
                    <div class="loader"></div>
                  </div>
                </td>
              </tr>
            ) : !Drivers?.length ? (
              <tr>
                <td colSpan={10}>
                  <div
                    className="d-flex flex-column  justify-content-center align-items-center  "
                    style={{ height: "15vh" }}
                  >
                    <img src={NotFound} style={{ height: "100px" }} alt="" />
                    <div>Driver not found !</div>
                  </div>
                </td>
              </tr>
            ) : (
              Drivers?.map((item, index) => {
                return (
                  <tr>
                    <td>
                      {currentPage == 1
                        ? index + 1
                        : index + 1 + currentPage * 10 - 10}
                    </td>
                    <td>{item?._id}</td>
                    <td>
                      <Link
                        className="profile-img"
                        onClick={() => handleImageClick(item?.profileUrl)}
                      >
                        <img
                          src={item?.profileUrl}
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.src = user;
                          }}
                          className="img-fluid"
                        />
                      </Link>
                    </td>
                    <td>{item?.username}</td>
                    <td>{item?.mobilenumber}</td>
                    <td>{item?.email}</td>
                    <td>{item?.Residence_Address}</td>
                    <td>
                      <Link
                        className="profile-img"
                        onClick={() =>
                          handleImageClick(item?.drivingLicenseUrl)
                        }
                      >
                        <img
                          //src={item?.drivingLicenseUrl}
                          src={item?.drivingLicenseUrl}
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.src = user;
                          }}
                        />
                      </Link>
                    </td>
                    <td>
                      <Link
                        onClick={() =>
                          handleImageClick(item?.insuranceCertificateUrl)
                        }
                        className="profile-img"
                      >
                        <img
                          src={item?.insuranceCertificateUrl}
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.src = user;
                          }}
                        />
                      </Link>
                    </td>
                    <td>
                      <Link
                        onClick={() =>
                          handleImageClick(item?.safetyDocumentsUrl)
                        }
                        className="profile-img"
                      >
                        <img
                          src={item?.safetyDocumentsUrl}
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.src = user;
                          }}
                        />
                      </Link>
                    </td>
                    <td>
                      <Link className="profile-img">
                        <img
                          onClick={() =>
                            handleImageClick(item?.vehicle_pictures1_Url)
                          }
                          src={item?.vehicle_pictures1_Url}
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.src = user;
                          }}
                        />
                        <img
                          onClick={() =>
                            handleImageClick(item?.vehicle_pictures2_Url)
                          }
                          src={item?.vehicle_pictures2_Url}
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.src = user;
                          }}
                        />
                        <img
                          onClick={() =>
                            handleImageClick(item?.vehicle_pictures3_Url)
                          }
                          src={item?.vehicle_pictures3_Url}
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.src = user;
                          }}
                        />
                        <img
                          onClick={() =>
                            handleImageClick(item?.vehicle_pictures4_Url)
                          }
                          src={item?.vehicle_pictures4_Url}
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.src = user;
                          }}
                        />
                      </Link>
                    </td>
                    <td>{item?.Vehicle_Details}</td>
                    <td>{item?.Vehicle_type}</td>
                    <td>{item?.Company_name}</td>
                    <td>{item?.Model_name}</td>
                    <td>{item?.Plate_number}</td>

                    <td>{item?.VINumber}</td>
                    <td>{item?.Vehicle_Color}</td>
                    <td>
                      <div>
                        {item?.status == "pending" && (
                          <>
                            {/* <Link
                              to={"#"}
                              class="badge bg-warning me-1 text-white"
                            >
                              Pending
                            </Link> */}
                            <td>
                              <div className="mb-2">
                                <Link
                                  onClick={() =>
                                    handleChangeStatus({
                                      user_id: item?._id,
                                      type: "approve",
                                    })
                                  }
                                  class="btn btn-success btn-sm me-1"
                                >
                                  Approve
                                </Link>
                                <Link
                                  onClick={() =>
                                    handleChangeStatus({
                                      user_id: item?._id,
                                      type: "reject",
                                    })
                                  }
                                  class="btn btn-danger btn-sm me-1"
                                >
                                  Reject
                                </Link>
                              </div>
                            </td>
                          </>
                        )}
                        {item?.status == "approve" && (
                          <Link
                            to={"#"}
                            class="badge bg-success mb-2 text-white"
                          >
                            Approved
                          </Link>
                        )}
                        {item?.status == "Verify" && (
                          <Link
                            to={"#"}
                            class="badge bg-success mb-2 text-white"
                          >
                            Verified
                          </Link>
                        )}
                        {item?.status == "reject" && (
                          <Link
                            to={"#"}
                            class="badge bg-danger  me-1 text-white"
                          >
                            Reject
                          </Link>
                        )}
                      </div>
                    </td>
                    <td>
                      <div className="icon-up-del">
                        <Link
                          onClick={() => {
                            setSingleDriver((prev) => item);
                          }}
                          type="button"
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModaledit"
                        >
                          <i className="fa-solid fa-pen"></i>
                        </Link>

                        <Link
                          // onClick={() =>
                          //   handleRemoveDriver({ user_id: item?._id })
                          // }
                          onClick={() => {
                            setDriverIdToDelete(item._id);
                          }}
                          data-bs-toggle="modal"
                          data-bs-target="#deleteConfirmationModal"
                        >
                          <i className="fa-solid fa-trash"></i>
                        </Link>
                        <span
                          onClick={() => {
                            setSingleDriver((prev) => item);
                          }}
                          type="button"
                          data-bs-toggle="modal"
                          data-bs-target="#viewDriver"
                        >
                          <i className="fa-solid fa-eye"></i>
                        </span>
                      </div>
                    </td>

                    <td>
                      <a
                        href="https://www.paypal.com/myaccount/transfer/homepage/pay"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="btn btn-upload py-2"
                      >
                        Pay
                      </a>
                    </td>
                  </tr>
                );
              })
            )}
          </tbody>
        </table>
      </div>
      {/* Modal for Image Preview */}
      <div
        className="modal fade"
        id="imagePreviewModal"
        tabIndex="-1"
        aria-labelledby="imagePreviewModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
            <div className=" bg-primary modal-header">
              <h5
                className="modal-title text-white "
                id="imagePreviewModalLabel"
              >
                Image Preview
              </h5>
              <button
                type="button"
                className="btn-close bg-white"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <img
                src={imagePreviewUrl}
                className="img-fluid"
                alt="Large Preview"
              />
            </div>
            <div className="modal-footer">
              {/* <button
                type="button"
                className="btn btn-primary"
                data-bs-dismiss="modal"
              >
                Update
              </button> */}
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
      <Paggination
        currentPage={currentPage}
        nPage={nPage}
        handleChangePage={handleChangePage}
      />

      <div
        className="modal fade"
        id="exportDriverDetail"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Export User Details
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="user-details">
                <div>
                  <div>
                    <h4 className="py-4 mx-auto ">
                      Are sure to want export Driver Detail !
                    </h4>
                  </div>
                  <div className="upload-reset-btn mb-0 justify-content-center pt-2">
                    <button
                      id="closeEdit"
                      className="btn btn-reset"
                      data-bs-dismiss="modal"
                    >
                      No
                    </button>
                    <button
                      onClick={() => {
                        document.getElementById("exportDriver").click();
                      }}
                      data-bs-dismiss="modal"
                      className="btn btn-upload me-0"
                    >
                      Yes
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="importDriverDetail"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Import Driver Details
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="user-details">
                <div>
                  <div>
                    <h4 className="py-4 mx-auto ">
                      Do You Want Add Duplicate Data ?{" "}
                    </h4>
                    <p>Total Data : {AllDrivers.length}</p>
                    <p>
                      Duplicate Data :{" "}
                      {
                        ImportDriver?.filter((el) =>
                          AllDrivers?.some((item) => item?._id == el?._id)
                        ).length
                      }
                    </p>
                    <p>
                      New Data :{" "}
                      {
                        ImportDriver?.filter(
                          (el) =>
                            !AllDrivers?.some((item) => item?._id == el?._id)
                        ).length
                      }
                    </p>
                  </div>
                  <div className="upload-reset-btn mb-0 justify-content-center pt-2">
                    <button
                      onClick={() => {
                        let newData = ImportDriver?.filter(
                          (el) =>
                            !AllDrivers?.some((item) => item?._id == el?._id)
                        );
                        if (!newData?.length)
                          return toast.error("Data Not Found !");
                        toast
                          .promise(
                            POSTAPI(Api.AddDriverCSV, {
                              data: newData?.map((item) => {
                                let { _id, ...newRow } = item;
                                return newRow;
                              }),
                            }),
                            {
                              loading: "Wait Few Moment !",
                              success: "Driver Add Successfully !",
                              error: "CSV Structure Not Match !",
                            }
                          )
                          .then(() => {
                            setisUpdate(!isUpdate);
                          })
                          .catch(() => {});
                      }}
                      className="btn btn-reset"
                      data-bs-dismiss="modal"
                    >
                      No
                    </button>
                    <button
                      onClick={() => {
                        toast
                          .promise(
                            POSTAPI(Api.AddDriverCSV, {
                              data: ImportDriver?.map((item) => {
                                let { _id, ...newRow } = item;
                                return newRow;
                              }),
                            }),
                            {
                              loading: "Wait Few Moment !",
                              success: "Driver Add Successfully !",
                              error: "CSV Structure Not Match !",
                            }
                          )
                          .then(() => {
                            setisUpdate(!isUpdate);
                          })
                          .catch(() => {});
                      }}
                      data-bs-dismiss="modal"
                      className="btn btn-upload me-0"
                    >
                      Yes
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Delete Confirmation Modal */}
      <div
        className="modal fade"
        id="deleteConfirmationModal"
        tabIndex="-1"
        aria-labelledby="deleteConfirmationModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header bg-primary">
              <h5
                className="modal-title text-white"
                id="deleteConfirmationModalLabel"
              >
                Confirm Deletion
              </h5>
              <button
                type="button"
                className="btn-close bg-white"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <h4 className="py-4 mx-auto">
                Are you sure you want to delete this driver?
              </h4>
              <div className="d-flex justify-content-center pt-2">
                <button
                  id="confirmDeleteButton"
                  className="btn btn-primary me-2"
                  data-bs-dismiss="modal"
                  onClick={handleRemoveDriver}
                >
                  Yes
                </button>
                <button className="btn btn-secondary" data-bs-dismiss="modal">
                  No
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllDriverDetails;
