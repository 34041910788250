import React, { useEffect, useState } from "react";
import Sidebar from "./Sidebar";
import user from "../Image/user.png";
import { useCookies } from "react-cookie";
import {
  AdminUpdateApi,
  ShowContentApi,
  UpdateAdminProfile,
} from "../helper/integration";
import { Api } from "../utils/Api";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";

const Profile = () => {
  const { register, reset, handleSubmit } = useForm();
  const [image, setImage] = useState("");
  const [cookie] = useCookies(["admin"]);
  const [UserData, setUserData] = useState(null);
  const [isLoad, setisLoad] = useState(false);
  const [isUpdate, setisUpdate] = useState("");
  const handleImageChange = async (e) => {
    const selectedImage = e.target.files[0];

    if (selectedImage) {
      try {
        const imageUrl = URL.createObjectURL(selectedImage);
        setImage(imageUrl);
        const newData = new FormData();
        newData.append("id", cookie.admin._id);
        newData.append("image", selectedImage);
        console.log(cookie.admin, selectedImage);

        // Make sure UpdateAdminProfile resolves properly
        await UpdateAdminProfile(newData);
        console.log(newData);

        // Optionally, update local state or show success message
        toast.success("Image updated successfully");
        setisUpdate(!isUpdate);

        setUserData((prevUserData) => ({
          ...prevUserData,
          image: imageUrl, // Update the state with the new image URL
        }));
      } catch (error) {
        // Handle any errors that occur during image update
        console.log("Error updating admin profile:", error);
        toast.error("Failed to update image. Please try again later.");
      }
    }
  };

  const handleUpdateData = async (data) => {
    try {
      let obj = {
        id: cookie.admin._id,
        name: data?.name || UserData?.name,
        email: data?.email || UserData?.email,
        country_code: data?.country_code || UserData?.country_code,
        mobilenumber: data?.mobilenumber || UserData?.mobilenumber,
      };
      await toast.promise(AdminUpdateApi(obj), {
        loading: "Wait Few Moment !",
        success: "Data Update Successfully !",
        error: "Something Wrong !",
      });
      setUserData((prev) => obj);
      reset();
    } catch (er) {
      console.log(er);
    }
  };

  useEffect(() => {
    (async () => {
      if (!cookie.admin || !cookie.admin._id) window.location = "/";
      try {
        let obj = {
          id: cookie.admin._id,
        };

        const data = await ShowContentApi(Api.AdminShow, obj);
        setUserData((prev) => data);
        setImage(data?.image);
        setisLoad(true);
      } catch (er) {
        console.log(er);
      }
    })();
  }, [cookie]);

  return (
    <div>
      <Sidebar />
      <div className="asside">
        <div className="about-first">
          <div className="row">
            <div className="col-12">
              <div className="mb-24">
                <div className="bg-box">
                  {!isLoad ? (
                    <div
                      className="d-flex justify-content-center  align-items-center "
                      style={{ height: "70svh" }}
                    >
                      <div className="loader"></div>
                    </div>
                  ) : (
                    <>
                      <div className="pro-add-new px-0">
                        <p>EDIT PROFILE</p>
                      </div>
                      <form onSubmit={handleSubmit(handleUpdateData)}>
                        <label className="mb-2">Upload Image</label>
                        <div className="profile-img-change mb-2">
                          <img
                            src={image}
                            className="img-fluid"
                            alt="User Profile"
                            onError={({ currentTarget }) => {
                              currentTarget.onerror = null; // prevents looping
                              currentTarget.src = user;
                            }}
                          />
                          <label htmlFor="image-upload">
                            <i className="fa-solid fa-pen-to-square"></i>
                          </label>
                          <input
                            type="file"
                            id="image-upload"
                            style={{ display: "none" }}
                            onChange={handleImageChange}
                          />
                        </div>
                        <h5 className="small-label">
                          Maximum upload image size 5 MB
                        </h5>
                        <div className="mb-3">
                          <label className="mb-2">Name</label>
                          <input
                            type="text"
                            className="form-control"
                            defaultValue={UserData?.name}
                            {...register("name")}
                          />
                        </div>
                        <div className="mb-3">
                          <label className="mb-2">Email ID</label>
                          <input
                            type="email"
                            className="form-control"
                            defaultValue={UserData?.email}
                            {...register("email")}
                          />
                        </div>
                        <div className="mb-4">
                          <label className="mb-2">Country code</label>
                          <input
                            type="text"
                            className="form-control"
                            defaultValue={UserData?.country_code}
                            {...register("country_code")}
                          />
                        </div>
                        <div className="mb-4">
                          <label className="mb-2">Mobile Number</label>
                          <input
                            type="number"
                            className="form-control"
                            defaultValue={UserData?.mobilenumber}
                            {...register("mobilenumber")}
                          />
                        </div>
                        <div className="upload-reset-btn mb-0 justify-content-center pt-2">
                          <button
                            type="button"
                            className="btn btn-reset"
                            data-bs-dismiss="modal"
                            onClick={() => reset()}
                          >
                            Cancel
                          </button>
                          <button
                            type="submit"
                            className="btn btn-upload me-0"
                            data-bs-dismiss="modal"
                          >
                            Save
                          </button>
                        </div>
                      </form>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
