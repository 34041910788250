import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Sidebar from "./Sidebar";
import Footer from "./Footer";
import user from "../Image/user.png";
import { ShowDriverDocApi, VerifyDriverApi } from "../helper/integration";
import Paggination from "../common/Paggination";
import toast from "react-hot-toast";
import NotFound from "../Image/notfound.png";
import UpdateModel from "../common/Driver Documents/UpdateModel";
const DriverDocuments = () => {
  const [AllDocs, setAllDocs] = useState([]);
  const [DriverDoc, setDriverDoc] = useState([]);
  const [isLoad, setisLoad] = useState(false);
  const [isUpdate, setisUpdate] = useState(false);
  const [currentPage, setcurrentPage] = useState(1);
  const [recordsPerPage] = useState(10);
  const [nPage, setnPage] = useState([1]);
  const [imagePreviewUrl, setImagePreviewUrl] = useState("");
  const [SingleDriver, setSingleDriver] = useState(null);

  const handleChangePage = (id) => {
    setcurrentPage(id);
    const indexOfLastRecord = id * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    let currentRecords = AllDocs.slice(indexOfFirstRecord, indexOfLastRecord);
    setDriverDoc(currentRecords);
  };
  const handleSearch = (e) => {
    let val = e.target.value;
    let newArray = AllDocs.filter((item) => {
      return (
        item.username && item.username.toLowerCase().includes(val.toLowerCase())
      );
    });
    setcurrentPage(1);
    const indexOfLastRecord = 1 * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const currentRecords = newArray.slice(
      indexOfFirstRecord,
      indexOfLastRecord
    );
    setDriverDoc(currentRecords);
    const nPages = Math.ceil(newArray?.length / recordsPerPage);
    const num = [...Array(nPages + 1).keys()].slice(1);
    setnPage(num);
  };

  const handleVerifyDriver = (id, status) => {
    let obj = {
      user_id: id,
      status: status,
    };
    toast
      .promise(VerifyDriverApi(obj), {
        loading: "Wait Few Moment !",
        success: "Driver Verification status updated Successfully !",
        error: "Something Wrong !",
      })
      .then(() => {
        setisUpdate(!isUpdate);
      })
      .catch(() => {});
  };
  useEffect(() => {
    (async function () {
      try {
        const data = await ShowDriverDocApi();
        setAllDocs((prev) => data);

        let indexOfLastRecord = 1 * recordsPerPage;
        let indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
        let currentRecords = data?.slice(indexOfFirstRecord, indexOfLastRecord);
        setcurrentPage(1);
        setDriverDoc(currentRecords);
        let nPages = Math.ceil(data?.length / recordsPerPage);
        let num = [...Array(nPages + 1).keys()].slice(1);
        setnPage(num);
      } catch (er) {
        console.log(er);
      } finally {
        setisLoad(true);
      }
    })();
  }, [isUpdate]);

  const handleImageClick = (url) => {
    setImagePreviewUrl(url);
    const imageModal = new window.bootstrap.Modal(
      document.getElementById("imagePreviewModal")
    );
    imageModal.show();
  };
  return (
    <div>
      {<Sidebar />}
      <div className="asside">
        <div className="about-first">
          <div className="row">
            <div className="col-12 mb-24">
              <div className="bg-box">
                <div className="pro-add-new px-0">
                  <p>DRIVER DOCUMENTS</p>
                  <div className="form-floating small-floating">
                    <input
                      type="text"
                      className="form-control py-2 w-auto"
                      id="floatingInput"
                      placeholder="name@example.com"
                      onChange={handleSearch}
                    />
                    <label for="floatingInput">Search</label>
                  </div>
                </div>
                <div className="responsive-table">
                  <table className="  table">
                    <thead>
                      <tr>
                        <th>Driver ID</th>
                        <th>Profile Image</th>
                        <th>Driver Name</th>
                        <th>Driver Email</th>
                        <th>Driving License</th>
                        <th>Insurance Certificate</th>
                        <th>Safety Documents</th>
                        <th className="text-center">Action</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {!isLoad ? (
                        <tr>
                          <td colSpan={7}>
                            <div
                              className="d-flex justify-content-center align-items-center  "
                              style={{ height: "10vh" }}
                            >
                              <div class="loader"></div>
                            </div>
                          </td>
                        </tr>
                      ) : DriverDoc?.length ? (
                        DriverDoc?.map((item) => {
                          return (
                            <tr>
                              <td>{item?._id}</td>
                              <td>
                                <Link className="profile-img">
                                  <img
                                    onClick={() =>
                                      handleImageClick(item?.ProfileImage)
                                    }
                                    src={item?.ProfileImage}
                                    className="img-fluid"
                                    onError={({ currentTarget }) => {
                                      currentTarget.onerror = null; // prevents looping
                                      currentTarget.src = user;
                                    }}
                                  />
                                </Link>
                              </td>
                              <td>{item?.username}</td>
                              <td>{item?.email}</td>
                              <td>
                                <Link
                                  onClick={() =>
                                    handleImageClick(item?.drivingLicenseUrl)
                                  }
                                  className="profile-img"
                                >
                                  <img
                                    src={item?.drivingLicenseUrl}
                                    className="img-fluid"
                                    onError={({ currentTarget }) => {
                                      currentTarget.onerror = null; // prevents looping
                                      currentTarget.src = user;
                                    }}
                                  />
                                </Link>
                              </td>

                              <td>
                                <Link
                                  onClick={() =>
                                    handleImageClick(
                                      item?.insuranceCertificateUrl
                                    )
                                  }
                                  className="profile-img"
                                >
                                  <img
                                    src={item?.insuranceCertificateUrl}
                                    className="img-fluid"
                                    onError={({ currentTarget }) => {
                                      currentTarget.onerror = null; // prevents looping
                                      currentTarget.src = user;
                                    }}
                                  />
                                </Link>
                              </td>
                              <td>
                                <Link
                                  onClick={() =>
                                    handleImageClick(item?.safetyDocumentsUrl)
                                  }
                                  className="profile-img"
                                >
                                  <img
                                    src={item?.safetyDocumentsUrl}
                                    className="img-fluid"
                                    onError={({ currentTarget }) => {
                                      currentTarget.onerror = null; // prevents looping
                                      currentTarget.src = user;
                                    }}
                                  />
                                </Link>
                              </td>
                              <td>
                                <div className=" icon-up-del">
                                  <Link
                                    onClick={() => {
                                      setSingleDriver((prev) => item);
                                    }}
                                    type="button"
                                    data-bs-toggle="modal"
                                    data-bs-target="#exampleModaledit"

                                    // Adds right margin of 1.5rem
                                  >
                                    <i className="fa-solid fa-pen"></i>
                                  </Link>
                                  <select
                                    className="form-select mb-1  mt-1 form-control"
                                    //id="floatingSelectGrid"
                                    // aria-label="Floating label select example"
                                    // {...register("type", { required: true })}
                                    // onChange={handleOptionChange}
                                    // name="type"
                                    //onChange={handleOptionChange}
                                    onChange={(e) =>
                                      handleVerifyDriver(
                                        item?._id,
                                        e.target.value
                                      )
                                    }
                                  >
                                    {/* <option selected>
                                Select
                              </option> */}
                                    <option value="pending">Pending</option>
                                    <option value="Verify">Verify</option>
                                    <option value="reject">Reject</option>
                                  </select>
                                </div>
                              </td>

                              <td className="mb-1 mt-1">
                                {item?.status == "Verify" ? (
                                  <div>
                                    <Link
                                      to={"#"}
                                      class="badge bg-success me-1 text-white"
                                    >
                                      Verified
                                    </Link>
                                    {/* <Link to={'#'} class="badge bg-warning me-1 text-white">Pending</Link> */}
                                  </div>
                                ) : item?.status == "reject" ? (
                                  <div className="mb-1 mt-1">
                                    <Link
                                      to={"#"}
                                      className="badge bg-danger me-1 text-white"
                                    >
                                      Rejected
                                    </Link>
                                  </div>
                                ) : (
                                  <div className="mb-1 mt-1">
                                    <Link
                                      to={"#"}
                                      className="badge bg-warning me-1 text-white"
                                    >
                                      Pending
                                    </Link>
                                  </div>
                                )}
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan={7}>
                            <div
                              className="d-flex flex-column  justify-content-center align-items-center mt-2  "
                              style={{ height: "15vh" }}
                            >
                              <img
                                src={NotFound}
                                style={{ height: "100px" }}
                                alt=""
                              />
                              <div> Driver Document not found !</div>
                            </div>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
                {/* Modal for Image Preview */}
                <div
                  className="modal fade"
                  id="imagePreviewModal"
                  tabIndex="-1"
                  aria-labelledby="imagePreviewModalLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog modal-dialog-centered modal-lg">
                    <div className="modal-content">
                      <div className=" bg-primary modal-header">
                        <h5
                          className="modal-title text-white "
                          id="imagePreviewModalLabel"
                        >
                          Image Preview
                        </h5>
                        <button
                          type="button"
                          className="btn-close bg-white"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        ></button>
                      </div>
                      <div className="modal-body">
                        <img
                          src={imagePreviewUrl}
                          className="img-fluid"
                          alt="Large Preview"
                        />
                      </div>
                      <div className="modal-footer">
                        {/* <button
                          type="button"
                          className="btn btn-primary"
                          data-bs-dismiss="modal"
                        >
                          Update
                        </button> */}
                        <button
                          type="button"
                          className="btn btn-secondary"
                          data-bs-dismiss="modal"
                        >
                          Close
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <!-- Edit Modal --> */}
                <UpdateModel
                  item={SingleDriver}
                  isUpdate={isUpdate}
                  setisUpdate={setisUpdate}
                  setItem={setSingleDriver}
                />
                {/* <!--End Edit Modal --> */}
                <Paggination
                  nPage={nPage}
                  currentPage={currentPage}
                  handleChangePage={handleChangePage}
                />
              </div>
            </div>
          </div>
        </div>
        {<Footer />}
      </div>
    </div>
  );
};

export default DriverDocuments;
