import React, { useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { AddBannerApi } from "../../helper/integration";

const AddBanner = ({ isUpdate, setisUpdate }) => {
  const { register, reset, handleSubmit } = useForm();
  const [Image, setImage] = useState(null);
  const handleAddBanner = (data) => {
    let newData = new FormData();
    newData.append("types", data.types);
    newData.append("title", data.title);
    if (Image) newData.append("image", Image);
    newData.append("status", data.status);

    toast
      .promise(AddBannerApi(newData), {
        loading: "Wait Few Moment !",
        success: "Banner Add Successfully !",
        error: "Someting Wrong !",
      })
      .then(() => {
        document.getElementById("closeAddbanner").click();
        reset();
        setisUpdate(!isUpdate);
      })
      .catch(() => {
        setImage(null);
        document.getElementById("closeAddbanner").click();
        reset();
      });
  };
  return (
    <div
      className="modal fade"
      id="exampleModalAdd"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              Add Banner
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <div className="user-details">
              <form className="row" onSubmit={handleSubmit(handleAddBanner)}>
                <div className="mb-3 col-12">
                  <label className="mb-2">Select Type</label>
                  <select
                    className="form-select form-control"
                    id="floatingSelectGrid"
                    aria-label="Floating label select example"
                    {...register("types", { required: true })}
                  >
                    <option value="User">User</option>
                    <option value="Driver">Driver</option>
                  </select>
                </div>
                <div className="mb-3 col-12">
                  <label className="mb-2">Name Of Promotion</label>
                  <input
                    type="text"
                    className="form-control"
                    {...register("title", { required: true })}
                  />
                </div>
                <div className="mb-3 col-12">
                  <label className="mb-2">Banner Image</label>
                  <input
                    type="file"
                    className="form-control"
                    onChange={(e) => setImage(e.target.files[0])}
                    multiple
                  />
                </div>
                <div className="mb-3 col-12">
                  <label className="mb-2">Select Status</label>
                  <select
                    className="form-select form-control"
                    id="floatingSelectGrid"
                    aria-label="Floating label select example"
                    {...register("status", { required: true })}
                  >
                    <option value="Active">Active</option>
                    <option value="Deactive">Inactive</option>
                  </select>
                </div>
                <div className="upload-reset-btn mb-0 justify-content-center pt-2">
                  <button
                    className="btn btn-reset"
                    id="closeAddbanner"
                    data-bs-dismiss="modal"
                    type="button"
                  >
                    Cancel
                  </button>
                  <button className="btn btn-upload me-0" type="submit">
                    Save
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddBanner;
